import React from 'react';
import ReactQuill from 'react-quill';
import EditorToolbar, { modules, formats } from "./Toolbar";
import "react-quill/dist/quill.snow.css";

const RichTextEditor = ({ value, onChange }) => {
  return (
    <div className="text-editor">
    <EditorToolbar />
    <ReactQuill
      value={value}
      onChange={onChange}
      theme="snow"
      placeholder={"Write something awesome..."}
      modules={modules}
      formats={formats}
    />
    </div>
  );
};

export default RichTextEditor;