import React, { useState, useEffect } from 'react';

import { Container } from './styles';
import { FaBookReader, FaPlus, FaMinus } from 'react-icons/fa';
import Collapse from 'react-bootstrap/Collapse';
import "react-quill/dist/quill.snow.css";

const PleaseRead = ({ guidance }) => {
    const [showGuidance, setShowGuidance] = useState(false);
    const [guidanceFragments, setGuidanceFragments] = useState([]);

    useEffect(() => {
        if(guidance != null && guidance != "") {
            setGuidanceFragments(guidance);

            // var splitedGuidance = guidance.split()

            var guidanceValue = document.querySelector(".guidance_html");
            guidanceValue.innerHTML = guidance;    
        }

    }, []);

    return (
        <Container className="container mt-4 mb-3">
            <div className="please-read-div">
                <div className="please-read-header row" onClick={() => setShowGuidance(!showGuidance)}>
                    <div className="col-md-11">
                        <FaBookReader size={21} style={{ marginBottom: "5px" }} />
                        <label>Please read</label>
                    </div>
                    <div className="col-md-1">
                        {
                            showGuidance ?
                                <FaMinus size={36} className="svg-circled" onClick={() => setShowGuidance(false)} />
                                :
                                <FaPlus size={36} className="svg-circled" onClick={() => setShowGuidance(true)} />
                        }
                    </div>
                </div>
                <div className="row">
                    <Collapse in={showGuidance} className="col-md-12 p-5 pt-4 pb-4">
                        <div className='guidance_html ql-editor '></div>
                    </Collapse>
                </div>
            </div>
        </Container>
    )
}

export default PleaseRead;
