import React, { useEffect, useState } from 'react';
import { GoLinkExternal, GoChevronDown } from 'react-icons/go';
import { Container, Card } from './styles';
import { useHistory } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useAuth } from '../../hooks/auth';
import { MdChat } from 'react-icons/md';

import Api, { exceptionNotificationAPI } from '../../services/api';

import TableLoading from '../../components/TableLoading';
import moment from 'moment';
import Swal from 'sweetalert2';
import ModalSelectWaiver from '../ModalSelectWaiver';

const DashboardLocal = () => {
    const history = useHistory();
    const { user, userRoles } = useAuth();
    const [userObject, setUserObject] = useState(null);
    const [modalReadShow, setModalReadShow] = useState(false);
    const [data, setData] = useState([]);
    const [selectedCrop, setSelectedCrop] = useState("");
    const [selectedAccordionOperation, setSelectedAccordionOperation] = useState("");
    const [crops, setCrops] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModalSelectWaiver, setShowModalSelectWaiver] = useState(false);
    const [selectedWaiver, setSelectedWaiver] = useState(null);


    useEffect(() => {
        var userObjectAux = JSON.parse(localStorage.getItem('@QualityApp:user'));

        // var userLogged = JSON.parse(userObjectAux);

        if (userObjectAux) {
            if (userRoles.includes('RoleApprover') && userObjectAux.needReadConsent && userObjectAux.isFirstLogin) {
                setModalReadShow(true);
            }
        }

        setUserObject(userObjectAux);
        setData([]);
        loadCrops(userObjectAux);
    }, [])

    useEffect(() => {
        if (selectedCrop != "") {
            setLoading(true);
            Api.get(`Dashboard/GetByFilter?selectedReportYear=${selectedCrop}`).then((result) => {
                if (result && result.data) {
                    setData(result.data.response);
                    if (result.data.response && result.data.response.length > 0) {
                        setSelectedAccordionOperation(result.data.response[0].operationID);
                    }
                    setLoading(false);
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });

            if (userObject.selectedReportYearID && userObject.selectedReportYearID != selectedCrop) {
                var targetCrop = crops.find(x => x.reportYearID == selectedCrop);
                userObject.selectedReportYearID = selectedCrop;
                userObject.selectedReportYear = targetCrop ? targetCrop.year : "";
                localStorage.setItem('@QualityApp:user', JSON.stringify(userObject));
                document.querySelector("#selectedCropYear").innerHTML = targetCrop ? targetCrop.year : "";
            }
        }
    }, [selectedCrop]);

    const onClickModuleRedirect = (operationName, module, operationID) => {
        var operationNameWords = operationName.split(" ");
        var moduleNameWords = module.description.split(" ");
        var url = "";


        if (module.isWaiverModule) {
            var obj = {
                module: module,
                operationID: operationID,
                companyID: userObject.selectedCompanyID,
                operationName: operationName,
                description: module.description
            }

            setSelectedWaiver(obj)
            setShowModalSelectWaiver(true)
        }
        else {
            url = `/report/${operationName.replaceAll(' ', '').replaceAll('&', '').toLowerCase()}/${module.description.replaceAll(' ', '').replaceAll('&', '').toLowerCase()}`;
            if (module.blockedToSend) {
                Swal.fire({
                    title: 'This module is locked for submission',
                    text: "No data will be sent to approval until submission time starts.",
                    icon: 'warning',
                    showDenyButton: false,
                    confirmButtonColor: '#112A61',
                    confirmButtonText: 'Ok',
                    showCloseButton: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        history.push(url);
                    }
                });
            }else {
                history.push(url);
            }
        }


    }

    const redirectBILink = () => {
        window.open("https://app.powerbi.com/groups/me/apps/e31d07d5-fa95-452c-af71-32e38c5f2a36/reports/0aa6b03b-ea0e-47af-a794-81f1982d8305/ReportSectionfbfe9508e1e6e1e28925?ctid=ff9c7474-421d-4957-8d47-c4b64dec87b5&experience=power-bi", "_blank");
    }

    const loadCrops = (userObjectAux) => {
        setLoading(true);
        Api.get(`Dashboard/getAllYears`).then((result) => {
            if (result && result.data) {
                setCrops(result.data.response);
            }
            setLoading(false);
            if (userObjectAux != null && userObjectAux.selectedReportYearID)
                setSelectedCrop(userObjectAux.selectedReportYearID);
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onClickButtonCompareVersion = (companyID, reportYearID, operationID, moduleID) => {
        history.push(`/compareVersion/companyID=${companyID}&reportYearID=${reportYearID}&operationID=${operationID}&moduleID=${moduleID}`);
    };

    const onClickButtonDiscussionDashboard = (companyID, reportYearID, operationID, moduleID) => {
        history.push(`/discussionDashboard/companyID=${companyID}&reportYearID=${reportYearID}&operationID=${operationID}&moduleID=${moduleID}`);
    };

    const handleCropChange = (event) => {
        setSelectedCrop(event.target.value);
        window.location.reload(true)
      };

    return (
        <Container>
            <div className="dashboard-header">
                <div className="container">
                    <div className="row">
                        <div className="dashboard-title col-md-8">
                            {userObject && <h1>{userObject.company.name}</h1>}
                        </div>
                        <div className="dashboard-filter col-md-4">
                            {
                                !loading &&
                                <select className="col-md-12" onChange={handleCropChange} value={selectedCrop}>
                                    {
                                        crops ?
                                            crops.map(crop => {
                                                return <option key={crop.reportYearID} value={crop.reportYearID}>{crop.year}</option>
                                            })
                                            :
                                            <></>
                                    }
                                </select>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="container dashboard-body">
                {data && data.length > 0 ?
                    <>
                        {
                            loading ?
                                <TableLoading />
                                :
                                <>
                                    <div className="cards-header">
                                        <div className="col-md-3">Form</div>
                                        <div className="col-md-1 last-change-column" style={{ paddingRight: '55px' }}>Last change</div>
                                        <div className="col-md-1" style={{ paddingLeft: 32, textAlign: 'center' }}>Sent by</div>
                                        <div className="col-md-4 year-version-column" style={{ padding: '0 2.5rem', textAlign: 'right' }}>Crop Year/Version</div>
                                    </div>
                                    {
                                        data.map((operation) => {
                                            var today = new moment()
                                            return <>
                                                <Card className="show" key={operation.operationID} item={operation.operationID}>
                                                    <div className={`card-body ${selectedAccordionOperation === operation.operationID ? 'collapsed' : ''}`} onClick={() => selectedAccordionOperation == operation.operationID ? setSelectedAccordionOperation(null) : setSelectedAccordionOperation(operation.operationID)}>
                                                        <div className="col-md-3">
                                                            <GoChevronDown />{operation.description}
                                                        </div>
                                                        <div className="col-md-2 last-change-column"></div>
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-1 year-version-column">
                                                            {operation.operationID != 4 ? operation.year : ""}
                                                        </div>
                                                        <div className="col-md-2">
                                                        </div>
                                                        <div className="col-md-1">
                                                            {
                                                                <div className={operation.percentage == 0 ?
                                                                    "status pending"
                                                                    :
                                                                    "status complete"}>
                                                                    {`${operation.percentage}%`}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={`card collapse ${selectedAccordionOperation === operation.operationID ? 'show' : ''}`}>
                                                        {operation.modules ?
                                                            operation.modules.length > 0 ?
                                                                operation.modules.map((module) =>
                                                                    <div className="card-row">
                                                                        <div className="card-body" item={module.moduleID} onClick={() => { onClickModuleRedirect(operation.description, module, operation.operationID) }}>
                                                                            <div className="col-md-3">
                                                                                {module.tobaccoTypeID != null ? module.tobaccoTypeName : module.description}
                                                                            </div>
                                                                            <div className="col-md-1 last-change-column">
                                                                                {module.dateLastChange != null ? module.dateLastChange : ""}
                                                                            </div>
                                                                            <div className="col-md-2 year-version-column">
                                                                                {module.sentBy}
                                                                            </div>
                                                                            {
                                                                                module.deadline != null ?
                                                                                    <div className={`col-md-2 status ${module.classReject}`}>
                                                                                        {`Deadline: ${moment(module.deadline, "DD/MM/YYYY").format("DD/MM/YYYY")}`}
                                                                                    </div>
                                                                                    : <div className="col-md-2"></div>
                                                                            }
                                                                            <div className="col-md-1"></div>
                                                                            <div className="col-md-2">
                                                                                {
                                                                                    module.status != null && 
                                                                                    <div className={module.statusNumber == 0 ?
                                                                                        "status notStarted"
                                                                                        :
                                                                                        ((module.statusNumber == 5 || module.statusNumber == 8) ?
                                                                                            "status rejected"
                                                                                            :
                                                                                            "status complete")}>
                                                                                        {module.status}
                                                                                    </div>
                                                                                }
                                                                                
                                                                            </div>
                                                                            <div className="col-md-1">
                                                                                {
                                                                                    <div className={module.percentage == 0 ?
                                                                                        "status pending"
                                                                                        :
                                                                                        "status complete"} style={{ width: '80%' }}>
                                                                                        {`${module.percentage}%`}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-row-buttons-body">
                                                                            <button title="Compare Versions" className="card-row-button version-button" onClick={() => onClickButtonCompareVersion(userObject.companyID, operation.reportYearID, operation.operationID, module.moduleID)}>
                                                                                v{module.version}
                                                                            </button>
                                                                            <button title="Discussion Dashboard" className="card-row-button-last" onClick={() => onClickButtonDiscussionDashboard(userObject.companyID, operation.reportYearID, operation.operationID, module.moduleID)}>
                                                                                <MdChat size={20} />
                                                                            </button>
                                                                        </div>
                                                                    </div>)
                                                                :
                                                                <div className="no-record-found">
                                                                    <div className="col-md-12">No records found</div>
                                                                </div>
                                                            :
                                                            <TableLoading />
                                                        }
                                                    </div>
                                                </Card>
                                            </>
                                        })
                                    }
                                </>
                        }
                    </>
                    :
                    loading ?
                        <TableLoading />
                        :
                        <div>There is no data to list here</div>
                }
            </div>
            {
                userObject && userObject.isSourcingGroup == false ?
                    <div className="container dashboard-buttons">
                        <button type="button" onClick={() => redirectBILink()} className="btn btn-custom">see dashboard <GoLinkExternal size={17} /></button>
                    </div>
                    : <></>
            }


            <ModalSelectWaiver
                showModalSelectWaiver={showModalSelectWaiver}
                setShowModalSelectWaiver={setShowModalSelectWaiver}
                companyID={userObject?.companyID ?? null}
                reportYearID={selectedCrop}
                module={selectedWaiver}
            />
        </Container>
    );
}

export default DashboardLocal;
