import styled from 'styled-components';

export const ContainerHeader = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 25px;
    margin-top: 42px;
`;

export const Table = styled.table`
    font-size: 13px;

    thead{
        background-color: #102A63;
        height: 40px;
        z-index: 0;

        tr{
            th{
                text-align: center;
                font-weight: bold;
                color: #FFFFFFFF;
                padding: 14px;
            }

            th:first-child{
                border-radius: 8px 0px 0px 0px;
            }

            th:last-child{
                border-radius: 0px 8px 0px 0px;
            }
        }

        &.sticky-top {
            z-index: 1;
        }
    }

    tbody{
        background-color: #FFFFFFFF;
        box-shadow: 0px 3px 20px #0000000d;

        tr{
            border: 1px solid #ECECF3;
            border-left: #FFFFFF;
            border-right: #FFFFFF;

            td{
                text-align: center;
                padding: 14px;

                &:last-child{
                    .btn{
                        background-color: transparent;
                        border: 0;
                        padding: 0;
                        margin: 0;

                        svg{
                            color: #B0B0BB;
                            
                            &:hover{
                                color: #102A63;
                            }
                        }

                        &:focus{
                            background-color: transparent;
                            border: 0;
                            padding: 0;
                            margin: 0;
                            box-shadow: 0 0 0 0 transparent;
                        }

                        &:active{
                            background-color: transparent;
                            border: 0;
                            padding: 0;
                            margin: 0;
                            box-shadow: 0 0 0 0 transparent;
                        }
                    }

                    .dropdown-menu{
                        box-shadow: 0px 3px 20px #00000026;
                        border: 1px solid #ECECF3;
                        border-radius: 4px;
                        font-size: 13px;

                        .dropdown-item{
                            padding-left: 19px;
                            padding-right: 19px;

                            &:active{
                                background-color: #102A63;
                            }
                        }
                    }
                }
            }

            .no-records{
                text-align: center;
            }

            &:last-child{
                td:first-child{
                    border-radius: 0px 0px 0px 8px;
                }

                td:last-child{
                    border-radius: 0px 0px 8px 0px;
                }

                border-bottom: #F6F8FA;
            }
        }

        
    }
`;
