import moment from "moment";
import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { FaArrowRight } from "react-icons/fa";
import { FiUpload } from "react-icons/fi";
import NumberFormat from "react-number-format";
import Api, { exceptionNotificationAPI } from "../../services/api";
import Button from "../Button";
import GetTypeInput from "../FormInput/set";
import { success_message } from "../Toast";
// import ModalBody from 'react-bootstrap/ModalBody';
import { Header, ModalBody } from './styles';
import { transform } from "typescript";
import { margin } from "polished";
import Select, { ActionMeta, OnChangeValue } from 'react-select';

export default function ModalCustomInput({referenceObject, referenceColIndex, referenceIndex, selectedValues, options, isMultipleSelect, showModalCustomInput, setShowModalCustomInput, requestName, requestDescription, onChangeEvent}) {

    const [values, setValues] = useState(selectedValues);

    return (

        <Modal
            show={showModalCustomInput}
            onHide={() => {
                setShowModalCustomInput(false);
            }}
            onShow={() => {
                    console.log('tenho esses valores', selectedValues)

                    var dataCompany = selectedValues?.map((ce) => {
                        var data = {
                            value: ce.companyID,
                            label: options.find(x => x.value == ce.companyID)?.label
                        };
                        return data;
                    });
                    if(selectedValues)
                        setValues(dataCompany);
                    else
                        setValues([]);
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader closeButton>
                <ModalTitle id="contained-modal-title-vcenter">
                    <Header>{requestName}</Header>
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Select
                    options={options}
                    value={values}
                    isMulti={isMultipleSelect}
                    closeMenuOnSelect={false}
                    onChange={(a) => setValues(a)}
                />
                <div style={{margin: "0.5rem 0"}}>
                    <Button style={{margin: "0 1rem", float: "right", 'text-transform':"none"}} onClick={() => {onChangeEvent(referenceObject, referenceColIndex, referenceIndex, values) ; setValues([])}}> {"Update companies"}</Button>
                </div>
            </ModalBody>

        </Modal>
    );
}

