import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import Api, { exceptionNotificationAPI } from '../../services/api';

import { Container } from './styles';

const MFASignIn = () => {
    const { user, validateMFA, skipMFA, userRoles} = useAuth();
    const history = useHistory();
    const [token, setToken] = useState('');

    useEffect(() => {
        if (user && userRoles) {
            var roles = userRoles.constructor === Array ? userRoles : JSON.parse(userRoles);
            if(roles.length > 0){
                history.push("/");
            }
        }
    }, [history, user]);

    const handleSubmit = async () => {
        try {
            var result = await validateMFA({
                token: token
            });
            if(result.data.response)
                history.push("/");                
            
        } catch (error) {
            exceptionNotificationAPI(error);
        }
    };

    const onEnterPress = (e) => {
        if(e.key == 'Enter'){
            handleSubmit();
        }
    }

    const handleSkip = async () => {
        try {
            var result = await skipMFA();

            if(result.data.response)
                history.push("/");                
            
        } catch (error) {
            exceptionNotificationAPI(error);
        }
    }

    return (
        <Container>
            <div className="main-div container" align="center">
                <div className="card">
                    <div className="card-body">
                        <div className="form-group">
                        <label>
                                Token
                            </label>
                            <input className="form-control" id="Token" name="token" placeholder="" onChange={(e) => setToken(e.target.value)} required={true} type="text" onKeyPressCapture={(e) => onEnterPress(e)}/>
                        </div>                   
                        <button className="btn" onClick={handleSubmit}>Validate</button>
                        {/* <button className="btn" onClick={handleSkip}>Skip Multi factor Authentication</button> */}
                    </div>
                </div>
                <div className="card card-terms mb-2">
                    <div className="terms">
                            <p>Third parties' representatives accessing this tool can learn more about how BAT is processing personal data for business relationships from <a href="https://www.bat.com/group/sites/UK__9D9KCY.nsf/vwPagesWebLive/DOBB5H9Z" target="_blank" rel="noreferrer">here</a></p>
                            <p>BAT employees accessing this tool can learn more about how their personal data is processed by checking the local BAT Employees Privacy Notice where applicable. In case of queries, please contact your local LEX team.</p>
                        </div>
                </div>                
            </div>
        </Container>
    );
};

export default MFASignIn;
