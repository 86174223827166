import React, { useState } from 'react';
import { Switch } from 'react-router-dom';

import Route from './route';
import RoutesList from './routesList';

var mainArray = [];
RoutesList.forEach(item => {
    var array = [];
    !item.isMenu && array.push(item);
    item.childrens && item.childrens.forEach(child => {
        !child.isMenu && array.push(child);
    });
    mainArray = mainArray.concat(array);
});

const Routes = () => (    
    <Switch>
        {mainArray.map(route => (
            <Route
                key={route.path}
                path={route.path}
                exact
                component={route.component}
                isPrivate={route.isPrivate}
                allowedRoles={route.allowedRoles} />
        ))}
    </Switch>
);

export default Routes;
