import React, { useEffect, useState, useRef } from 'react';
import Api, { exceptionNotificationAPI } from '../../services/api';
import * as Yup from 'yup';
import FormCrud from '../../components/FormCrud';
import { options } from 'toastr';
import { Form } from '@unform/web';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import Button from "../../components/Button";
import { Header, ContentTitle, CommentTextArea, ModalBody } from './styles';
import { error_message, info_message, success_message } from '../../components/Toast';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import { useHistory, useParams } from 'react-router-dom';
import getValidationErrors from '../../utils/getValidationErrors';
import FormInput from '../../components/FormInput';
import CancelButton from '../../components/CancelButton';

const FormFolder = ({ showFolderModal, setShowFolderModal, ids, loadFolders, isNewFolder, setIsNewFolder }) => {    
    const id = useParams(null);
    const [foldersOptions, setFoldersOptions] = useState([]);
    const [companysOptions, setCompanysOptions] = useState([]);
    const [multipleValues, setMultipleValues] = useState([]);
    const [selectCustomValue, setSelectCustomValue] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(JSON.stringify(id) !== '{}' ? id.documentFolderID : null);
    const history = useHistory();
    const formRef = useRef(null);
    const [data, setData] = useState(null);
    const [userObject, setUserObject] = useState(JSON.parse(localStorage.getItem('@QualityApp:user')));

    // useEffect(() => {
       
    // }, [showFolderModal]);

    useEffect(() => {
        if (showFolderModal == true) {
            if (ids != null && isNewFolder == false) {
                loadData();
                
            }
        }
    }, [id, showFolderModal]);

    useEffect(() => {
        if (showFolderModal == true) {
            setMultipleValues([]);
            loadCompanies();
            loadDataFolder()
            setSelectedFolder(id.documentFolderID)
        }
    }, [showFolderModal]);

    const loadDataFolder = async () => {
        Api.get(`DocumentUploadFolder/getAllFolders?${JSON.stringify(id) !== '{}'  ? 'id='+ id.documentFolderID : ""}`).then((result) => {
            if (result && result.data) {
                var options = result.data.response;
                // .map(item => ({ value: item.documentUploadFolderID, text: `${item.folderName}` }));
                // options = [{value: "", text: "No parent folder"}, ...options];

                setFoldersOptions(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadCompanies = async () => {
        Api.get('Company/getAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.companyID, text: `${item.name}` }));

                setCompanysOptions(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const getObjects = (currentObject, fragments) => {
        if (fragments.length > 0) {
            var object = currentObject[fragments[0]];

            if (object != null) {
                fragments.shift();
                return getObjects(object, fragments);
            }
            else {
                return "";
            }
        }

        return currentObject;
    }

    const loadData = async () => {
        setData(null);
        await Api.get(`DocumentUploadFolder/get?folderID=${ids}`).then((result) => {
            if (result && result.data) {
                var dataObj = {};
                var companyOptions = [];

                fields.forEach(f => {
                    if (f.name == "folderCompanies") {
                        var dataMultiple = result.data.response["folderCompanies"].map((sc) => {
                            var data = {
                                value: sc.companyID,
                                label: sc.company.name
                            };
                            return data;
                        });
                        setMultipleValues(dataMultiple);
                    }                   
                    else {
                        if (f.name.indexOf(".") > -1) {
                            var fragments = f.name.split('.');

                            var object = getObjects(result.data.response, fragments);

                            dataObj[f.name] = (object != null ? object : "");
                        }
                        else {
                            dataObj[f.name] = result.data.response[f.name];
                        }
                    }
                });

                formRef.current.setData(dataObj);
                setData(dataObj);

            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const handleSubmit = async (data) => {
        try {
            var obj = {}
            var postObj = {};
            const submitUrl = `DocumentUploadFolder/${(ids != null && isNewFolder == false) ? "edit" : "new"}`;

            postObj["DocumentUploadFolderID"] = (ids != null && isNewFolder == false) ? ids : null;

            fields.map(f => {
                if (f.required !== undefined && f.required === false) {
                    return null;
                } else {
                    return obj[f.name] = Yup.string().required(`${f.label} is required`);
                }
            })

            const schema = Yup.object().shape(obj);

            await schema.validate(data, {
                abortEarly: false,
            });



            fields.map(f => {
                if(f.name == "parentFolderID"){
                    return postObj[f.name] = selectedFolder;
                }
                else {
                    return postObj[f.name] = data[f.name]
                }
            })

            if (multipleValues.length > 0) {
                postObj.folderCompanies = multipleValues.map((m) => {
                    var data = {
                        companyID: m.value
                    }
                    return data;
                });
            }



            await Api.post(submitUrl, postObj);

            setShowFolderModal(false);
            loadFolders();

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error);

                formRef.current.setErrors(errors);

                return;
            }

            exceptionNotificationAPI(error);
        }
    }

    const hideModal = () => {
        setIsNewFolder(false)
        setShowFolderModal(false);
    }

    const booleanOptions = [
        { value: false, text: "No" },
        { value: true, text: "Yes" }
    ];

    const fields = [
        {
            label: "Folder Name",
            name: "folderName",
            type: "input"
        },
        {
            label: "Companies to exclude",
            name: "folderCompanies",
            type: "select",
            required: false,
            options: companysOptions,
            multiple: true
        },
        {
            label: "Parent Folder",
            name: "parentFolderID",
            type: "select",
            options: foldersOptions,
            required: false
        },
        {
            label: "Active",
            name: "isActive",
            type: "select",
            options: booleanOptions
        },

    ]   


    return (
        <Modal
            show={showFolderModal}
            onHide={() => {
                hideModal();
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader closeButton>
                <ModalTitle id="contained-modal-title-vcenter">
                    <Header>Create Folder</Header>
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Form onSubmit={handleSubmit} ref={formRef}>
                                <table className="table table-striped">
                                    <tbody>
                                        {
                                            fields.map((f, i) => {
                                                if (f.type === "select") {
                                                    if (f.multiple) {
                                                        return (
                                                            <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                                <td width="25%">
                                                                    <label>
                                                                        {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                                    </label>
                                                                </td>
                                                                <td width="75%"><Select
                                                                    options={f.options.map((o) => {
                                                                        var data = {
                                                                            value: o.value,
                                                                            label: o.text
                                                                        }
                                                                        return data;
                                                                    })}
                                                                    value={multipleValues}
                                                                    isMulti
                                                                    closeMenuOnSelect={false}
                                                                    onChange={(a) => setMultipleValues(a)}
                                                                />
                                                                </td>
                                                            </tr>)
                                                    }
                                                    else if (f.name != "parentFolderID") {
                                                        return <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                            <td width="25%">
                                                                <label>
                                                                    {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                                </label>
                                                            </td>
                                                            <td width="75%">
                                                                <FormInput
                                                                    name={f.name}
                                                                    id={f.name}
                                                                    checked={f.checked}
                                                                    inputType={f.type}
                                                                    placeholder={f.placeholder}
                                                                    options={f.options}
                                                                    disabled={f.disabled ? f.disabled : false}
                                                                    onChange={(a) => {
                                                                        if (f.onChanged != null) {
                                                                            f.onChanged(formRef.current)
                                                                        }
                                                                        return;
                                                                    }} />

                                                            </td>
                                                        </tr>
                                                    }
                                                    else {
                                                        return <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                            <td width="25%">
                                                                <label>
                                                                    {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                                </label>
                                                            </td>
                                                            <td width="75%">
                                                                <select className='mb-4 px-4 py-2'
                                                                    name={f.name}
                                                                    id={f.name}
                                                                    checked={f.checked}
                                                                    inputType={f.type}
                                                                    placeholder={f.placeholder}
                                                                    onChange={(a) => setSelectedFolder(a.target.value)}>
                                                                    <option key={JSON.stringify(id) === '{}' ? null : id.documentFolderID} value={JSON.stringify(id) === '{}' ? "" : id.documentFolderID}>Actual Folder</option>
                                                                    {foldersOptions.length > 0 && foldersOptions.map(folder => {
                                                                        if (folder.parentFolders == null) {
                                                                            return <option key={folder.documentUploadFolderID} value={folder.documentUploadFolderID}>{folder.folderName}</option>
                                                                        } else {
                                                                            return (<>
                                                                            <optgroup label={folder.folderName}>
                                                                                <option key={folder.documentUploadFolderID} value={folder.documentUploadFolderID}>{folder.folderName}</option>
                                                                                {folder.parentFolders.length > 0 && folder.parentFolders.map((pfolder) => {
                                                                                    return (                                                                                                                                                                          
                                                                                        <option key={pfolder.documentUploadFolderID} value={pfolder.documentUploadFolderID}>{pfolder.folderName}</option>
                                                                                    )
                                                                                    
                                                                                })}                                                                                
                                                                            </optgroup>
                                                                            </>
                                                                            )
                                                                            
                                                                        }
                                                                        
                                                                    })}
                                                                    
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    }
                                                }
                                                else {
                                                    if (id !== undefined && f.editable !== undefined && f.editable === false) {
                                                        return <></>
                                                    }
                                                    else {
                                                        return <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                            <td width="25%">
                                                                <label>
                                                                    {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                                </label>
                                                            </td>
                                                            <td width="75%">
                                                                <FormInput
                                                                    className="form-control"
                                                                    name={f.name}
                                                                    id={f.name}
                                                                    disabled={f.disabled ? f.disabled : false}
                                                                    checked={f.checked}
                                                                    inputType={f.type} />
                                                            </td>
                                                        </tr>
                                                    }
                                                }
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div className="d-flex flex-row mt-2">
                                    <Button className="btn btn-secondary" style={{ backgroundColor: '#8b8b8b' }} onClick={() => setShowFolderModal(false)}>Cancel</Button>
                                    <Button type="submit">Save</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>

            </ModalBody>
        </Modal>


    );
}

export default FormFolder;
