import React, { useEffect, useRef, useState } from 'react';
import { Container, Table } from './styles';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { setComment, setFile, setNumber, setText, getFieldsToBlock, refNumberCalculationsDependencies, getExtraIdType, validateEvidence } from '../../services/functions';
import { toleranceValidations } from '../../services/tolerance';
import { useAuth } from '../../hooks/auth';
import { Form } from '@unform/web';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';

//Components
import { success_message, info_message, error_message } from '../../components/Toast/index.jsx';
import TooltipComponent from '../../components/TooltipComponent';
import TooltipAlertComponent from '../../components/TooltipAlertComponent';
import TableLoading from '../../components/TableLoading';
import FormInput from '../../components/FormInput/index';
import GetTypeInput from '../../components/FormInput/set';
import PleaseRead from '../../components/PleaseRead';
import ThriveScreenNavBar from '../../components/ThriveScreenNavBar/index';
import SessionTimeOut from '../../components/SessionTimeout';
import ModalLoading from '../../components/ModalLoading/index';
import ModuleButtons from '../../components/ModuleButtons/index';
import ModalCommentTabs from '../../components/ModalCommentTabs/index';
import ChatWindow from '../../components/Chat/index';
import ChatButton from '../../components/ChatButton/index';

import ModalUploadFile from '../../components/ModalUploadFile/index';
import GroupedByColumnRowTable from '../../components/GroupedByColumnRowTable/index';
import { FindReadConsentLastCropYear, CheckEditKPIsToReopenModules, CheckLastVersionChangesByModules, GetCurrentRowCount, CalculateTargetSubsectionKPI } from '../../services/functions';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Collapse from 'react-bootstrap/Collapse';

//Style
import { CommentTextArea } from './styles';
import { FaAngleDown, FaAngleUp, FaRegEye } from 'react-icons/fa';
import { FiArrowLeft } from 'react-icons/fi';
import { FaTrashAlt } from 'react-icons/fa';
import { MdComment } from 'react-icons/md';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ModalUploadEvidence from '../../components/ModalUploadEvidence';
import ModalSelectWaiver from '../../components/ModalSelectWaiver/index.jsx';

const Report = () => {
    const formRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const { userRoles } = useAuth();
    const history = useHistory();
    const location = useLocation();
    const { operation, moduleName, waiverIdentifier } = useParams();

    //Modal de comentário
    const [show, setShow] = useState(false);
    const [readConsent, setReadConsent] = useState(false);
    const [showModalUpload, setShowModalUpload] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalComment, setModalComment] = useState("");
    const [modalCommentator, setModalCommentator] = useState("");
    const [idEditing, setIdEditing] = useState(null);
    const [refNumber, setRefNumber] = useState(null);
    const [row, setRow] = useState(null); //No caso que a coluna da tabela de sub section é o nome da KPI
    const [subSectionID, setSubSectionID] = useState(null); //No caso que a coluna da tabela de sub section é o nome da KPI
    const [version, setVersion] = useState(null);
    const [versionID, setVersionID] = useState(null);
    const [allVersions, setAllVersions] = useState([]);
    const [approver, setApprover] = useState(null);
    const [subSectionKPIs, setSubSectionKPIs] = useState(null);
    const [kpis, setKpis] = useState(null);
    const [MODULE_ID, setMODULE_ID] = useState(null);
    const [operationID, setOperationID] = useState(null);
    const [navBar, setNavBar] = useState(null);

    //collapse
    const [collapse, setCollapse] = useState("");
    const [collapseText, setCollapseText] = useState("");
    const [collapseRow, setCollapseRow] = useState("");
    const [collapseCol, setCollapseCol] = useState("");

    //Modal loading
    const [loadingFile, setLoadingFile] = useState(false);
    const [showModalSelectWaiver, setShowModalSelectWaiver] = useState(false);

    //Filtro de Company
    const [selectedCompanyID, setSelectedCompanyID] = useState(null);
    const [companiesFilter, setCompaniesFilter] = useState([]);
    const [userObject, setUserObject] = useState(JSON.parse(localStorage.getItem('@QualityApp:user')));

    //Construção da tela
    const [selectedTobaccoTypes, setSelectedTobaccoTypes] = useState([]);
    const [selectedCountries, setSelectedCountry] = useState([]);
    const [moduleObject, setModuleObject] = useState({
        isEditable: false,
        showButtons: false,
        data: null,
        answers: [],
        kpiRowTotal: [],
        moduleStatus: null,
        moduleStatusNumber: null,
        tobaccoTypesOptions: [],
        version: null,
        columnRows: []
    });

    //Chat
    const defaultChatRequest = {
        "reportYearID": userObject.selectedReportYearID,
        "companyId": userObject.selectedCompanyID,
        "moduleId": MODULE_ID,
        "subSectionKPIID": null,
        "kpiId": null,
        "tobaccoTypeID": null,
        "row": null,
        "column": null
    };

    const defaultEvidenceRequest = {
        "reportYearID": userObject.selectedReportYearID,
        "companyID": userObject.selectedCompanyID,
        "moduleID": MODULE_ID,
        "subSectionKPIID": null,
        "tobaccoTypeID": null,
        "row": null,
        "column": null,
        "subSectionKPIValue": 0.0,
        "file": null,
        "farmersCount": null,
        "value": null,
        "isValidated": false
    };
    const [showChat, setShowChat] = useState(false);
    const [showUploadEvidence, setShowUploadEvidence] = useState(false);
    const [chatRequest, setChatRequest] = useState({ ...defaultChatRequest });
    const [evidenceRequest, setEvidenceRequest] = useState({ ...defaultEvidenceRequest });
    const [kpiDiscussions, setKpiDiscussions] = useState([]);
    const [kpiEvidences, setKPIEvidences] = useState([]);
    const [rejectedKpisList, setRejectedKpisList] = useState([]);
    const [defaultValues, setDefaultValues] = useState([]);
    const [fixedKpisList, setFixedKpisList] = useState([]);

    const [subSectionKPIsToBlock, setSubSectionKPIsToBlock] = useState([]);

    const TOLERANEC_TYPES = {
        1: 'Between Range',
        2: 'Greater Than',
        3: 'Lower Than',
        4: 'Equal To',
        5: 'Greater Than or Equal To',
        6: 'Lower Than or Equal To',
        7: 'Custom'
    };

    useEffect(() => {
        setLoading(true);
        Api.get(`FormValue/getModuleIdByName?name=${moduleName}&reportYearID=${userObject.selectedReportYearID}`).then((result) => {
            if (result && result.data) {
                setMODULE_ID(result.data.response.moduleID);
                setOperationID(result.data.response.operationID);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });


        if (userObject.companyID == null)
            loadCompanies();
        else
            setSelectedCompanyID(userObject.companyID);
    }, []);

    useEffect(() => {

        if (moduleObject.removedAnswers != null) {
            var newAnswers = moduleObject.answers;
            moduleObject.removedAnswers.map((removedAnswer) => {
                newAnswers = refNumberCalculationsDependencies(removedAnswer, newAnswers, subSectionKPIs, removedAnswer.column, removedAnswer.row, moduleObject.version, moduleObject.columnRows, moduleObject);
            });

            setModuleObject({ ...moduleObject, answers: newAnswers, removedAnswers: null });
        }
    }, [moduleObject]);

    useEffect(() => {
        if (selectedCompanyID) {
            userObject.selectedCompanyID = selectedCompanyID;
            localStorage.setItem('@QualityApp:user', JSON.stringify(userObject));
            setChatRequest(
                {
                    ...defaultChatRequest,
                    "companyId": selectedCompanyID,
                });
            setEvidenceRequest(
                {
                    ...defaultEvidenceRequest,
                    "companyID": selectedCompanyID,
                });

            if (MODULE_ID != null) {
                loadData();
            }
        }
    }, [selectedCompanyID])

    useEffect(() => {
        if (selectedCompanyID && MODULE_ID != null) {
            loadData();
        }
    }, [selectedCompanyID, MODULE_ID])


    useEffect(() => {
        if (selectedCompanyID && MODULE_ID != null && operationID != null && selectedCompanyID != null) {
            var s = <ThriveScreenNavBar moduleID={MODULE_ID} operationID={operationID} reportYearID={userObject.selectedReportYearID} companyID={selectedCompanyID} />
            setNavBar(s)
        }
    }, [selectedCompanyID, loading])

    const loadCompanies = async () => {
        Api.get(`Dashboard/getAllCompanies`).then((result) => {
            if (result && result.data) {
                setCompaniesFilter(result.data.response);

                if (userObject.selectedCompanyID != null) {
                    setSelectedCompanyID(userObject.selectedCompanyID);
                } else {
                    setSelectedCompanyID(result.data.response.length > 0 ? result.data.response[0].companyID : 0);
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadData = async () => {
        var object = moduleObject;
        var evidences = [];

        var crops = await Api.get(`Dashboard/getAllCrops`).then(async (result) => {
            return result.data.response;
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

        await Api.get('TobaccoType/GetAll').then((result) => {
            if (result && result.data) {
                object.tobaccoTypesOptions = result.data.response.map(item => ({ value: item.tobaccoTypeID, text: item.name, hidden: false }));

            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

        await Api.get('Company/getAllCountries').then((result) => {
            if (result && result.data) {
                object.countriesOptions = result.data.response.map(item => ({ value: item.countryID, text: item.name }));
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

        await Api.get(`Discussion/GetKPIDiscussionsByModule?reportYearID=${userObject.selectedReportYearID}&&companyId=${userObject.selectedCompanyID}&&moduleId=${MODULE_ID}`).then((result) => {
            if (result && result.data) {
                setKpiDiscussions(result.data.response);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

        // await Api.get(`EvidenceAudit/GetKPIEvidenceAuditByModule?reportYearID=${userObject.selectedReportYearID}&&companyId=${userObject.selectedCompanyID}&&moduleId=${MODULE_ID}`).then((result) => {
        //     if (result && result.data) {
        //         evidences = result.data.response;
        //         setKPIEvidences(evidences);
        //     }
        // }).catch((error) => {
        //     exceptionNotificationAPI(error);
        // });

        if (MODULE_ID != null) {
            await Api.get(`FormValue/GetModuleReport?moduleID=${MODULE_ID}&selectedCompany=${selectedCompanyID ?? ""}&reportYearID=${userObject.selectedReportYearID}${waiverIdentifier != null ? '&waiverIdentifier=' + waiverIdentifier : ""}`).then(async (result) => {
                if (result && result.data) {
                    object.data = result.data.response;
                    setLoading(true);
                    var versions = [];


                    await Api.get(`FormValue/GetFormValues?moduleID=${MODULE_ID}&selectedCompanyID=${selectedCompanyID ?? ""}&reportYearID=${userObject.selectedReportYearID}${waiverIdentifier != null ? '&waiverIdentifier=' + waiverIdentifier : ""}`).then(async (result) => {
                        if (result.data.response != null) {
                            object.answers = [...result.data.response.list];
                            object.version = result.data.response.version;
                            versions.push(result.data.response.version);
                            versions = [...versions, ...result.data.response.lastVersions];

                            setAllVersions(versions)
                            setRejectedKpisList(object.version?.rejectedFormValuesList);
                            setDefaultValues(object.data?.defaultValues);
                            (userObject?.companyID == null) ? setFixedKpisList(object.version?.globalRejectFormValuesList) : setFixedKpisList(object.version?.fixedFormValuesList);
                            setVersionID(result.data.response.version.FormValueVersionID);
                            setApprover(result.data.response.version.approverGlobalFullName != "" ? result.data.response.version.approverGlobalFullName : result.data.response.version.approverLocalFullName != "" ? result.data.response.version.approverLocalFullName : "")
                        } else {
                            setDefaultValues(object.data?.defaultValues);
                        }
                        let dynamicBlockKpisNew = {}
                        if (object.data != null) {
                            object.data.subSectionKPIConditionalBlockRules.map(e => {
                                if (!dynamicBlockKpisNew[e.refNumber]) {
                                    dynamicBlockKpisNew[e.refNumber] = [{
                                        'answerToBlock': e.value,
                                        'blockWhenNotAnswer': e.blockWhenNotAnswer,
                                        'refNumbersToBlock': e.subSectionKPIConditionalBlocks.map(e => e.refNumber),
                                    }]
                                } else {
                                    dynamicBlockKpisNew[e.refNumber].push({
                                        'answerToBlock': e.value,
                                        'blockWhenNotAnswer': e.blockWhenNotAnswer,
                                        'refNumbersToBlock': e.subSectionKPIConditionalBlocks.map(e => e.refNumber),
                                    })
                                }
                            })
                        }
                        //console.log(dynamicBlockKpisNew, "new conditional blocks")
                        //object.dynamicBlockKpis = dynamicBlockKpis;
                        object.dynamicBlockKpis = dynamicBlockKpisNew;
                        object.showSubSectionKPIInformation = userObject.showSubSectionKPIInformation != null ? userObject.showSubSectionKPIInformation : false;
                        var subSectionKPIList = [];
                        var kpisList = [];
                        var columnRowsList = [];
                        var subSectionRows = [];

                        var selectedTobaccoTypesList = [];

                        object.answers.filter(x => x.tobaccoTypeID != null).map((item) => {
                            var typeExists = object.tobaccoTypesOptions.find(x => x?.value == item.tobaccoTypeID);

                            if (typeExists) {
                                var existedItem = selectedTobaccoTypesList.find(x => x?.value == item.tobaccoTypeID)

                                if (!existedItem) {
                                    selectedTobaccoTypesList[item.column] = {
                                        value: item.tobaccoTypeID,
                                        text: typeExists.text
                                    };

                                    typeExists.hidden = true;
                                }
                            }
                        })

                        setSelectedTobaccoTypes(selectedTobaccoTypesList);


                        object.data.sections.map((section) => {
                            section.collapsed = false;
                            section.subSections.map((subSection) => {
                                kpisList = kpisList.concat(subSection.kpis)
                                subSection.kpis.map((kpi) => {
                                    subSectionKPIList = subSectionKPIList.concat(kpi.subSectionKPIs);
                                });
                                columnRowsList = columnRowsList.concat(subSection.columnRows);

                                if (subSection.isColumnRow) {
                                    subSection["numberOfRows"] = GetCurrentRowCount(subSection.subSectionID, subSectionKPIList, object.answers, columnRowsList);
                                    subSectionRows.push({
                                        "subSectionID": subSection.subSectionID,
                                        "numberOfRows": subSection["numberOfRows"],
                                    });
                                }
                            })
                        })


                        setKpis(kpisList)
                        setSubSectionKPIs(subSectionKPIList);
                        object.columnRows = columnRowsList;

                        object.isEditable = false;
                        object.showButtons = false;

                        var today = new moment();
                        var userUpdated = JSON.parse(localStorage.getItem('@QualityApp:user'));
                        var cropTarget = crops.find(x => x.reportYearID == userUpdated.selectedReportYearID);
                        var startDate = cropTarget.beginDate;
                        var endDate = cropTarget.endDate;

                        if (result.data.response && result.data.response.list && result.data.response.list.length > 0) {
                            object.moduleStatus = result.data.response.list[0].status;
                            object.moduleStatusNumber = result.data.response.list[0].statusNumber;

                            if (userRoles.includes("RoleAdmin")) {
                                if (object.moduleStatusNumber == 7) {
                                    object.isEditable = false;
                                    object.showButtons = true;
                                }
                            }
                            else if (userRoles.includes("RoleInfoHolder")) {
                                if (moment(today).isSameOrAfter(startDate) && moment(today).isSameOrBefore(endDate)) {
                                    if (object.moduleStatusNumber == 1 || object.moduleStatusNumber == 3
                                        || object.moduleStatusNumber == 5 || object.moduleStatusNumber == 6
                                        || object.moduleStatusNumber == 8) {
                                        object.isEditable = true;
                                        object.showButtons = true;
                                    }
                                }
                            } else if (userRoles.includes("RoleApprover")) {
                                if (userObject.companyID) {
                                    if (object.moduleStatusNumber == 2) {
                                        object.isEditable = false;
                                        object.showButtons = true;
                                    }
                                } else {
                                    if (object.moduleStatusNumber == 4) {
                                        object.isEditable = false;
                                        object.showButtons = true;
                                    } else if (object.moduleStatusNumber == 5) {
                                        object.isEditable = false;
                                        object.showButtons = false;
                                    }
                                }
                            }
                        } else {
                            object.moduleStatus = null;

                            if (userRoles.includes("RoleInfoHolder")) {
                                if (moment(today).isSameOrAfter(startDate) && moment(today).isSameOrBefore(endDate)) {
                                    object.isEditable = true;
                                    object.showButtons = true;
                                }
                            }
                        }

                        let subSectionKPIsToBlockList = [];

                        // for (var refNumber in object.dynamicBlockKpis) {
                        //     const sskAnswer = object.answers.filter(x => x.refNumber == refNumber);
                        //     if (sskAnswer.length >= 1) {
                        //         for (let index = 0; index < sskAnswer.length; index++) {
                        //             object.dynamicBlockKpis[sskAnswer[index].refNumber].forEach(element => {
                        //                 if ((sskAnswer[index].textValue != null && sskAnswer[index].textValue == element.answerToBlock)
                        //                     || (sskAnswer[index].numberValue != null && sskAnswer[index].numberValue == element.answerToBlock)) {

                        //                     var subSectionKPI = subSectionKPIList.find(x => x.subSectionKPIID == sskAnswer[index].subSectionKPIID);

                        //                     if (subSectionKPI) {
                        //                         var newFieldsToBlock = getFieldsToBlock(
                        //                             {
                        //                                 'subSectionKPI': subSectionKPI,
                        //                                 'subSectionKPIs': subSectionKPIList,
                        //                                 'refNumbersToBlock': element.refNumbersToBlock,
                        //                                 'row': sskAnswer[index].row != null ? sskAnswer[index].row : null,
                        //                                 'column': sskAnswer[index].column != null ? sskAnswer[index].column : null,

                        //                             }
                        //                         );
                        //                         if (newFieldsToBlock) {
                        //                             subSectionKPIsToBlockList.push(...newFieldsToBlock);
                        //                         }
                        //                     }
                        //                 }
                        //             });
                        //         }                                
                        //     }
                        //     else {
                        //         object.dynamicBlockKpis[refNumber].forEach(element => {
                        //             if (element.blockWhenNotAnswer) {
                        //                 var subSectionKPI = subSectionKPIList.find(x => x.refNumber == refNumber);

                        //                 if (subSectionKPI) {
                        //                     var newFieldsToBlock = getFieldsToBlock(
                        //                         {
                        //                             'subSectionKPI': subSectionKPI,
                        //                             'subSectionKPIs': subSectionKPIList,
                        //                             'refNumbersToBlock': element.refNumbersToBlock,
                        //                             'row': 0,
                        //                         }
                        //                     );
                        //                     if (newFieldsToBlock) {
                        //                         subSectionKPIsToBlockList.push(...newFieldsToBlock);
                        //                     }
                        //                 }
                        //             }
                        //         });
                        //     }                                            
                        // }















                        // var InputSubsectionKPIs = subSectionKPIList.filter(x => x.inputCalc == "Input");

                        // InputSubsectionKPIs.map((ssk) => {
                        //     var columns = columnRowsList.find(x => x.columnRowID == ssk.columnRowID)?.minimumQuantity ?? 1;

                        //     for (let columnIndex = 0; columnIndex < columns; columnIndex++) {

                        //         if (subSectionRows.some(x => x.subSectionID == ssk.subSectionID)) {

                        //             var subsectionRowCount = subSectionRows.find(x => x.subSectionID == ssk.subSectionID);

                        //             for (let rowIndex = 0; rowIndex < subsectionRowCount.numberOfRows; rowIndex++) {

                        //             }
                        //         }
                        //         else {
                        //             for (var refNumber in object.dynamicBlockKpis) {
                        //                 const sskAnswer = object.answers.find(x => x.refNumber == refNumber && x.column == columnIndex);
                        //                 if (sskAnswer) {
                        //                     object.dynamicBlockKpis[sskAnswer.refNumber].forEach(element => {

                        //                         if ((sskAnswer.textValue != null && sskAnswer.textValue == element.answerToBlock)
                        //                             || (sskAnswer.numberValue != null && sskAnswer.numberValue == element.answerToBlock)) {
                        //                             var subSectionKPI = subSectionKPIList.find(x => x.subSectionKPIID == sskAnswer.subSectionKPIID);

                        //                             if (subSectionKPI) {
                        //                                 var newFieldsToBlock = getFieldsToBlock(
                        //                                     {
                        //                                         'subSectionKPI': subSectionKPI,
                        //                                         'subSectionKPIs': subSectionKPIList,
                        //                                         'refNumbersToBlock': element.refNumbersToBlock,
                        //                                         'column': columnIndex
                        //                                     }
                        //                                 );
                        //                                 if (newFieldsToBlock) {
                        //                                     subSectionKPIsToBlock.push(...newFieldsToBlock);
                        //                                 }
                        //                             }
                        //                         }
                        //                     });
                        //                 }
                        //             }
                        //         }
                        //     }
                        // });
                        // setSubSectionKPIsToBlock(subSectionKPIsToBlock);




                        var calculatedSubsectionKPIs = subSectionKPIList.filter(x => x.calculation != null && x.calculation != '' && x.inputCalc != "Input");

                        calculatedSubsectionKPIs.map((ssk) => {
                            var columns = columnRowsList.find(x => x.columnRowID == ssk.columnRowID)?.minimumQuantity ?? 1;

                            for (let columnIndex = 0; columnIndex < columns; columnIndex++) {

                                if (subSectionRows.some(x => x.subSectionID == ssk.subSectionID)) {

                                    var subsectionRowCount = subSectionRows.find(x => x.subSectionID == ssk.subSectionID);

                                    for (let rowIndex = 0; rowIndex < subsectionRowCount.numberOfRows; rowIndex++) {
                                        object.answers = CalculateTargetSubsectionKPI(ssk, object.answers, subSectionKPIList, columnIndex, rowIndex, object.version, object.columnRows, object, setKPIEvidences, evidences);
                                    }
                                }
                                else {
                                    object.answers = CalculateTargetSubsectionKPI(ssk, object.answers, subSectionKPIList, columnIndex, null, object.version, object.columnRows, object, setKPIEvidences, evidences);
                                }
                            }
                        });

                        setModuleObject(object);


                        setLoading(false);


                        subSectionKPIList.map((ssk) => {
                            var answers = object.answers.filter(x => x.subSectionKPIID == ssk.subSectionKPIID)
                            answers.map((answer) => {
                                toleranceValidations({
                                    'subSectionKPI': ssk,
                                    'column': answer.column,
                                    'row': answer.row,
                                    'value': answer.numberValue,
                                    'text': answer.textValue,
                                    'newAnswers': object.answers,
                                    'subSectionKPIs': subSectionKPIList,
                                    'moduleObject': object
                                });
                                var subSectionKPIColumn = object.columnRows.find(x => x.columnRowID == ssk.columnRowID);
                                var extraIdType = getExtraIdType(subSectionKPIColumn, answers, ssk.refNumber, answer.column, answer.row);

                                validateEvidence({
                                    'subSectionKPIID': ssk.subSectionKPIID,
                                    'value': answer.numberValue != null || answer.numberValue != "" ? answer.numberValue : answer.textValue,
                                    'column': answer.column,
                                    'row': answer.row,
                                    'extraTypeID': extraIdType,
                                    'kpiEvidences': evidences,
                                    'setKPIEvidences': setKPIEvidences,
                                    'answers': object.answers
                                })


                            })
                        });

                    }).catch((error) => {
                        exceptionNotificationAPI(error);
                    });
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }

    }

    const handleSubmit = async (values, reset, event) => {
        event.preventDefault();
        if (userRoles.includes("RoleAdmin")) {
            save(moduleObject.answers, MODULE_ID, false, false);
        } else {
            save(moduleObject.answers, MODULE_ID, false, false);
        }
    }

    const save = async (answers, moduleID, isFinalVersion, isSendEvent = false) => {
        try {
            var inputs = document.querySelectorAll("input, select");
            var mandatoryFields = [];
            var mandatoryComment = [];
            var mandatoryEvidence = [];
            var hasAlertShowing = false;
            var request = {
                answers: answers,
                moduleID: moduleID,
                reportYearID: userObject.selectedReportYearID,
                isFinalVersion: isFinalVersion,
                selectedCompanyID: selectedCompanyID,
            };

            if (waiverIdentifier != null) {
                request.waiverIdentifier = waiverIdentifier;
            }

            for (var i = 0; i < inputs.length; ++i) {
                inputs[i].classList.remove("required-field");
            }

            var matches = document.querySelectorAll(".required-comment, .required-comment-textarea, .required-file, .required-file-btn");
            for (var i = 0; i < matches.length; ++i) {
                matches[i].classList.remove("required-comment");
                matches[i].classList.remove("required-comment-textarea");
                matches[i].classList.remove("required-file");
                matches[i].classList.remove("required-file-btn");
            }

            var alertsMessage = document.querySelectorAll("[id*='_alert']");

            Array.from(alertsMessage).map((alert) => {
                if (!Array.from(alert.classList).includes("hide")) {
                    hasAlertShowing = true;

                    return;
                }
            })

            if (hasAlertShowing) {
                error_message("Have some alert in screen");

                return;
            }

            if (isFinalVersion == true) {
                var hasMandatoryFieldsEmpty = false;
                var hasMandatoryEvidenceEmpty = false;
                mandatoryFields = subSectionKPIs.filter(x => x.mandatory);
                mandatoryEvidence = subSectionKPIs.filter(x => x.evidence == 'Mandatory');
                mandatoryComment = subSectionKPIs.filter(x => x.comment == 'Mandatory');



                mandatoryFields.map((field) => {
                    // var querySelector = document.querySelectorAll(`[id^="subSectionKPI_${field.subSectionKPIID}_${field.columnRowID}"]:not(div)`);


                    moduleObject.data.sections.map((section) => {
                        section.subSections.map((subSection) => {
                            if (subSection.isColumnRow) {
                                [...Array(subSection.numberOfRows)].map((x, row) => {
                                    var element = document.querySelector(`[id^="subSectionKPI_${field.subSectionKPIID}_${field.columnRowID}_r${row}"]:not(div)`);
                                    var answer = answers.find(x => x.subSectionKPIID == field.subSectionKPIID && x.row == row);

                                    if (element != null && (answer == null || answer == "")) {
                                        element.classList.remove("required-field");
                                        if (!element.disabled && element.value == "") {
                                            element.classList.add("required-field");
                                            hasMandatoryFieldsEmpty = true;
                                        }
                                    }

                                })
                            }
                            else {
                                var targetColumn = moduleObject.columnRows.find(x => x.columnRowID == field.columnRowID);
                                console.log(targetColumn);
                                if (targetColumn.minimumQuantity != null && targetColumn.minimumQuantity > 1) {
                                    var tobaccoSelectedCount = selectedTobaccoTypes.length > 0 ? selectedTobaccoTypes.length : 1;
                                    [...Array(tobaccoSelectedCount )].map((x, col) => {
                                        var element = document.querySelector(`[id^="subSectionKPI_${field.subSectionKPIID}_${field.columnRowID}_c${col}"]:not(div)`);
                                        var answer = answers.find(x => x.subSectionKPIID == field.subSectionKPIID && x.column == col);

                                        if (element != null && (answer == null || answer == "")) {
                                            element.classList.remove("required-field");
                                            if (!element.disabled && element.value == "") {
                                                element.classList.add("required-field");
                                                hasMandatoryFieldsEmpty = true;
                                            }
                                        }

                                    })
                                }
                                else {
                                    var element = document.querySelector(`[id^="subSectionKPI_${field.subSectionKPIID}_${field.columnRowID}"]:not(div)`);

                                    if (element != null) {
                                        element.classList.remove("required-field");
                                        if (!element.disabled && element.value == "") {
                                            element.classList.add("required-field");
                                            hasMandatoryFieldsEmpty = true;
                                        }
                                    }
                                }
                            }                            
                        })                        
                    })








                    // var querySelector = `[id^="subSectionKPI_${field.subSectionKPIID}_${field.columnRowID}`;
                    // var answer = answers.filter(x => x.subSectionKPIID == field.subSectionKPIID);
                    // if (answer != null && answer.length > 0) {
                    //     answer.map((a) => {

                    //         if (a.column != null) {
                    //             querySelector += `-c${a.column}`;
                    //         }
                    //         if (a.row != null) {
                    //             querySelector += `-r${a.row}`;
                    //         }
                    //         if (a.yearID != null) {
                    //             querySelector += `_y${a.yearID}`;
                    //         }


                    //         var input = document.querySelector(`${querySelector}"]:not(div)`);


                    //         if (input != null) {
                    //             input.classList.remove("required-field");
                    //             if (!input.disabled && input.value == "") {
                    //                 input.classList.add("required-field");
                    //                 hasMandatoryFieldsEmpty = true;
                    //             }
                    //         }
                    //     })
                    // }
                    // else {
                    //     var inputEmpty = document.querySelector(`[id^='subSectionKPI_${field.subSectionKPIID}']:not(div)`);

                    //     if (inputEmpty != null) {
                    //         inputEmpty.classList.remove("required-field");
                    //         if (!inputEmpty.disabled && inputEmpty.value == "") {
                    //             inputEmpty.classList.add("required-field");

                    //             hasMandatoryFieldsEmpty = true;
                    //         }
                    //     }
                    // }







                    // var querySelector = `[id^="subSectionKPI_${field.subSectionKPIID}_${field.columnRowID}`;
                    // var listCopysToIgnore = [];
                    // var targetColumn = moduleObject.columnRows.find(x => x.columnRowID == field.columnRowID);
                    // for (let i = 0; i < targetColumn.minimumQuantity; i++) {
                    //     if (i > 0) {
                    //         listCopysToIgnore.push("c" + i);
                    //     }
                    // }

                    // var input = document.querySelector(`${querySelector}"]:not(div)`);


                    // if (input != null) {
                    //     input.classList.remove("required-field");
                    //     if (!input.disabled && input.value == "") {
                    //         if (!listCopysToIgnore.includes(input.id.slice(-2))) {
                    //             input.classList.add("required-field");
                    //             hasMandatoryFieldsEmpty = true;
                    //         }
                    //     }
                    // }


                })

                // mandatoryEvidence.map((field) => {
                //     var answer = answers.filter(x => x.subSectionKPIID == field.subSectionKPIID);
                //     var listCopysToIgnore = [];


                //     if (answer != null) {
                //         answer.map((a) => {
                //             var evidence = kpiEvidences.find(d => d.subSectionKPIID == field.subSectionKPIID
                //                 && d.tobaccoTypeID == (a.tobaccoTypeID !== null ? a.tobaccoTypeID : null)
                //                 && d.fuelTypeID == (a.fuelTypeId !== null ? a.fuelTypeId : null)
                //                 && d.curingBarnID == (a.curingBarnID !== null ? a.curingBarnID : null)
                //                 && d.countryID == (a.countryId !== null ? a.countryId : null)
                //                 && d.row == (a.row !== null ? a.row : null)
                //                 && d.column == (a.column !== null ? a.column : null)
                //                 && d.value == a.numberValue
                //                 && d.isValidated == true)


                //             if (a == null || (a != null && evidence == null)) {
                //                 var inputFile;


                //                 var querySelector = `[id^="evidence-button-icon-${field.subSectionKPIID}`;

                //                 if (a.column != null) {
                //                     querySelector += `-c${a.column}`;
                //                 }
                //                 if (a.row != null) {
                //                     querySelector += `-r${a.row}`;
                //                 }
                //                 if (a.yearID != null) {
                //                     querySelector += `_y${a.yearID}`;
                //                 }

                //                 inputFile = document.querySelector(`${querySelector}"]`);

                //                 if (evidence == null) {
                //                     inputFile.style.color = "#ff4747";
                //                 }

                //                 hasMandatoryEvidenceEmpty = true;

                //             }
                //         })
                //     }
                // })

                mandatoryEvidence.map((field) => {
                    var answer = answers.filter(x => x.subSectionKPIID == field.subSectionKPIID);
                    var listCopysToIgnore = [];


                    if (answer != null || answer.length > 0) {
                        answer.map((a) => {
                            if (a.file == null) {
                                var comment;
                                var file;
                                var input;

                                var querySelector = `[id^="subSectionKPI_${field.subSectionKPIID}_${field.columnRowID}`;

                                if (a.column != null) {
                                    querySelector += `_c${a.column}`;
                                }
                                if (a.row != null) {
                                    querySelector += `_r${a.row}`;
                                }
                                if (a.yearID != null) {
                                    querySelector += `_y${a.yearID}`;
                                }


                                comment = document.querySelector(`${querySelector}_comment"]`);
                                file = document.querySelector(`${querySelector}_file"]`);
                                input = document.querySelector(`${querySelector}"]:not(div)`);


                                if ((!input.disabled && input.value == "") || (input.disabled && input.value != "") || (!input.disabled && input.value != "")) {
                                    if (comment != null) {
                                        comment.classList.add("required-file");
                                    }

                                    if (file != null) {
                                        file.classList.add("required-file-btn");
                                    }

                                    hasMandatoryEvidenceEmpty = true;
                                }
                            }
                        })
                    }
                })

                mandatoryComment.map((field) => {
                    var answer = answers.filter(x => x.subSectionKPIID == field.subSectionKPIID);

                    if (answer != null) {
                        answer.map((a) => {
                            if (a == null || (a != null && (a.comment == "" || a.comment == null))) {
                                var comment;
                                var commentText;

                                var querySelector = `[id^="subSectionKPI_${field.subSectionKPIID}_${field.columnRowID}`;

                                if (a.column != null) {
                                    querySelector += `_c${a.column}`;
                                }
                                if (a.row != null) {
                                    querySelector += `_r${a.row}`;
                                }
                                if (a.yearID != null) {
                                    querySelector += `_y${a.yearID}`;
                                }

                                comment = document.querySelector(`${querySelector}_comment"`);
                                commentText = document.querySelector(`${querySelector}_commentText"`);

                                if (comment != null) {
                                    comment.classList.add("required-comment");
                                }

                                if (commentText != null) {
                                    commentText.classList.add("required-comment-textarea");
                                }

                                hasMandatoryEvidenceEmpty = true;
                            }
                        })
                    }
                })

                if (hasMandatoryFieldsEmpty) {
                    error_message("Some fields is required be filled to submit Final Version!");

                    return;
                } else if (hasMandatoryEvidenceEmpty) {
                    error_message("Some comments or files is required be filled to submit Final Version!");

                    return;
                }
            }

            setLoading(true);


            await CheckLastVersionChangesByModules(request, (waiverIdentifier != null ? waiverIdentifier : null), isSendEvent, loadData, setLoading, loading, null);

        }
        catch (error) {
            exceptionNotificationAPI(error);
        }

    }

    const changeSelectedCountry = (value, ssk, row) => {
        if (value == '') {
            Swal.fire({
                title: 'Are you sure you want to change the country value to empty?',
                text: `If you press Yes, the row ${row} of sub section will be cleared`,
                icon: 'warning',
                showDenyButton: true,
                confirmButtonColor: '#112A61',
                denyButtonColor: '#B0B0BB',
                denyButtonText: 'No',
                confirmButtonText: 'Yes',
                showCloseButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    updateSelectedCountry(value, ssk, row, false);
                } else {
                    var changedSelected = document.getElementById(`subSectionKPI_${ssk.subSectionKPIID}`);

                    if (changedSelected)
                        changedSelected.value = selectedCountries[0].value;
                }
            });
        } else {
            updateSelectedCountry(value, ssk, row, false);
        }
    }

    const updateSelectedCountry = (value, ssk, row, updateIndexes) => {
        var answersOfChangedCountry = null;
        var filteredAnswers = null;
        var object = moduleObject;

        var previousSelectedOption = moduleObject.countriesOptions.find(x => x.value == selectedCountries.find(x => x.subSectionID == ssk.subSectionID && x.index == row)?.value);
        if (previousSelectedOption != null) {
            previousSelectedOption.hidden = false;
        }

        var selectedOption = moduleObject.countriesOptions.find(x => x.value == value);

        if (selectedOption != null) {
            selectedOption.hidden = true;
        }

        var selected = {
            value: value,
            text: value != "" ? selectedOption.text : "",
            subSectionID: ssk.subSectionID,
            index: row
        };

        var inputsInRow = [];

        answersOfChangedCountry = moduleObject.answers.filter(x => x.row == row && x.subSectionID == ssk.subSectionID && (previousSelectedOption != null ? x.countryID == previousSelectedOption.value : x.countryID != null));
        filteredAnswers = moduleObject.answers.filter(x => x.row != row || (x.row == row && x.subSectionID != ssk.subSectionID) || (x.row == row && x.countryID == null));
        var currentAnswer = moduleObject.answers.find(x => x.row == row && x.subSectionID == ssk.subSectionID && x.textValue == previousSelectedOption?.value
            && x.subSectionKPIID == ssk.subSectionKPIID && x.refNumber == ssk.refNumber);

        if (currentAnswer != null && selectedOption != null) {
            filteredAnswers = [...filteredAnswers, currentAnswer];
        }

        if (answersOfChangedCountry != null && value != "") {
            answersOfChangedCountry.map(x => {
                x.countryID = value;
            });

            filteredAnswers = [...filteredAnswers, ...answersOfChangedCountry];
        }

        object.answers = filteredAnswers;

        var allCountryFiltered = selectedCountries.filter(x => x.subSectionID != selected.subSectionID || (x.subSectionID == selected.subSectionID && x.index != selected.index));
        var newSelectedCountries = [...allCountryFiltered];


        if (value != "") {
            newSelectedCountries.push(selected);
        }

        newSelectedCountries.sort((a, b) => a.index - b.index);

        if (updateIndexes) {
            newSelectedCountries.map((item) => {
                if (item.index > row && item.subSectionID == ssk.subSectionID) {
                    item.index = item.index - 1;
                }
            });
        }

        setSelectedCountry(newSelectedCountries);

        if (answersOfChangedCountry != null && value == "") {
            answersOfChangedCountry.map((answer) => { //Remove the alert, red border and values in inputs of column changed to ""
                var inputInColumn = document.querySelector(`#subSectionKPI_${answer.subSectionKPIID}_${answer.columnRowID}_r${row}`);
                var alertInRow = document.querySelector(`#subSectionKPI_${answer.subSectionKPIID}_${answer.columnRowID}_r${row}_alert`);

                inputInColumn != null && inputInColumn.classList.remove("alert-input");
                inputsInRow = document.querySelectorAll(`[id^="subSectionKPI_${answer.subSectionKPIID}_"]`);

                if (Array.from(inputsInRow).filter(x => x.className.indexOf("alert-input") > -1).length == 0)
                    alertInRow != null && alertInRow.classList.add("hide");
            });

            object.removedAnswers = [...answersOfChangedCountry];
        }

        setText(
            {
                'subSectionKPI': ssk,
                'ref': ssk.refNumber,
                'value': selected.value,
                'row': row,
                'moduleObject': object,
                'setModuleObject': setModuleObject,
                'toleranceValidations': toleranceValidations,
                'selectedCountries': selectedCountries,
                'subSectionKPIs': subSectionKPIs,
                'countryID': selected.value
            });
    }

    const changeSelectedTobaccoType = (value, ssk, index, column) => {
        if (value == '') {
            Swal.fire({
                title: 'Are you sure you want to change the tobacco type value to empty?',
                // text: `If you press Yes, the column of sub section "Production and farmer data" and "Extension services" will be cleared`,
                icon: 'warning',
                showDenyButton: true,
                confirmButtonColor: '#112A61',
                denyButtonColor: '#B0B0BB',
                denyButtonText: 'No',
                confirmButtonText: 'Yes',
                showCloseButton: true
            }).then((result) => {
                if (result.isConfirmed) {
                    updateSelectedTobaccoType(value, ssk, index, column);
                } else {
                    var changedSelected = document.getElementById(`subSectionKPI_${ssk.subSectionKPIID}`);

                    if (changedSelected)
                        changedSelected.value = selectedTobaccoTypes[0].value;
                }
            });
        } else {
            updateSelectedTobaccoType(value, ssk, index, column);
        }
    }

    const updateSelectedTobaccoType = (value, ssk, index) => {
        var answersOfColumnTobaccoType = null;
        var filteredAnswers = null;
        var object = moduleObject;

        var previousSelectedOption = moduleObject.tobaccoTypesOptions.find(x => x.value == selectedTobaccoTypes[index]?.value);
        if (previousSelectedOption != null) {
            previousSelectedOption.hidden = false;
        }

        var selectedOption = moduleObject.tobaccoTypesOptions.find(x => x.value == value);

        if (selectedOption != null) {
            selectedOption.hidden = true;
        }

        var selected = {
            value: value,
            text: value != "" ? selectedOption.text : ""
        }
        var inputsInRow = [];

        answersOfColumnTobaccoType = moduleObject.answers.filter(x => x.column == index && x.row == null && (previousSelectedOption ? x.tobaccoTypeID == previousSelectedOption?.value : x.tobaccoTypeID != null));
        filteredAnswers = moduleObject.answers.filter(x => x.column != index || (x.column == index && x.tobaccoTypeID == null));
        var currentAnswer = moduleObject.answers.find(x => x.column == index && x.textValue == previousSelectedOption?.value
            && x.subSectionKPIID == ssk.subSectionKPIID && x.refNumber == ssk.refNumber);

        if (currentAnswer != null) {
            filteredAnswers = [...filteredAnswers, currentAnswer];
        }

        if (answersOfColumnTobaccoType != null && value != "") {
            answersOfColumnTobaccoType.map(x => {
                x.tobaccoTypeID = value;
            });

            filteredAnswers = [...filteredAnswers, ...answersOfColumnTobaccoType];
        }

        object.answers = filteredAnswers;

        if (value == "") {
            var selectedEmpty = {
                value: "",
                text: "",
            }

            selectedTobaccoTypes[index] = selectedEmpty;
        }
        else {
            selectedTobaccoTypes[index] = selected;
        }

        setSelectedTobaccoTypes(selectedTobaccoTypes);

        if (answersOfColumnTobaccoType != null && value == "") {
            answersOfColumnTobaccoType.map((answer) => { //Remove the alert, red border and values in inputs of column changed to ""
                var inputInColumn = document.querySelector(`#subSectionKPI_${answer.subSectionKPIID}_${answer.columnRowID}_c${index}`);
                var alertInRow = document.querySelector(`#subSectionKPI_${answer.subSectionKPIID}_${answer.columnRowID}_c${index}_alert`);

                inputInColumn != null && inputInColumn.classList.remove("alert-input");
                inputsInRow = document.querySelectorAll(`[id^="subSectionKPI_${answer.subSectionKPIID}_"]`);

                if (Array.from(inputsInRow).filter(x => x.className.indexOf("alert-input") > -1).length == 0)
                    alertInRow != null && alertInRow.classList.add("hide");
            });

            object.removedAnswers = [...answersOfColumnTobaccoType];
        }

        setText(
            {
                'subSectionKPI': ssk,
                'ref': ssk.refNumber,
                'value': selected.value,
                'isTobaccoType': true,
                'column': index,
                'moduleObject': object,
                'setModuleObject': setModuleObject,
                'toleranceValidations': toleranceValidations,
                'selectedTobaccoTypes': selectedTobaccoTypes,
                'subSectionKPIs': subSectionKPIs,
                'tobaccoTypeID': selected.value
            });
    }

    async function uploadFile({ subSectionKPI, ref, files, subSection, column, row, fuelTypeID, curingBarnID, countryID, isTobaccoType, tobaccoTypeID, totalSubSectionKPIID, moduleObject, setModuleObject, selectedTobaccoTypes, subSectionKPIs }) {
        try {
            if (files.target.files[0].size / 1024 > 30720) {
                error_message("File is too big (max 30mb)!");
                return;
            };
            info_message("The upload was sent!");
            const data = new FormData();
            data.append("files", files.target.files[0]);
            await Api.post("FormValue/uploadFile", data)
                .then(response => {
                    setFile(
                        {
                            'moduleObject': moduleObject,
                            'subSectionKPI': subSectionKPI,
                            'subSection': subSection,
                            'ref': ref,
                            'value': response.data.response,
                            'column': column,
                            'row': row,
                            'fuelTypeID': fuelTypeID,
                            'curingBarnID': curingBarnID,
                            'countryID': countryID,
                            'isTobaccoType': isTobaccoType,
                            'tobaccoTypeID': tobaccoTypeID,
                            'totalSubSectionKPIID': totalSubSectionKPIID,
                            'setModuleObject': setModuleObject,
                            'selectedTobaccoTypes': selectedTobaccoTypes,
                            'subSectionKPIs': subSectionKPIs
                        });
                    success_message("The upload was successfully!");
                })
        }
        catch (error) {
            exceptionNotificationAPI(error);
        }
    }

    const getFile = async (fileName) => {
        setLoadingFile(true);

        await Api.get(`FormValue/getFile/${fileName}`, { responseType: 'blob' })
            .then(res => {
                let url = window.URL.createObjectURL(res.data);
                window.open(url);
                setLoadingFile(false);
            });
    }

    function funcCollapse(id, text, row, col) {
        if (id == collapse || (row != null && collapse != null && collapse != "")) {
            setCollapse("");
            setCollapseText("");
            setCollapseRow("");
            setCollapseCol("");
        }
        else {
            var answerExists = moduleObject.answers.find(x => x.subSectionKPIID == id && (row != null ? x.row == row : true) && (col != null ? x.column == col : true)) != null;

            setCollapse(id);
            setCollapseText(text);
            setCollapseRow(row);
            setCollapseCol(col);
            /*if (answerExists) {
                setCollapse(id);
                setCollapseText(text);
                setCollapseRow(row);
                setCollapseCol(col);
            }
            else
                Swal.fire({
                    title: "",
                    text: "You can't add a comment or an attachment without adding an answer first.",
                    icon: 'warning',
                    showDenyButton: false,
                    confirmButtonColor: '#112A61',
                    confirmButtonText: 'Ok',
                    showCloseButton: true
                })*/
        }
    }

    function collapseSection(s) {
        var index = moduleObject.data.sections.findIndex(x => x.sectionID == s.sectionID)
        var sectionCollapse = moduleObject.data.sections.find(x => x.sectionID == s.sectionID);
        sectionCollapse.collapsed = !sectionCollapse.collapsed
        var newObject = moduleObject
        newObject.data.sections[index] = sectionCollapse
        setModuleObject({ ...newObject })
    }

    function loadRefNumbersAndCalculations(sskpi) {
        if (moduleObject.showSubSectionKPIInformation && sskpi != null) {
            var refNumberJSX = null;
            var calculationJSX = null;
            var toleranceTypeJSX = null;
            var toleranceKPILinkedRefNumberJSX = null;
            var upperToleranceJSX = null;
            var lowerToleranceJSX = null;
            if (sskpi?.refNumber != null) {
                refNumberJSX = <>
                    {sskpi.refNumber}<br />
                </>;
            }
            if (userRoles.includes("RoleAdmin")) {
                if (sskpi.calculation != null && sskpi.calculation != "") {
                    calculationJSX = <>
                        {sskpi.calculation}<br />
                    </>;
                }

                if (sskpi.toleranceType != null && sskpi.toleranceType != "") {
                    toleranceTypeJSX = <>
                        {TOLERANEC_TYPES[sskpi.toleranceType]}<br />
                    </>;
                }

                if (sskpi.toleranceType > 1 && sskpi.toleranceKPILinkedRefNumber != null && sskpi.toleranceKPILinkedRefNumber != "") {
                    toleranceKPILinkedRefNumberJSX = <>
                        {sskpi.toleranceKPILinkedRefNumber}<br />
                    </>;
                }

                if (sskpi.toleranceType == 1 && sskpi.lowerTolerance != null && sskpi.lowerTolerance !== "") {
                    lowerToleranceJSX = <>
                        {sskpi.lowerTolerance}<br />
                    </>;
                }

                if (sskpi.toleranceType == 1 && sskpi.upperTolerance != null && sskpi.upperTolerance !== "") {
                    upperToleranceJSX = <>
                        {sskpi.upperTolerance}<br />
                    </>;
                }
            }

            return <>
                {refNumberJSX}
                {calculationJSX}
                {toleranceTypeJSX}
                {toleranceKPILinkedRefNumberJSX}
                {lowerToleranceJSX}
                {upperToleranceJSX}
            </>
        }
        else {
            return <></>
        }
    }

    return (
        <Container>
            {
                <SessionTimeOut save={save} answers={moduleObject.answers} moduleID={MODULE_ID} statusNumber={moduleObject.moduleStatusNumber} />
            }
            <div className="header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-9">
                            <h2>
                                <FiArrowLeft
                                    color={"#FFFFFF"}
                                    className="icon-left"
                                    size={10}
                                    onClick={() => history.push("/")}
                                />
                                <label className="text-center d-flex align-items-center justify-content-center">{moduleObject.data ? moduleObject.data.description : ""}</label>
                                <div className="text-center d-flex align-items-center justify-content-center">
                                    {
                                        !loading ?
                                            moduleObject.moduleStatus != null && moduleObject.moduleStatusNumber != null ?
                                                <div className={moduleObject.moduleStatusNumber == 0 ?
                                                    "status pending"
                                                    :
                                                    ((moduleObject.moduleStatusNumber == 3 || moduleObject.moduleStatusNumber == 5 || moduleObject.moduleStatusNumber == 8) ?
                                                        "status rejected"
                                                        :
                                                        "status complete")}>
                                                    {approver != "" ? moduleObject.moduleStatus + " - " + approver : moduleObject.moduleStatus}
                                                </div>
                                                :
                                                <div className="status pending">
                                                    Not saved as draft
                                                </div>
                                            :
                                            <></>
                                    }
                                </div>
                            </h2>
                        </div>
                        <div className="col-2" style={{ textAlign: 'right' }}>
                            {
                                !loading && userObject.company != null &&
                                userObject.company.isFSMSupplier && moduleObject.isEditable && moduleObject.data.isFSMModule &&
                                <button className="uploadfsm-btn" type="submit" id={"uploadFSMData"}
                                    onClick={() => {
                                        setShowModalUpload(true)
                                    }}>Import FSM data</button>
                            }
                        </div>
                        <div className='col-1' style={{ textAlign: 'right' }}>
                            {
                                !loading &&
                                <div className='row'>
                                    <div className='col-6'>
                                        <MdComment
                                            color={version != null && (version.commentApproverLocal || version.commentApproverGlobal) && (moduleObject.moduleStatusNumber == 3 || moduleObject.moduleStatusNumber == 5 || moduleObject.moduleStatusNumber == 8) ? "#4DAE49" : "#D2D2DB"}
                                            style={{ cursor: 'pointer' }}
                                            className="icon-comment"
                                            size={30}
                                            onClick={() => {
                                                setModalTitle(moduleObject.data.description)
                                                const hasComment = version != null && (version.commentApproverLocal || version.commentApproverGlobal);
                                                setModalComment(hasComment ?
                                                    version.commentApproverLocal ?
                                                        version.commentApproverLocal
                                                        :
                                                        version.commentApproverGlobal
                                                    :
                                                    "");
                                                setModalCommentator(hasComment ?
                                                    version.commentApproverLocal ?
                                                        version.approverLocalFullName
                                                        :
                                                        version.approverGlobalFullName
                                                    :
                                                    "");
                                                setShow(true);
                                            }}
                                        />
                                    </div>
                                    <OverlayTrigger
                                        delay={{ hide: 0, show: 100 }}
                                        overlay={(props) => (
                                            <Tooltip {...props}>
                                                Display KPIs numbers
                                            </Tooltip>
                                        )}
                                        placement={"right"}>
                                        <div className='col-6'>
                                            <FaRegEye
                                                color={userObject.showSubSectionKPIInformation ? "#4DAE49" : "#D2D2DB"}
                                                style={{ cursor: 'pointer' }}
                                                className="icon-comment"
                                                size={30}
                                                onClick={() => {
                                                    var user = userObject;
                                                    if (user.showSubSectionKPIInformation) {
                                                        user.showSubSectionKPIInformation = !user.showSubSectionKPIInformation;
                                                    }
                                                    else {
                                                        user.showSubSectionKPIInformation = true;
                                                    }
                                                    setUserObject(user);
                                                    localStorage.setItem('@QualityApp:user', JSON.stringify(user));
                                                    setModuleObject({ ...moduleObject, showSubSectionKPIInformation: user.showSubSectionKPIInformation })
                                                }}
                                            />
                                        </div>
                                    </OverlayTrigger>
                                </div>
                            }
                        </div>
                        {MODULE_ID && operationID && selectedCompanyID &&
                            <ThriveScreenNavBar moduleID={MODULE_ID} operationID={operationID} reportYearID={userObject.selectedReportYearID} companyID={selectedCompanyID} setShowModalSelectWaiver={setShowModalSelectWaiver} showModalSelectWaiver={showModalSelectWaiver} />
                        }
                    </div>
                </div>
            </div>
            {
                !userObject.companyID && selectedCompanyID && !userObject.groupID ?
                    <div className="container">
                        <div className="col-md-12">
                            <select onChange={(x) => setSelectedCompanyID(x.target.value)} value={selectedCompanyID} className="mt-3">
                                {
                                    companiesFilter && companiesFilter.length > 0 ?
                                        companiesFilter.map(company => {
                                            return <option key={company.companyID} value={company.companyID}>{company.name}</option>
                                        })
                                        :
                                        <></>
                                }
                            </select>
                        </div>
                    </div>
                    :
                    <></>
            }
            {
                moduleObject.data && moduleObject.data.guidance && moduleObject.data.guidance != "" &&
                <PleaseRead guidance={moduleObject.data.guidance} />
            }
            <Form onSubmit={handleSubmit} ref={formRef}>
                <div className="container body">
                    {loading && <div className="mb-5"><TableLoading /></div>}
                    {!loading && MODULE_ID != null && moduleObject.data && moduleObject.data.sections && moduleObject.data.sections.map((s) => {
                        return <div className="mb-5" key={s.sectionID}>
                            <div className="section-title">
                                <h5>
                                    <strong>
                                        {s.description}
                                    </strong>
                                    {s.isCollapsable ?
                                        <>
                                            {!s.collapsed ?
                                                <FaAngleUp className='hover-disable' size={20} onClick={() => collapseSection(s)} style={{ marginRight: "0px", marginLeft: "auto", marginTop: "auto" }} />
                                                :
                                                <FaAngleDown className='hover-disable' size={20} onClick={() => collapseSection(s)} style={{ marginRight: "0px", marginLeft: "auto", marginTop: "auto" }} />
                                            }
                                        </>
                                        :
                                        <></>
                                    }
                                </h5>

                                {
                                    s.guidance &&
                                    <p>{s.guidance}</p>
                                }

                            </div>
                            <div style={{
                                display: s.collapsed ? 'none' : 'block'
                            }} >
                                {s.subSections.map((ss) => {
                                    var totalColumns = 0;
                                    ss.columnRows.map(e => {
                                        if (e.minimumQuantity) {
                                            totalColumns += e.minimumQuantity;
                                        } else {
                                            totalColumns += 1
                                        }
                                    })
                                    return (<GroupedByColumnRowTable
                                        //calculations={calculations}
                                        subSection={ss}
                                        moduleObject={moduleObject}
                                        setModuleObject={setModuleObject}
                                        toleranceValidations={toleranceValidations}
                                        formatValuesInTooltip={formatValuesInTooltip}
                                        setNumber={setNumber}
                                        setText={setText}
                                        funcCollapse={funcCollapse}
                                        collapse={collapse}
                                        collapseText={collapseText}
                                        collapseRow={collapseRow}
                                        collapseCol={collapseCol}
                                        setComment={setComment}
                                        uploadFile={uploadFile}
                                        kpis={kpis}
                                        defaultChatRequest={defaultChatRequest}
                                        defaultEvidenceRequest={defaultEvidenceRequest}
                                        getFile={getFile}
                                        totalColumns={totalColumns}
                                        //blockedField={blockedField}
                                        MODULE_ID={MODULE_ID}
                                        setChatRequest={setChatRequest}
                                        setEvidenceRequest={setEvidenceRequest}
                                        setShowChat={setShowChat}
                                        setShowUploadEvidence={setShowUploadEvidence}
                                        chatRequest={chatRequest}
                                        evidenceRequest={evidenceRequest}
                                        kpiDiscussions={kpiDiscussions}
                                        kpiEvidences={kpiEvidences}
                                        setKPIEvidences={setKPIEvidences}
                                        // tobaccoTypeID={tobaccoTypeID}
                                        loading={loading}
                                        subSectionKPIs={subSectionKPIs}
                                        changeSelectedTobaccoType={changeSelectedTobaccoType}
                                        selectedTobaccoTypes={selectedTobaccoTypes}
                                        changeSelectedCountry={changeSelectedCountry}
                                        selectedCountries={selectedCountries}
                                        // selectedFuelTypes={selectedFuelTypes}
                                        subSectionKPIsToBlock={subSectionKPIsToBlock}
                                        setSubSectionKPIsToBlock={setSubSectionKPIsToBlock}
                                        updateSelectedCountry={updateSelectedCountry}
                                        loadRefNumbersAndCalculations={loadRefNumbersAndCalculations}
                                        rejectedKpisList={rejectedKpisList}
                                        defaultValues={defaultValues}
                                        fixedKpisList={fixedKpisList}
                                    />)
                                })
                                }
                            </div>

                        </div>
                    })}
                    {!loading && !moduleObject.data.blockedToSend &&
                        <ModuleButtons
                            userRoles={userRoles}
                            showButtons={moduleObject.showButtons}
                            isEditable={moduleObject.isEditable}
                            moduleID={MODULE_ID}
                            answers={moduleObject.answers}
                            selectedCompanyID={selectedCompanyID}
                            selectedReportYearID={userObject.selectedReportYearID}
                            loadData={loadData}
                            save={save}
                            setLoading={setLoading}
                            moduleObject={moduleObject}
                            setModuleObject={setModuleObject}
                            waiverIdentifier={waiverIdentifier ?? null}
                            status={moduleObject.moduleStatusNumber}
                            setShowFixedHeader={null}
                            setReadConsent={setReadConsent}
                            readConsent={readConsent}
                            versionID={versionID}
                            needReadConsent={userObject.needReadConsent}
                            subSectionKPIs={subSectionKPIs}
                            selectedTobaccoTypes={selectedTobaccoTypes}
                            selectedCountries={selectedCountries}
                            fixedKpisList={fixedKpisList}
                        />
                    }
                </div>
            </Form>
            <ModalCommentTabs
                show={show}
                setIdEditing={setIdEditing}
                setRefNumber={setRefNumber}
                setModalTitle={setModalTitle}
                setModalComment={setModalComment}
                setModalCommentator={setModalCommentator}
                setShow={setShow}
                setRow={setRow}
                modalTitle={modalTitle}
                modalCommentator={modalCommentator}
                modalComment={modalComment}
                idEditing={idEditing}
                isEditable={moduleObject.isEditable}
                refNumber={refNumber}
                row={row}
                subSectionID={subSectionID}
                setComment={setComment}
                version={version}
                allVersions={allVersions}
            />
            <ModalUploadFile
                showModalUpload={showModalUpload}
                setShowModalUpload={setShowModalUpload}
                companyID={selectedCompanyID}
                reportYearID={userObject.selectedReportYearID}
                moduleID={MODULE_ID}
                versionID={versionID}
            />
            <ModalLoading open={loadingFile} message={"Loading file..."} />
            <ChatWindow
                request={chatRequest}
                show={showChat}
                status={moduleObject.moduleStatusNumber}
                setShowChat={setShowChat}
            />
            <ModalUploadEvidence
                kpiEvidences={kpiEvidences}
                setKPIEvidences={setKPIEvidences}
                evidenceRequest={evidenceRequest}
                setEvidenceRequest={setEvidenceRequest}
                showUploadEvidence={showUploadEvidence}
                setShowUploadEvidence={setShowUploadEvidence}
                MODULE_ID={MODULE_ID}
            />
            <ModalSelectWaiver
                showModalSelectWaiver={showModalSelectWaiver}
                setShowModalSelectWaiver={setShowModalSelectWaiver}
                companyID={selectedCompanyID}
                reportYearID={userObject?.selectedReportYearID}
                module={moduleObject.data}
            />
        </Container >
    );
}

export default Report;

const formatValuesInTooltip = (value, decimalSeparator, decimalScale, suffix) => {
    return (
        value != null && typeof (value) == "number" ?
            <NumberFormat
                value={value}
                thousandsGroupStyle="thousand"
                decimalSeparator={decimalSeparator}
                decimalScale={decimalScale}
                suffix={suffix}
                displayType="text"
                type="text"
                thousandSeparator={true}
                allowNegative={true}
                allowLeadingZeros={false}
                allowEmptyFormatting={true}
            />
            :
            <>{value}</>
    )
}

