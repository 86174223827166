import { Component, useEffect, useState } from "react";
import Api, { exceptionNotificationAPI } from "./api";
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAuth } from '../hooks/auth';
import { success_message, info_message, error_message } from '../components/Toast/index';
import { func } from "prop-types";
import { toleranceValidations } from "./tolerance";
import GetTypeInput from "../components/FormInput/set";
import { regex } from "uuidv4";
import moment from "moment";


// const [readConsent, setReadConsent] = useState(null);
// const { userRoles } = useAuth();
// const history = useHistory();

var answersToBeValited = [];

const COLUMN_ROW_TYPES = {
    'Simple': 1,
    'Country': 2,
    'FuelType': 3,
    'TobaccoType': 4,
    'Year': 5,
}

export const setNumber = ({ subSectionKPI, ref, value, column, row, fuelTypeID, countryID, isTobaccoType, tobaccoTypeID, totalSubSectionKPIID, moduleObject, setModuleObject, subSectionKPIs, yearID, subSectionKPIsToBlock, setSubSectionKPIsToBlock, isActionPlan = false, }) => {
    if (isActionPlan) {
        var moduleToReturn = setFormAnswers(
            {
                'moduleObject': moduleObject,
                'subSectionKPI': subSectionKPI,
                'ref': ref,
                'value': value != null ? value : "",
                'column': column,
                'row': row,
                'fuelTypeID': fuelTypeID,
                'countryID': countryID,
                'isTobaccoType': isTobaccoType,
                'tobaccoTypeID': tobaccoTypeID,
                'totalSubSectionKPIID': totalSubSectionKPIID,
                'setModuleObject': setModuleObject,
                'subSectionKPIs': subSectionKPIs,
                'yearID': yearID,
                'subSectionKPIsToBlock': subSectionKPIsToBlock,
                'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock,
                'isActionPlan': isActionPlan
            });
        return moduleToReturn;
    } else {
        setFormAnswers(
            {
                'moduleObject': moduleObject,
                'subSectionKPI': subSectionKPI,
                'ref': ref,
                'value': value != null ? value : "",
                'column': column,
                'row': row,
                'fuelTypeID': fuelTypeID,
                'countryID': countryID,
                'isTobaccoType': isTobaccoType,
                'tobaccoTypeID': tobaccoTypeID,
                'totalSubSectionKPIID': totalSubSectionKPIID,
                'setModuleObject': setModuleObject,
                'subSectionKPIs': subSectionKPIs,
                'yearID': yearID,
                'subSectionKPIsToBlock': subSectionKPIsToBlock,
                'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock,
            });
    }
};


export const removeFile = ({ subSectionKPI, ref, value, column, row, fuelTypeID, curingBarnID, countryID, isTobaccoType, tobaccoTypeID, totalSubSectionKPIID, moduleObject, setModuleObject, subSectionKPIs, deleteFile }) => {
    setFormAnswers(
        {
            'moduleObject': moduleObject,
            'subSectionKPI': subSectionKPI,
            'ref': ref,
            'file': value != null ? value : "",
            'column': column,
            'row': row,
            'fuelTypeID': fuelTypeID,
            'curingBarnID': curingBarnID,
            'countryID': countryID,
            'isTobaccoType': isTobaccoType,
            'tobaccoTypeID': tobaccoTypeID != null ? tobaccoTypeID : "",
            'totalSubSectionKPIID': totalSubSectionKPIID,
            'setModuleObject': setModuleObject,
            'subSectionKPIs': subSectionKPIs,
            'deleteFile': deleteFile
        });
};

export const setText = ({ subSectionKPI, ref, value, column, row, fuelTypeID, curingBarnID, countryID, isTobaccoType, tobaccoTypeID, totalSubSectionKPIID, moduleObject, setModuleObject, subSectionKPIs, yearID, subSectionKPIsToBlock, setSubSectionKPIsToBlock, isActionPlan = false }) => {
    if (isActionPlan) {
        var moduleToReturn = setFormAnswers(
            {
                'moduleObject': moduleObject,
                'subSectionKPI': subSectionKPI,
                'ref': ref,
                'text': value != null ? value : "",
                'column': column,
                'row': row,
                'fuelTypeID': fuelTypeID,
                'curingBarnID': curingBarnID,
                'countryID': countryID,
                'isTobaccoType': isTobaccoType,
                'tobaccoTypeID': tobaccoTypeID,
                'totalSubSectionKPIID': totalSubSectionKPIID,
                'setModuleObject': setModuleObject,
                'subSectionKPIs': subSectionKPIs,
                'yearID': yearID,
                'subSectionKPIsToBlock': subSectionKPIsToBlock,
                'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock,
                'isActionPlan': isActionPlan,
            });
        return moduleToReturn
    } else {
        setFormAnswers(
            {
                'moduleObject': moduleObject,
                'subSectionKPI': subSectionKPI,
                'ref': ref,
                'text': value != null ? value : "",
                'column': column,
                'row': row,
                'fuelTypeID': fuelTypeID,
                'curingBarnID': curingBarnID,
                'countryID': countryID,
                'isTobaccoType': isTobaccoType,
                'tobaccoTypeID': tobaccoTypeID,
                'totalSubSectionKPIID': totalSubSectionKPIID,
                'setModuleObject': setModuleObject,
                'subSectionKPIs': subSectionKPIs,
                'yearID': yearID,
                'subSectionKPIsToBlock': subSectionKPIsToBlock,
                'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock,
                'isActionPlan': isActionPlan,
            });
    }
};

export const setComment = ({ subSectionKPI, ref, value, column, row, fuelTypeID, curingBarnID, countryID, isTobaccoType, tobaccoTypeID, totalSubSectionKPIID, moduleObject, setModuleObject, subSectionKPIs, yearID }) => {
    setFormAnswers(
        {
            'moduleObject': moduleObject,
            'subSectionKPI': subSectionKPI,
            'ref': ref,
            'comment': value != null ? value : "",
            'column': column,
            'row': row,
            'fuelTypeID': fuelTypeID,
            'curingBarnID': curingBarnID,
            'countryID': countryID,
            'isTobaccoType': isTobaccoType,
            'tobaccoTypeID': tobaccoTypeID,
            'totalSubSectionKPIID': totalSubSectionKPIID,
            'setModuleObject': setModuleObject,
            'subSectionKPIs': subSectionKPIs,
            'yearID': yearID
        });
};

export const setFile = ({ subSectionKPI, ref, value, column, row, fuelTypeID, curingBarnID, countryID, isTobaccoType, tobaccoTypeID, totalSubSectionKPIID, moduleObject, setModuleObject, subSectionKPIs }) => {
    setFormAnswers(
        {
            'moduleObject': moduleObject,
            'subSectionKPI': subSectionKPI,
            'ref': ref,
            'file': value != null ? value : "",
            'column': column,
            'row': row,
            'fuelTypeID': fuelTypeID,
            'curingBarnID': curingBarnID,
            'countryID': countryID,
            'isTobaccoType': isTobaccoType,
            'tobaccoTypeID': tobaccoTypeID != null ? tobaccoTypeID : "",
            'totalSubSectionKPIID': totalSubSectionKPIID,
            'setModuleObject': setModuleObject,
            'subSectionKPIs': subSectionKPIs
        });
};

const setFormAnswers = ({ moduleObject, setModuleObject, subSectionKPI, ref, value = null, text = null, comment = null, file = null, column = null, row = null, subSectionKPIs, curingBarnID, yearID, subSectionKPIsToBlock, setSubSectionKPIsToBlock, isActionPlan = false, deleteFile = null }) => {
    var newAnswers = moduleObject.answers;
    var myAnswer = getAnswers(
        {
            'newAnswers': newAnswers,
            'ref': ref,
            'subSectionKPI': subSectionKPI,
            'row': row,
            'column': column
        })[0];
    var answerExists = false;

    var subSectionKPIColumn = moduleObject.columnRows.find(x => x.columnRowID == subSectionKPI.columnRowID);

    var extraIdType = getExtraIdType(subSectionKPIColumn, newAnswers, subSectionKPI.refNumber, column, row, text);

    if (myAnswer != null) {
        if (value != null)
            myAnswer.numberValue = value;
        if (text != null)
            myAnswer.textValue = text;
        if (comment != null)
            myAnswer.comment = comment;
        if (file != null) {
            if (deleteFile) {
                myAnswer.file.find(e => e.file == file).isDeleted = deleteFile
            } else {
                if (Array.isArray(myAnswer.file)) {
                    myAnswer.file.push({ file, isDeleted: false })
                } else {
                    myAnswer.file = [{ file, isDeleted: false }];
                }
            }
        }
        if (row != null)
            myAnswer.row = row;
        if (yearID != null)
            myAnswer.yearID = yearID;
        if (extraIdType != null) {
            myAnswer[extraIdType.type] = extraIdType.value;
        }
        myAnswer.isFromFSMIntegration = false;
        answerExists = true;
    } else if (value != null || text != null) {
        myAnswer = {
            "subSectionKPIID": subSectionKPI.subSectionKPIID,
            "refNumber": ref,
            "subSectionID": subSectionKPI.subSectionID,
            "numberValue": value,
            "textValue": text,
            "comment": comment,
            "column": column,
            "row": row,
            "file": file ? [{ file, isDeleted: false }] : null,
            "yearID": yearID
        }
        if (extraIdType != null) {
            myAnswer[extraIdType.type] = extraIdType.value;
        }
        answerExists = true;
    }
    // validateEvidence({
    //     'subSectionKPIID': subSectionKPI.subSectionKPIID,
    //     'value': value != null ? value : "",
    //     'column': column,
    //     'row': row,
    //     'extraTypeID': extraIdType,
    //     'kpiEvidences': kpiEvidences,
    //     'setKPIEvidences': setKPIEvidences,
    //     'answers': newAnswers
    // })

    var notChangedAnswers = getNotChangedAnswers(newAnswers, subSectionKPI, ref, column, row);

    if (answerExists && myAnswer) {
        if (((myAnswer.numberValue != null && (myAnswer.numberValue + "") != "") || myAnswer.textValue != null && (myAnswer.textValue + "") != "") || (value != null && (value + "") != "") || (text != null && text != "")) {
            newAnswers = [...notChangedAnswers, myAnswer];
            answersToBeValited.push({ 'subSectionKPI': subSectionKPI, ...myAnswer });
        }
        else {
            newAnswers = notChangedAnswers;

            //Remove alertas respostas vazias
            var querySelector = `#subSectionKPI_${subSectionKPI.subSectionKPIID}_${subSectionKPI.columnRowID}`;

            if (column != null) {
                querySelector += `_c${column}`;
            }
            if (row != null) {
                querySelector += `_r${row}`;
            }
            var alert = document.querySelector(`${querySelector}_alert`);
            var input = document.querySelector(querySelector);

            input != null && input.classList.remove("alert-input");
            alert != null && alert.classList.add("hide");

        }
    }
    else {
        newAnswers = notChangedAnswers;
    }

    LinkedTolerancesToValidate(subSectionKPI, newAnswers, subSectionKPIs, column, row);

    var newAnswersWithCalculatedValues = [...refNumberCalculationsDependencies(subSectionKPI, newAnswers, subSectionKPIs, column, row, moduleObject.version, moduleObject.columnRows, moduleObject)];

    answersToBeValited.map((answer) => {
        if (answer != null) {
            toleranceValidations({
                'subSectionKPI': answer.subSectionKPI,
                'column': answer.column,
                'row': answer.row,
                'value': answer.numberValue,
                'text': answer.textValue,
                'newAnswers': newAnswersWithCalculatedValues,
                'subSectionKPIs': subSectionKPIs,
                'moduleObject': moduleObject
            });
        }
    });

    answersToBeValited = [];

    // if (moduleObject.dynamicBlockKpis[subSectionKPI.refNumber]) {
    //     if (text != null && text != '')
    //         moduleObject && moduleObject.dynamicBlockKpis[subSectionKPI.refNumber].forEach(element => {
    //             dynamicallyBlockFields(
    //                 {
    //                     'subSectionKPI': subSectionKPI,
    //                     'subSectionKPIs': subSectionKPIs,
    //                     'refNumbersToBlock': element.refNumbersToBlock || [],
    //                     'disable': element.answerToBlock == text,
    //                     'col': column,
    //                     'row': row,
    //                     'subSectionKPIsToBlock': subSectionKPIsToBlock,
    //                     'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock,
    //                     'answerToBlock': element.answerToBlock
    //                 });
    //         })

    //     if (value != null)
    //         moduleObject && moduleObject.dynamicBlockKpis[subSectionKPI.refNumber].forEach(element => {
    //             dynamicallyBlockFields(
    //                 {
    //                     'subSectionKPI': subSectionKPI,
    //                     'subSectionKPIs': subSectionKPIs,
    //                     'refNumbersToBlock': element.refNumbersToBlock || [],
    //                     'disable': element.answerToBlock == value,
    //                     'col': column,
    //                     'row': row,
    //                     'subSectionKPIsToBlock': subSectionKPIsToBlock,
    //                     'setSubSectionKPIsToBlock': setSubSectionKPIsToBlock,
    //                     'answerToBlock': element.answerToBlock
    //                 }
    //             );
    //         })
    // }

    if (isActionPlan) {
        return { ...moduleObject, answers: newAnswersWithCalculatedValues }
    } else {
        setModuleObject({ ...moduleObject, answers: newAnswersWithCalculatedValues });
    }

};

export function validateEvidence({ subSectionKPIID, value, column, row, curingBarnID, extraTypeID, setKPIEvidences, kpiEvidences = null, answers = null }) {
    if (kpiEvidences != null && kpiEvidences.length > 0) {
        var sskEvidence = kpiEvidences.find(d => d.subSectionKPIID == subSectionKPIID
            && d.curingBarnID == (curingBarnID !== null ? curingBarnID : null)
            && (extraTypeID != null ? d[extraTypeID.type] == extraTypeID.value : true)
            && d.row == (row !== null ? row : null)
            && d.column == (column !== null ? column : null)
            && d.isValidated == true);

        if (sskEvidence != null) {
            var sskEvidencesList = kpiEvidences.filter(x => x.evidenceAuditID != sskEvidence.evidenceAuditID);


            if (sskEvidence.value != RoundFixedNumber(value, 2)) {
                sskEvidence.isValidated = false;

                Api.post(`EvidenceAudit/UpdateStatus`, sskEvidence).then((result) => {
                    if (result && result.data.response) {
                        sskEvidencesList.push(sskEvidence);
                        setKPIEvidences(sskEvidencesList);
                    }
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                });


            }
        }
        else {
            if (answers != null) {
                var totalFarmers = answers.find(x => x.refNumber == 1133);

                var sskEvidenceInvalid = kpiEvidences.find(d => d.subSectionKPIID == subSectionKPIID
                    && d.curingBarnID == (curingBarnID !== null ? curingBarnID : null)
                    && (extraTypeID != null ? d[extraTypeID.type] == extraTypeID.value : true)
                    && d.row == (row !== null ? row : null)
                    && d.column == (column !== null ? column : null)
                    && d.isValidated == false
                    && d.farmersCount == totalFarmers.numberValue);

                if (sskEvidenceInvalid != null) {
                    var sskEvidencesList = kpiEvidences.filter(x => x.evidenceAuditID != sskEvidenceInvalid.evidenceAuditID);


                    if (sskEvidenceInvalid.value == RoundFixedNumber(value, 2)) {
                        sskEvidenceInvalid.isValidated = true;

                        Api.post(`EvidenceAudit/UpdateStatus`, sskEvidenceInvalid).then((result) => {
                            if (result && result.data.response) {
                                sskEvidencesList.push(sskEvidenceInvalid);
                                setKPIEvidences(sskEvidencesList);
                            }
                        }).catch((error) => {
                            exceptionNotificationAPI(error);
                        });


                    }
                }
            }

        }
    }
}

export const refNumberCalculationsDependencies = (subSectionKPI, answers, subSectionKPIs = null, column = null, row = null, version, columnRows, moduleObject) => {
    var refNumberCalculations = null;
    var formattedRefNumber = "[" + subSectionKPI.refNumber + "]";
    if (subSectionKPIs != null) {
        refNumberCalculations = subSectionKPIs.filter(x => (x.calculation != null && x.calculation.indexOf(formattedRefNumber) > -1));
    }

    var refNumberCalculationsSorted = refNumberCalculations.sort((a, b) => a.refNumber > b.refNumber ? 1 : -1)

    refNumberCalculationsSorted.map((subSectionKPITarget) => {
        var subSection = getSubSectionFromID(subSectionKPITarget.subSectionID, moduleObject);
        if (subSection != null) {
            var numberOfRows = subSection.isColumnRow ? subSection.numberOfRows : 1;
            for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex++) {
                answers = CalculateTargetSubsectionKPI(subSectionKPITarget, answers, subSectionKPIs, column, rowIndex, version, columnRows, moduleObject);
            }
        }
    });

    return answers;
}

export const CalculateTargetSubsectionKPI = (subSectionKPITarget, answers, subSectionKPIs, column = null, row = null, version, columnRows, moduleObject, setKPIEvidences, kpiEvidences = null) => {
    var valueCalculated = null;
    var valueDisplay = null;
    var subSectionKPIColumn = columnRows.find(x => x.columnRowID == subSectionKPITarget.columnRowID);

    var columnIndex = subSectionKPIColumn?.minimumQuantity > 1 ? column : null;
    var rowIndex = getSubSectionFromID(subSectionKPITarget.subSectionID, moduleObject)?.isColumnRow ? row != null ? row : 0 : null;
    var extraIdType = getExtraIdType(subSectionKPIColumn, answers, subSectionKPITarget.refNumber, columnIndex, rowIndex);

    var answersWithoutTargetRefNumber = getNotChangedAnswers(answers, subSectionKPITarget, subSectionKPITarget.refNumber, columnIndex, rowIndex);

    if (subSectionKPITarget.inputCalc == "Calculation") {
        var isText = subSectionKPITarget.unitMeasureID.toLowerCase() == "A115BFBB-3E3C-4DB2-991D-DF6E84F7549B".toLowerCase()
        if (isText) {
            valueDisplay = calculation(subSectionKPITarget.refNumber, subSectionKPITarget.calculation, answers, version, columnIndex, rowIndex, moduleObject, isText);
        } else {
            valueCalculated = calculation(subSectionKPITarget.refNumber, subSectionKPITarget.calculation, answers, version, columnIndex, rowIndex, moduleObject, isText);
        }
    }
    else if (subSectionKPITarget.inputCalc == "DisplayText") {
        valueDisplay = getTextValueFromCalculationParse(subSectionKPITarget.calculation, answers, columnIndex, rowIndex, subSectionKPIColumn)[0] ?? "null";
    }

    validateEvidence({
        'subSectionKPIID': subSectionKPITarget.subSectionKPIID,
        'value': valueCalculated != null ? valueCalculated : "",
        'column': column,
        'row': row,
        'extraTypeID': extraIdType,
        'kpiEvidences': kpiEvidences,
        'setKPIEvidences': setKPIEvidences
    })

    if (valueCalculated != null || valueDisplay != null) {
        var oldAnswer = getAnswers(
            {
                'newAnswers': answers,
                'ref': subSectionKPITarget.refNumber,
                'subSectionKPI': subSectionKPITarget,
                'row': rowIndex,
                'column': columnIndex
            })[0];

        var targetAnswer = {
            "subSectionKPIID": subSectionKPITarget.subSectionKPIID,
            "refNumber": subSectionKPITarget.refNumber,
            "numberValue": valueCalculated,
            "subSectionID": subSectionKPITarget.subSectionID,
            "textValue": valueDisplay,
            "comment": oldAnswer != null ? oldAnswer.comment : null,
            "row": rowIndex,
            "column": columnIndex,
            "file": oldAnswer != null ? oldAnswer.file : null
        }
        if (extraIdType != null) {
            targetAnswer[extraIdType.type] = extraIdType.value * 1;
        }

        var answerToBeValited = answersToBeValited.find(x => x.subSectionKPIID == targetAnswer.subSectionKPIID
            && x.column == targetAnswer.column
            && x.row == targetAnswer.row);

        if (answerToBeValited == null) {
            answersToBeValited.push({ 'subSectionKPI': subSectionKPITarget, ...targetAnswer });
        }

        answers = [...answersWithoutTargetRefNumber, targetAnswer];
        LinkedTolerancesToValidate(subSectionKPITarget, answers, subSectionKPIs, columnIndex, rowIndex);

        answers = [...refNumberCalculationsDependencies(subSectionKPITarget, answers, subSectionKPIs, columnIndex, row, version, columnRows, moduleObject)];
    } else {
        answers = [...answersWithoutTargetRefNumber];
        answers = [...refNumberCalculationsDependencies(subSectionKPITarget, answers, subSectionKPIs, columnIndex, row, version, columnRows, moduleObject)];
    }

    return answers;
}

export const getAnswers = ({ newAnswers, ref, subSectionKPI, row, column }) => {
    var myAnswer;
    var filter = {
        'subSectionKPIID': subSectionKPI?.subSectionKPIID,
        'refNumber': ref,
        'column': column,
        'row': row,
    }

    myAnswer = newAnswers.filter(function (item) {

        for (var key in filter) {
            if (filter[key] == null)
                continue;
            else if (item[key] == null || item[key] != filter[key])
                return false;
        }
        return true;
    });

    return myAnswer;
};

const getKPIsFromSubsection = (subSection, subSectionKPI) => {
    var subSectionKPIs = [];

    if (subSection != null) {
        subSectionKPIs = subSection.subSectionKPI.map((subSectionKPI) => {
            return subSectionKPI;
        });
    } else {
        subSectionKPIs.push(subSectionKPI);
    }

    return subSectionKPIs;
};

const getNotChangedAnswers = (answers, subSectionKPI, ref, column, row) => {
    var notChangedAnswers = [];

    if (column != null) { //Sub Section - Production and farmer data, Extension services
        notChangedAnswers = answers.filter(x => (x.subSectionKPIID != subSectionKPI.subSectionKPIID)
            || (x.subSectionKPIID == subSectionKPI.subSectionKPIID && x.column != column));
    } else if (row != undefined && column != undefined) { // Sub section - Central Gegraphic coordinates of the tobacco growing States or Province
        notChangedAnswers = answers.filter(x => (x.subSectionKPIID != subSectionKPI.subSectionKPIID)
            || (x.subSectionKPIID == subSectionKPI.subSectionKPIID && x.row != row));
    } else if (row != null) {
        notChangedAnswers = answers.filter(x => (x.subSectionKPIID != subSectionKPI.subSectionKPIID)
            || (x.subSectionKPIID == subSectionKPI.subSectionKPIID && x.row != row));
    } else {
        notChangedAnswers = answers.filter(x => x.subSectionKPIID != subSectionKPI.subSectionKPIID);
    }

    return notChangedAnswers;
};

export async function FindReadConsentLastCropYear(moduleID, companyID, ReportYearID, tobaccoTypeID = null, status, thriveVersionID, userRoles) {
    var readConsentResult = false;

    if (userRoles.includes("RoleApprover") && companyID) {
        if (status == 2) {
            await Api.get(`ReadConsent/FindReadConsentLastCropYear?moduleID=${moduleID}&companyID=${companyID}&ReportYearID=${ReportYearID}&versionID=${thriveVersionID}`).then((result) => {
                if (result && result.data.response) {
                    readConsentResult = result.data.response;
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
        else {
            readConsentResult = true;
        }
    }

    return readConsentResult;
}

export async function CheckEditKPIsToReopenModules(requestKPI, companyID, ReportYearID, moduleID, waiverIdentifier = null, isSendEvent, loadData, setLoading, loading, setLoadingSection, loadingSection, answers) {
    try {
        var request = {
            answers: requestKPI,
            moduleID: moduleID,
            ReportYearID: ReportYearID,
            selectedCompanyID: companyID,
            waiverIdentifier: waiverIdentifier
        };

        Api.post(`FormValue/CheckEditKPIsToReopenModules`, request).then(async (result) => {
            if (result && result.data) {
                // console.log(result.data.response)
                if (result.data.response.descriptionConcat != "") {
                    Swal.fire({
                        title: 'Some modules need to be reopened if the changes are confirmed',
                        text: `If you press Yes, ${result.data.response.descriptionConcat} will be reopened and will need to be approved again. Are you sure?`,
                        icon: 'warning',
                        showDenyButton: true,
                        confirmButtonColor: '#112A61',
                        denyButtonColor: '#B0B0BB',
                        denyButtonText: 'No',
                        confirmButtonText: 'Yes',
                        allowOutsideClick: false,
                        showCloseButton: true
                    }).then(async (rlt) => {
                        if (rlt.isConfirmed) {
                            await Api.post("FormValue/saveAsDraft", answers)
                                .then(async (response) => {
                                    if (isSendEvent) {
                                        await Api.post(`FormValue/send?moduleID=${moduleID}&ReportYearID=${ReportYearID}${waiverIdentifier != null ? `&waiverIdentifier=${waiverIdentifier}` : ""}`).then((results) => {
                                            success_message("The data was sent successfully!");

                                            loadData();
                                        }).catch((error) => {
                                            exceptionNotificationAPI(error);
                                            setLoading(false);
                                            setLoadingSection(false);
                                        });
                                    }
                                    else {
                                        success_message("The data was saved successfully!");
                                        loadData();
                                    }
                                }).then(async () => {
                                    var requestToSend = {
                                        ObjToRequest: result.data.response.moduleIDsList,
                                        companyID: companyID,
                                        ReportYearID: ReportYearID,
                                        waiverIdentifier: waiverIdentifier
                                    }

                                    await Api.post(`FormValue/FlowReopenModules`, requestToSend).then((results) => {
                                        success_message("The modules was reopened successfully!");

                                        loadData();
                                    }).catch((error) => {
                                        exceptionNotificationAPI(error);
                                        setLoading(false);
                                        setLoadingSection(false);
                                    });
                                })
                        } else {
                            setLoading(false);
                            setLoadingSection(false);
                        }
                    });
                }
                else {
                    await Api.post("FormValue/saveAsDraft", answers)
                        .then(async (response) => {
                            if (isSendEvent) {
                                await Api.post(`FormValue/send?moduleID=${moduleID}&ReportYearID=${ReportYearID}${waiverIdentifier != null ? `&waiverIdentifier=${waiverIdentifier}` : ""}`).then((results) => {
                                    success_message("The data was sent successfully!");
                                    loadData();
                                }).catch((error) => {
                                    exceptionNotificationAPI(error);
                                    setLoading(false);
                                    setLoadingSection(false);
                                });
                            }
                            else {
                                success_message("The data was saved successfully!");
                                loadData();
                            }
                        })
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }
    catch (error) {
        exceptionNotificationAPI(error);
    }
}

export async function CheckLastVersionChangesByModules(request, waiverIdentifier = null, isSendEvent, loadData, setLoading, loading, setLoadingSection, loadingSection, setShowFixedHeader = null) {
    try {
        Api.post(`FormValue/CheckLastVersionChangesByModules`, request).then((result) => {
            if (result && result.data) {
                CheckEditKPIsToReopenModules(result.data.response, request.selectedCompanyID, request.reportYearID, request.moduleID, waiverIdentifier, isSendEvent, loadData, setLoading, loading, setLoadingSection, loadingSection, request)
                if (setShowFixedHeader != null)
                    setShowFixedHeader(false)
            }

        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }
    catch (error) {
        exceptionNotificationAPI(error);
    }
}

export function RoundFixedNumber(num, digits, base = 10) {
    var pow = Math.pow(base || 10, digits);
    return Math.round(num * pow) / pow;
}

export function calculation(refNumber, advancedCalculation, answers, version, column = null, row = null, moduleObject, isText = false) {
    var result = null;

    if (version && ![0, 1, 3, 5, 6, 8].some(item => version.status === item)) {
        var answ = getAnswers(
            {
                'newAnswers': answers,
                'ref': refNumber,
                'row': row,
                'column': column
            }
        )[0];
        result = isText ? answ?.textValue : answ?.numberValue;
        
    } else {
        //improvement for calculations:
        //create keyword object, each keyword has it's own function call to execute
        //get all keywords on calculation
        //execute all keywords
        //new check for keywords while found at least 1 keyword on last check

        var formattedCalculation = advancedCalculation.replaceAll(" ", "");

        if (formattedCalculation.includes("GLIDEPATH")) {
            formattedCalculation = formattedCalculation.substring(formattedCalculation.indexOf('(') + 1, formattedCalculation.indexOf(';'));
        }


        if (formattedCalculation.includes("FROMLASTCROPYEAR")) {
            var count = (formattedCalculation.match(/FROMLASTCROPYEAR/g) || []).length;
            for (let index = 0; index < count; index++) {
                var parsed = parseKeyWordsFromCalculation("FROMLASTCROPYEAR", formattedCalculation);

                if (parsed != null) {
                    var splits = parsed.ExpressionValues.split(';')
                    if (splits.length > 0) {
                        var values = [];
                        splits.map((item) => {
                            var ref = '[' + item.replace('[', '').replace(']', '') + '.5' + ']';
                            values.push(...getValueFromCalculationParse(ref, answers));
                        })


                        var sum = values.every(x => x == null) ? "null" : values.reduce((previous, current) => previous + current, 0) + "_";

                        formattedCalculation = formattedCalculation.replace(parsed.ExpressionToReplace, sum);
                    }
                }
            }
        }

        //ALLTYPES(SUM;[$1] * [$2];[15065];[15049])
        if (formattedCalculation.includes("ALLTYPES")) {
            var count = (formattedCalculation.match(/ALLTYPES/g) || []).length;
            for (let index = 0; index < count; index++) {
                var parsed = parseKeyWordsFromCalculation("ALLTYPES", formattedCalculation);

                if (parsed != null) {
                    var [type, expression, ...splits] = parsed.ExpressionValues.split(';')
                    if (type != null && expression != null && splits != null) {
                        var resultFragment = "";
                        if (type === 'SUM' || type === 'MAX' || type === 'AVG') {
                            resultFragment = `${type}([args])`;
                        }

                        var regExp = new RegExp(`(\\[\\$\\d\\])+`, 'g');
                        var expressionMatches = expression.match(regExp);
                        if (getHighestPlaceholderValue(expressionMatches) != splits.length) {
                            resultFragment = 'null';
                        }
                        var answerValues = [];
                        splits.map((item) => {
                            if (item.includes('[')) {
                                var refNumber = item.replace('[', '').replace(']', '')
                                var refNumberAnswers = getAnswers(
                                    {
                                        'newAnswers': answers,
                                        'ref': refNumber
                                    }
                                );

                                answerValues.push(refNumberAnswers);
                            }
                        });
                        var resultList = [];
                        var expressionToEvaluate = expression;
                        var indexOfLongestArray = answerValues.reduce((p, c, i, a) => a[p].length > c.length ? p : i, 0);
                        answerValues[indexOfLongestArray].map((answer) => {
                            var placeholderIndex = indexOfLongestArray + 1;
                            expressionToEvaluate = expressionToEvaluate.replaceAll('[$' + placeholderIndex + ']', answer.numberValue + "_");

                            for (var i = 0; i < answerValues.length; i++) {
                                if (i != indexOfLongestArray) {
                                    var ans = answerValues[i]?.find(x => answer.tobaccoTypeID != null ? x.tobaccoTypeID == answer.tobaccoTypeID : true
                                        && answer.fuelTypeID != null ? x.fuelTypeID == answer.fuelTypeID : true
                                            && answer.countryID != null ? x.countryID == answer.countryID : true) ?? null;
                                    if (ans != null) {
                                        placeholderIndex = i + 1;
                                        expressionToEvaluate = expressionToEvaluate.replaceAll('[$' + placeholderIndex + ']', ans.numberValue + "_");
                                    }
                                }
                            }

                            resultList.push(evaluate(expressionToEvaluate, answers));
                            expressionToEvaluate = expression;
                        });

                        resultFragment = resultFragment.replace('[args]', resultList.join(';'));

                        formattedCalculation = formattedCalculation.replace(parsed.ExpressionToReplace, resultFragment);
                    }
                }
            }
        }

        if (formattedCalculation.includes("MAX")) {
            var count = (formattedCalculation.match(/MAX/g) || []).length;
            for (let index = 0; index < count; index++) {
                var parsed = parseKeyWordsFromCalculation("MAX", formattedCalculation);

                if (parsed != null) {
                    var splits = parsed.ExpressionValues.split(';')
                    if (splits.length > 0) {
                        var values = [];
                        splits.map((item) => {
                            values.push(...getValueFromCalculationParse(item, answers));
                        })


                        var max = values.every(x => x == null) ? "null" : Math.max(...values) + "_";

                        formattedCalculation = formattedCalculation.replace(parsed.ExpressionToReplace, max);
                    }
                }
            }
        }

        if (formattedCalculation.includes("SUMIF")) {
            var count = (formattedCalculation.match(/SUMIF/g) || []).length;
            for (let index = 0; index < count; index++) {
                var parsed = parseKeyWordsFromCalculation("SUMIF", formattedCalculation);

                if (parsed != null) {
                    var [type, condition, matchType, ...splits] = parsed.ExpressionValues.split(';')
                    if (type != null && condition != null && matchType != null && splits != null) {
                        var values = [];
                        //create objects to build a string to use as filter for answers;
                        var filteredAnswers = getFilteredAnswers(type, condition, matchType, answers, moduleObject); // criar
                        //tobacco, fcv, equal -> TobaccoTypeId == getTobaccoTypeId(fcv)
                        //tobacco, fcv, notequal -> TobaccoTypeId != getTobaccoTypeId(fcv)

                        splits.map((item) => {
                            values.push(...getValueFromCalculationParse(item, filteredAnswers));
                        })

                        var sum = values.every(x => x == null) ? "null" : values.reduce((previous, current) => previous + current, 0) + "_";

                        formattedCalculation = formattedCalculation.replace(parsed.ExpressionToReplace, sum);
                    }
                }
            }
        }

        if (formattedCalculation.includes("SUM")) {
            var count = (formattedCalculation.match(/SUM/g) || []).length;
            for (let index = 0; index < count; index++) {
                var parsed = parseKeyWordsFromCalculation("SUM", formattedCalculation);

                if (parsed != null) {
                    var splits = parsed.ExpressionValues.split(';')
                    if (splits.length > 0) {
                        var values = [];
                        splits.map((item) => {
                            values.push(...getValueFromCalculationParse(item, answers));
                        })
                        // console.log(refNumber)
                        // console.log(parsed)
                        // console.log(values)
                        var sum = values.every(x => x == null) ? "null" : values.reduce((previous, current) => previous + current, 0) + "_";

                        formattedCalculation = formattedCalculation.replace(parsed.ExpressionToReplace, sum);
                    }
                }
            }
        }

        if (formattedCalculation.includes("AVG")) {
            var count = (formattedCalculation.match(/AVG/g) || []).length;
            for (let index = 0; index < count; index++) {
                var parsed = parseKeyWordsFromCalculation("AVG", formattedCalculation);

                if (parsed != null) {
                    var splits = parsed.ExpressionValues.split(';')
                    if (splits.length > 0) {
                        var values = [];
                        splits.map((item) => {
                            values.push(...getValueFromCalculationParse(item, answers));
                        })

                        var sum = values.every(x => x == null) ? "null" : values.reduce((previous, current) => previous + current, 0);
                        var avg = sum == "null" ? "null" : ((sum / values.length) || 0) + "_";

                        formattedCalculation = formattedCalculation.replace(parsed.ExpressionToReplace, avg);
                    }
                }
            }
        }

        //IF([15717] < 0;[15033];0)
        if (formattedCalculation.includes("IF")) {
            var count = (formattedCalculation.match(/IF/g) || []).length;
            for (let index = 0; index < count; index++) {
                var parsed = parseKeyWordsFromCalculation("IF", formattedCalculation);

                if (parsed != null) {
                    var splits = parsed.ExpressionValues.split(';')
                    if (splits.length >= 2) {
                        var value = 0;
                        var condition = splits[0];
                        var valueTrue = splits[1];
                        var valueFalse = splits[2] || 0;

                        if (evaluate(condition, answers)) {
                            value = getValueFromCalculationParse(valueTrue, answers, null, null, false, isText);
                        }
                        else {
                            value = getValueFromCalculationParse(valueFalse, answers, null, null, false, isText);
                        }

                        value = value.every(x => x == null) ? "null" : value[0] + "_";

                        formattedCalculation = formattedCalculation.replace(parsed.ExpressionToReplace, value);
                    }
                }
            }
        }

        //ROUND([15717] ; 0)
        if (formattedCalculation.includes("ROUND")) {
            var count = (formattedCalculation.match(/ROUND/g) || []).length;
            for (let index = 0; index < count; index++) {
                var parsed = parseKeyWordsFromCalculation("ROUND", formattedCalculation);

                if (parsed != null) {
                    var splits = parsed.ExpressionValues.split(';')
                    if (splits.length == 2) {
                        var value = 0;
                        var originalValue = splits[0];
                        var decimal = splits[1];
                        value = getValueFromCalculationParse(originalValue, answers)

                        var numberRound = null;

                        if (typeof value[0] === 'number') {
                            numberRound = value.length === 1 ? value[0].toFixed(decimal) + "_" : "null";
                        } else {
                            numberRound = "null";
                        }

                        formattedCalculation = formattedCalculation.replace(parsed.ExpressionToReplace, numberRound);
                    }
                }
            }
        }

        result = evaluate(formattedCalculation, answers, column, row, isText);
    }


    return result;
}

function parseKeyWordsFromCalculation(keyWord, calculationFormula) {
    var regExp = new RegExp(keyWord + `\\(([^)]+)\\)`);
    var matches = regExp.exec(calculationFormula);

    if (matches && matches.length > 0)
        return {
            'ExpressionToReplace': matches[0],
            'ExpressionValues': matches[1]
        };
    else
        return null;
}

function getValueFromCalculationParse(item, answers, column = null, row = null, addUnderline = false, isText = false) {
    var value = [];

    if (item.includes('[')) {
        var refNumber = item.substring(item.indexOf("["), item.indexOf("]")).replace("[", "").replace("]", "")
        var refNumberAnswers = getAnswers(
            {
                'newAnswers': answers,
                'ref': refNumber,
                'row': row,
                'column': column
            }
        );

        if (refNumberAnswers != null && refNumberAnswers.length > 0) {
            refNumberAnswers.map((answer) => {
                var answerValue = Number(item.replaceAll("[" + refNumber + "]", answer.numberValue ? answer.numberValue : answer.textValue)) ? Number(item.replaceAll("[" + refNumber + "]", answer.numberValue ? answer.numberValue : answer.textValue)) : item.replaceAll("[" + refNumber + "]", answer.numberValue ? answer.numberValue : answer.textValue);
                if (addUnderline) {
                    answerValue += "_";
                }
                value.push(answerValue);
            });
        }
        else {
            refNumberAnswers = getAnswers(
                {
                    'newAnswers': answers,
                    'ref': refNumber,
                }
            );
            if (refNumberAnswers != null) {
                refNumberAnswers.map((answer) => {
                    if (answer.column == null && answer.row == null) {
                        var answerValue = Number(item.replaceAll("[" + refNumber + "]", answer.numberValue ? answer.numberValue : answer.textValue)) ? Number(item.replaceAll("[" + refNumber + "]", answer.numberValue ? answer.numberValue : answer.textValue)) : item.replaceAll("[" + refNumber + "]", answer.numberValue ? answer.numberValue : answer.textValue);
                        if (addUnderline) {
                            answerValue += "_";
                        }
                        value.push(answerValue);
                    }
                });
            }
            else {
                value.push(null);
            }
        }
    }
    else {
        var itemHadUnderline = false;
        if (item.includes("_")) {
            item = item.replace("_", "");
            itemHadUnderline = true;
        }
        if (!isText && typeof item === 'number') {
            item = 1 * item
        }
        if (addUnderline && itemHadUnderline) {
            item += "_";
        }
        value.push(item);

    }

    return value;
}

function getTextValueFromCalculationParse(item, answers, column = null, row = null, subSectionKPIColumn = null) {
    var value = [];

    if (item.includes('[')) {
        var refNumber = item.replace('[', '').replace(']', '')
        var refNumberAnswers = getAnswers(
            {
                'newAnswers': answers,
                'ref': refNumber,
                'row': row,
                'column': column
            }
        );

        if (refNumberAnswers != null && refNumberAnswers.length > 0) {
            refNumberAnswers.map((answer) => {
                value.push(answer.textValue);
            });
        }
        else {
            refNumberAnswers = getAnswers(
                {
                    'newAnswers': answers,
                    'ref': refNumber,
                }
            );
            if (refNumberAnswers != null) {
                refNumberAnswers.map((answer) => {
                    if (answer.column == null && answer.row == null) {
                        value.push(answer.textValue);
                    }
                });
            }
            else {
                value.push(null);
            }
        }
    }
    else {
        value.push(1 * item);
    }

    return value;
}

export function evaluate(formattedCalculation, answers, column = null, row = null, isText = false) {
    if (formattedCalculation.includes("null")) {
        if (!isText) {
            formattedCalculation = formattedCalculation.replaceAll("null", 0);
        }
        // return null;
    }
    var result = null;
    if (isText) {
        result = formattedCalculation.replaceAll("_", "").replaceAll("null", "").replaceAll("+", "")
    } else {
        var regexFilter = /[^-()\[\]\d/*+.<>=>&&!_]newDate/g;
        formattedCalculation = formattedCalculation.replace(regexFilter, '');
        var operations = /(?:==|<=|>=|>|<|\+|-|\*|\/)/g;
        var matches = formattedCalculation.match(operations);
        if (matches != null && matches.length > 0) {
            matches = matches.filter((value, index, array) => array.indexOf(value) === index);
            matches.forEach(match => {
                formattedCalculation = formattedCalculation.replaceAll(match, ` ${match} `)
            });
        }

        var advancedCalculationFragments = formattedCalculation.replaceAll("( ", "").replaceAll(" )", "").replaceAll("(", "").replaceAll(")", "").split(" ");

        var expressionToEvaluate = formattedCalculation;

        const specialChars = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
        advancedCalculationFragments.map((fragment, i) => {
            var answer = null;

            if (!isNaN(parseInt(fragment.replace("[", "").replace("]", ""))) && !specialChars.test(fragment.replace("[", "").replace("]", ""))) {
                answer = getValueFromCalculationParse(fragment, answers, column, row, true)[0] ?? "null";

                // var answerValue = answer == "null" ? answer : answer + "_";
                if (answer && typeof answer == "string" && answer.includes("/")) {
                    var date = new Date(moment(answer.split("_")[0], "DD/MM/YYYY"));
                    if (date instanceof Date) {
                        expressionToEvaluate = expressionToEvaluate.replaceAll(fragment, 'new Date(' + moment(answer.replace("_", ""), "DD/MM/YYYY") + ')' + "_");
                    } else {
                        expressionToEvaluate = expressionToEvaluate.replaceAll(fragment, answer);
                    }
                }
                else {
                    expressionToEvaluate = expressionToEvaluate.replaceAll(fragment, answer);
                }
            }
        })

        try {
            if (!expressionToEvaluate.includes("_")) {
                return null;
            }
            else {

                expressionToEvaluate = expressionToEvaluate.replaceAll("_", "").replaceAll("null", "0");
            }
            result = Function("return " + expressionToEvaluate.replace(regexFilter, ''))();
            result = !isFinite(result) ? 0 : result;

        } catch (error) {
            console.log({ "ERROR - Expression": expressionToEvaluate });
        }
    }

    return result;
}

export function GetCurrentRowCount(subSectionID, subSectionKPIList, answers, columnRowsList) {
    var rowCount = 0;
    var rowAnswers = answers.filter(x => x.row != null);


    var sskList = subSectionKPIList.filter(x => x.subSectionID == subSectionID);


    sskList.map((ssk) => {
        var columnRow = columnRowsList.filter(e => e.columnRowID == ssk.columnRowID)[0]

        if (ssk.calculation != null && (ssk.inputCalc == 'Calculation' || ssk.inputCalc == 'DisplayText') && (columnRow != null && (columnRow.kpiReference != "" && columnRow.kpiReference))) {
            var splitRefNumbers

            var regexFilter = /[^-()\[\]\d/*+.<>=>_]/g;
            var formattedCalculation = ssk.calculation.replace(regexFilter, '');
            var operations = /(?:==|<=|>=|>|<|\+|-|\*|\/)/g;
            var matches = formattedCalculation.match(operations);
            if (matches != null && matches.length > 0) {
                matches = matches.filter((value, index, array) => array.indexOf(value) === index);
                matches.forEach(match => {
                    formattedCalculation = formattedCalculation.replaceAll(match, ` ${match} `)
                });
            }

            splitRefNumbers = formattedCalculation.replaceAll("( ", "").replaceAll(" )", "").replaceAll("(", "").replaceAll(")", "").split(" ");

            splitRefNumbers.map((fragment, i) => {
                if (!isNaN(parseInt(fragment.replace("[", "").replace("]", "")))) {
                    var answerList = getAnswers(
                        {
                            'newAnswers': rowAnswers,
                            'ref': fragment.replace("[", "").replace("]", "")
                        });

                    if (answerList.length > 0) {
                        answerList.map((answ) => {
                            if (answ.row > rowCount) {
                                console.log(answ.row, "asnw row count")
                                rowCount = answ.row;
                            }
                        });
                    }
                }
            })
        }

        var answer = getAnswers(
            {
                'newAnswers': rowAnswers,
                'ref': ssk.refNumber
            });

        if (answer.length > 0) {
            answer.map((answ) => {
                if (answ.row > rowCount) {
                    rowCount = answ.row;
                }
            });
        }
    })


    return rowCount + 1;
}

function getSubSectionFromID(subSectionID, moduleObject) {
    var subSection = null;
    moduleObject.data.sections.map((x) => {
        if (subSection == null) {
            if (x.subSections.some(ss => ss.subSectionID == subSectionID)) {
                subSection = x.subSections.find(ss => ss.subSectionID == subSectionID);
                return;
            }
        }
    });

    if (subSection != null) {
        return subSection;
    }

    return false;
}

export function getExtraIdType(columnRow, answers, refnumber, column = null, row = null, textValue = null) {
    if (columnRow?.kpiReference != null) {
        var value = { 'value': null, 'type': null };
        if (columnRow.kpiReference != refnumber) {
            var answer = getAnswers(
                {
                    'newAnswers': answers,
                    'ref': columnRow.kpiReference,
                    'row': row,
                    'column': column
                })[0];

            value.value = answer?.textValue ?? null;
        }
        else {
            value.value = textValue;
        }
        value.type = {
            2: 'countryID',
            3: 'fuelTypeID',
            4: 'tobaccoTypeID'
        }[columnRow.type] ?? null;

        if (value.value != null && value.type != null) {
            return value;
        }
        else {
            return null;
        }
    }
    return null;
}

const LinkedTolerancesToValidate = (sskpi, newAnswers, subSectionKPIs, column = null, row = null) => {
    if (subSectionKPIs != null) {
        var linkedKpis = subSectionKPIs.filter(ssk => ssk.toleranceKPILinkedRefNumber?.includes(sskpi.refNumber));

        linkedKpis.map((ssk) => {
            var answer = getAnswers(
                {
                    'newAnswers': newAnswers,
                    'ref': ssk.refNumber,
                    'subSectionKPI': ssk,
                    'row': row,
                    'column': column
                })[0];

            if (answer != null) {
                var answerToBeValited = answersToBeValited.find(x => x.subSectionKPIID == answer.subSectionKPIID
                    && x.column == answer.column
                    && x.row == answer.row);

                if (answerToBeValited == null) {
                    answersToBeValited.push({ 'subSectionKPI': ssk, ...answer })
                }
            }
        })
    }
}

const setZeroAnswer = (subSectionKPI, newAnswers) => {
    var value = 0;

    var myAnswer = newAnswers.find(x => x.subSectionKPIID == subSectionKPI.subSectionKPIID && x.refNumber == subSectionKPI.refNumber);

    if (myAnswer == null || myAnswer == undefined) {
        var myAnswer = {
            "subSectionKPIID": subSectionKPI.subSectionKPIID,
            "refNumber": subSectionKPI.refNumber,
            "numberValue": value,
            "textValue": null,
            "comment": null,
            "column": null,
            "row": null,
            "file": null
        }
    } else {
        if (myAnswer.numberValue === null || myAnswer.numberValue === undefined) {
            myAnswer.numberValue = value;
        }
    }

    var oldAnswers = newAnswers.filter(x => x.subSectionKPIID != subSectionKPI.subSectionKPIID || x.refNumber != subSectionKPI.refNumber);

    newAnswers = [...oldAnswers, myAnswer];

    return newAnswers;
}

export const handleAddZeroes = async (event, moduleObject, setModuleObject) => {
    event.preventDefault();

    Swal.fire({
        title: 'Are you sure?',
        text: "This action will add zeros into ALL empty numeric fields in the form. Are you sure you wish to proceed?",
        icon: 'warning',
        showDenyButton: true,
        confirmButtonColor: '#112A61',
        denyButtonColor: '#B0B0BB',
        denyButtonText: 'No',
        confirmButtonText: 'Yes',
        showCloseButton: true
    }).then(async (result) => {
        if (result.isConfirmed) {
            var newAnswers = [...moduleObject.answers];

            moduleObject.data.sections.map((section) => {
                section.subSections.map((ss) => {
                    ss.kpis.map((k) => {
                        k.subSectionKPIs.map((ssk) => {
                            if (IsNumericType(ssk.unitMeasure.type))
                                newAnswers = setZeroAnswer(ssk, newAnswers);
                        })
                    })
                })
            });

            setModuleObject({ ...moduleObject, answers: newAnswers });
        }
    })

}

const IsNumericType = (type) => {
    return (!['All/Some/None',
        'Always/Sometimes/Never/NA',
        'Always: 100% of the times/Most of the time: over 90% of the times/Sometimes/Occasionally:less than 90% of the times/Never: 0% of the times',
        'Full cost/Subsidised/Free of Charge',
        'Selection from list: All, More than half, Approximaterly half, Less than half, None',
        'Selection from countries list',
        'Selection from housing',
        'Text'].includes(type));
}

const getFilteredAnswers = (type, condition, matchType, answers, moduleObject) => {
    var filteredAnswers = [];

    var types = {
        'Column': 'column',
        'Row': 'row',
        'Tobacco': 'tobaccoTypeID',
        'Fuel': 'fuelTypeID',
        'Country': 'countryID'
    };

    if (type == 'Tobacco' || type == 'Fuel' || type == 'Country') {
        condition = getTypeIdsFromName(type, condition, matchType, moduleObject);
    }

    filteredAnswers = answers.filter(x => condition.some(c => c.value == x[types[type]]));

    return filteredAnswers;
}

const getTypeIdsFromName = (type, condition, matchType, moduleObject) => {
    var conditionIds = [];

    var types = {
        'Tobacco': 'tobaccoTypesOptions',
        'Fuel': 'fuelTypesOptions',
        'Country': 'countriesOptions'
    };

    switch (matchType) {
        case 'Equal':
            conditionIds = moduleObject[types[type]]?.filter(x => x.text?.replaceAll(' ', '') == condition);
            break;

        case 'NotEqual':
            conditionIds = moduleObject[types[type]]?.filter(x => x.text?.replaceAll(' ', '') != condition);
            break;

        case 'StartWith':
            conditionIds = moduleObject[types[type]]?.filter(x => x.text?.replaceAll(' ', '')?.startsWith(condition));
            break;

        case 'EndWith':
            conditionIds = moduleObject[types[type]]?.filter(x => x.text?.replaceAll(' ', '')?.endsWith(condition));
            break;

        case 'Contain':
            conditionIds = moduleObject[types[type]]?.filter(x => x.text?.replaceAll(' ', '')?.includes(condition));
            break;
        default:
            break;
    }

    return conditionIds;
}


export const getFieldsToBlock = ({ subSectionKPI = null, subSectionKPIs, refNumbersToBlock = [], row, column, answerToBlock }) => {
    let list = [];

    refNumbersToBlock.map(refNumber => {
        var ssk = subSectionKPIs.find(x => x.refNumber == refNumber);
        if (ssk && !list.some(x => x.subSectionKPIID == ssk.subSectionKPIID) && (subSectionKPI != null ? ssk.subSectionID == subSectionKPI.subSectionID : true)) {
            ssk.row = row != null ? row : null;
            ssk.column = column != null ? column : null;
            ssk.answerToBlock = answerToBlock;
            list.push(ssk)
        }
    });

    return list;
}

const dynamicallyBlockFields = ({ subSectionKPI, subSectionKPIs, row, col, refNumbersToBlock = [], subSectionKPIsToBlock, setSubSectionKPIsToBlock, disable = false, answerToBlock, ans }) => {
    var list = JSON.parse(JSON.stringify(subSectionKPIsToBlock));

    let subSectionKPIsToBlockAux = getFieldsToBlock(
        {
            'subSectionKPI': subSectionKPI,
            'subSectionKPIs': subSectionKPIs,
            'refNumbersToBlock': refNumbersToBlock,
            'row': row,
            'column': col
        }
    );

    var IDs = list.map((ssk) => {
        if(row != null && col == null) {
            if((ssk.subSectionID == subSectionKPI.subSectionID && ssk.row != row) || (ssk.subSectionID != subSectionKPI.subSectionID)) {
                return ssk.subSectionKPIID;
            }

        }
        else if(row == null && col != null){
            if(ssk.subSectionKPIID == subSectionKPI.subSectionKPIID && ssk.column != col){
                return ssk.subSectionKPIID;
            }
        }
        else {
            if(ssk.subSectionKPIID == subSectionKPI.subSectionKPIID){
                return ssk.subSectionKPIID;
            }
        }
    })    

    var aux = list.filter(x => !IDs.includes(x.subSectionKPIID));

    aux = [...aux, ...subSectionKPIsToBlockAux]

    setSubSectionKPIsToBlock(aux);

    // aux.forEach(ssk => {
    //     var elementsToBlock = null;
    //     if (row != null) {
    //         elementsToBlock = document.querySelectorAll(`[id^=subSectionKPI_${ssk.subSectionKPIID}_${ssk.columnRowID}_r${row}]`);
    //     }
    //     else if (row == null && col != null) {
    //         elementsToBlock = document.querySelectorAll(`[id^=subSectionKPI_${ssk.subSectionKPIID}_${ssk.columnRowID}_c${col}]`);
    //     }
    //     else {
    //         elementsToBlock = document.querySelectorAll(`[id^=subSectionKPI_${ssk.subSectionKPIID}_${ssk.columnRowID}]`);
    //     }

    //     elementsToBlock.forEach(element => {
    //         setElementDisabled(element, disable);
    //     });
    // });
}

const setElementDisabled = (element, disable) => {
    if (element.localName == 'a')
        return;
    if (element.localName == 'button')
        element = element.nextSibling;

    element.disabled = disable;
}

const getHighestPlaceholderValue = (expressionMatches) => {
    var matches = expressionMatches.map((match) => {
        return match.replace(/\D/g, '');
    })

    return Math.max(...matches);
}
