import styled from 'styled-components';

export const Container = styled.button`
  background: #102A63;
  border: 0;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
  text-transform: uppercase;
  transition: background-color 0.2s;
  min-width: 150px;
  margin-left: 5px;

  &:hover {
    background-color: #102A63;
  }
  
  &:focus{
    background-color: #102A63;
    box-shadow: 0 0 0 0 transparent;
  }

  &:active{
    background-color: #102A63;
    box-shadow: 0 0 0 0 transparent;
  }
`;
