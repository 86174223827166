import styled from 'styled-components';
export const Container = styled.div`
    .header {
        background-color: #FFFFFFFF;
        color: #112A61;
        padding-top: 25px;box-shadow: 0px 3px 20px #0000000D;
    }

    .uploadfsm-btn{
            background-color: #50af47;
            border: 1px solid #50af47;
            color: #FFF;
            padding: 9px 20px;
            border-radius: 5px;
            
            &:hover{
                border: 1px solid #50af47;
                color: #50af47;
                background-color: transparent;
            }
        }

    .header h2 {
        font-weight: bold !important;
        font-size: 22px;
        display: flex;
        
        .icon-left {
            cursor: pointer;
            background-color:#B0B0BB; 
            border-radius: 50%; 
            width: 35px; 
            height: 35px; 
            opacity: 0.5; 
            margin-right: 10px;
            padding: 5px;
    
            &:hover{
                opacity: 1;
                animation: pulse;
                animation-duration: 1s;
            }
        }

        

        div:last-child{
            padding-top: 0;
            text-align: center;
            justify-content: center;
            margin-left: 10px;

            .status{
                border-radius: 13px;
                padding: 6px 15px 6px 15px;
                font-size: 12px;
                min-width: 116px;
                color: black;
            }

            .status.complete{
                background-color: #ABF0B0;
            }

            .status.pending{
                background-color: #FFDA7E;
            }

            .status.rejected{
                background-color: #FB9D9D;
            }
        }
    }
    
    .body{
        margin-top: 32px;
    }

    .section-title{
        margin-bottom: 20px;
        
        h5{
            font-size: 16px;
            margin-bottom: 10px;
            text-shadow: 0px 3px 20px #0000000D;
        }

        p{
            text-align: left;
            font-size: 13px;
            letter-spacing: 0px;
            color: #000000;
            text-shadow: 0px 3px 20px #0000000D;
            opacity: 1;
            margin-bottom: 0;
        }
    }

    select {
        background: transparent;
        color: #495057;
        background-color: #FFFFFF;
        flex: 1;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        width: 100%;

        &::placeholder {
        color: #e2e2e2;
        }

        &:focus{
        box-shadow: 0;
        }

        &:active{
        box-shadow: 0;
        }

        &:focus-visible{
            box-shadow: 0;
            outline: none;
        }

        &:disabled{
        background-color: #e9ecef;
        }

        option{
        background-color: #FFFFFF;
        }
    }

    svg{
        color: #D2D2DB;
        cursor: pointer;

        &:hover{
            color: #112A61;
        }
    }

    .icon-comment{
        color: #D2D2DB;
        opacity: 0.7;
        cursor: pointer;
        
        &:hover{
            color: #4DAE49!important;
            opacity: 1;
            animation: pulse;
            animation-duration: 1s;
        }
    }

    .alert-input{
        border: 1px solid #e71700!important;
    }

    .hide{
        display: none;
    }

    
`;

export const CommentText = styled.div`
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    padding: 0rem 1rem 2rem; 
    font-style: italic; 
`;

export const CommentTextArea = styled.textarea`
    background: #F6F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #D3D2E4;
    border-radius: 4px;
    resize: vertical;
    width: 95%;
    margin: 0.5rem 1rem;
    position: relative;

    &.block-comment{
        background-color: transparent !important;
        border: 0 !important;
        resize: none !important;
        font: italic normal normal 14px Montserrat;
        text-align: left;
    }
`;

export const Table = styled.table`
    font-size: 13px;
    width: 100%;

    thead{
        background-color: #112A61;
        height: 40px;

        tr{
            th{
                font-weight: bold;
                color: #FFFFFFFF;
                padding: 14px;
            }

            th.sub-section-description{
                font-weight: bold;
                color: #FFFFFFFF;
                padding: 14px;
                border-radius: 8px 8px 0px 0px;
            }

            th.sub-section-guidance{
                color: #FFFFFFFF;
                padding: 0 14px 14px 14px;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    tbody:first-child{
        font-weight: bold;
    }

    tbody{
        background-color: #FFFFFFFF;
        box-shadow: 0px 3px 20px #0000000d;

        tr.add-another{
            background-color: #F6F8FA;

            td{
                text-align: left!important;
                
                span{
                    font-weight: bold;
                    color: #387722;
                    opacity: 0.8;
                    cursor: pointer;
    
                    &:hover{
                        opacity: 1;
                    }
                }
            }
        }

        tr{
            border: 1px solid #ECECF3;
            border-left: #FFFFFF;
            border-right: #FFFFFF;

            .upload-btn-wrapper {
                position: relative;
                overflow: hidden;
                display: inline-block;
            }

            .btn-custom {
                background-color: transparent;
                border: 1px solid #B0B0BB;
                color: #B0B0BB;
                padding: 14px 40px 14px 40px;
                border-radius: 5px;
            }

            .upload-btn-wrapper input[type=file] {
                font-size: 100px;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
            }
            
            td{
                padding: 14px;

                &:first-child{
                    text-align: center;
                }
                
                &:last-child{
                    /* text-align: center; */

                    .btn{
                        background-color: transparent;
                        border: 0;
                        padding: 0;
                        margin: 0;
                        cursor: unset;

                        &:focus{
                            background-color: transparent;
                            border: 0;
                            padding: 0;
                            margin: 0;
                            box-shadow: 0 0 0 0 transparent;
                        }

                        &:active{
                            background-color: transparent;
                            border: 0;
                            padding: 0;
                            margin: 0;
                            box-shadow: 0 0 0 0 transparent;
                        }
                    }

                    .dropdown-menu{
                        box-shadow: 0px 3px 20px #00000026;
                        border: 1px solid #ECECF3;
                        border-radius: 4px;
                        font-size: 13px;

                        .dropdown-item{
                            padding-left: 19px;
                            padding-right: 19px;

                            &:active{
                                background-color: #112A61;
                            }
                        }
                    }
                }

                input {
                    background: transparent;
                    color: #495057;
                    background-color: #FFFFFF;
                    flex: 1;
                    padding: 0.375rem 0.75rem;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    border: 1px solid #ced4da;
                    border-radius: 0.25rem;
                    width: 100%;
                    padding-left: 5px!important; 
                    padding-right: 5px!important; 

                    &::placeholder {
                    color: #e2e2e2;
                    }

                    &:focus{
                        box-shadow: none;
                    }

                    &:active{
                        box-shadow: none;
                    }

                    &:focus-visible{
                        box-shadow: none;
                        outline: none;
                    }

                    &:disabled{
                    background-color: #e9ecef;
                    }

                    option{
                    background-color: #FFFFFF;
                    }
                }

                select.read-only, input.read-only{
                    background-color: transparent;
                    border: 0;

                }

                input.read-only-total{
                    background-color: #DEF2D6;
                    color: black;
                    font-weight: bold;
                    border: 1px solid #DEF2D6;
                }

                input.no-total{
                    background-color: transparent;
                    border: 0;
                }

                .required-field{
                    border-color: #ff0000;
                }

                .required-comment svg{
                    color: #ff0000 !important;
                }

                .required-comment-textarea {
                    border-color: #ff0000;
                }

                .required-file svg{
                    color: #ff0000 !important;
                }

                .required-file-btn {
                    border-color: #ff0000 !important;
                    color: #ff0000 !important;
                }

                .required-kpi{
                    color: #ff0000;
                }
            }

            .no-records{
                text-align: center;
            }

            &:last-child{
                td:first-child{
                    border-radius: 0px 0px 0px 8px;
                }

                td:last-child{
                    border-radius: 0px 0px 8px 0px;
                }

                border-bottom: #F6F8FA;
            }
        }
    }
`;
