import React, { useState } from 'react';
import TableCrud from '../../components/TableCrud';

const ModuleIndex = () => {
    const columns = [
        {
            label: "Form",
            name: "operation.description"
        },
        {
            label: "Module Description",
            name: "description"
        },        
        {
            label: "Order",
            name: "order"
        },
        {
            label: "Report Year",
            name: "reportYear.year"
        },
        {
            label: "Active",
            name: "isActive"
        }
    ]

    return (
        <TableCrud title={"Module"} table={"module"} columns={columns}/>
    );
}

export default ModuleIndex;
