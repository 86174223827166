import React from 'react';
import TableCrud from '../../components/TableCrud';

const TobaccoTypeIndex = () => {
    const columns = [
        {
            label: "Name",
            name: "name"
        }
    ]

    return (
        <TableCrud title={"Tobacco Type"} table={"tobaccoType"} columns={columns}/>
    );
}

export default TobaccoTypeIndex;
