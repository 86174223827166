import styled from 'styled-components';
import Collapse from 'react-bootstrap/Collapse';


export const Header = styled.div`
    font-size: 14px;
    margin-bottom:0;
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
`;

export const ContentTitle = styled.div`
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #112A61;
    padding: 1rem;
    font-weight: 500;
    font-weight: bold;
`;

export const ModalBody = styled.div`
    padding: 2rem 1rem;


    .upload-btn-wrapper {
        position: relative;
    }

    .btn-custom {
        padding: 14px 40px 14px 40px;
        width: 100%;
        height: 11rem;
        background: #F2F5F8;
        border: 2px dashed #ACB4BA;
        border-radius: 8px;
    }

    .upload-btn-wrapper input[type=file] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }

    h3{
        margin-top: 10px;
        padding-bottom: 25px;
    }

    .video-react-video {
        width: 100%;
    }

    .modal-content .video-react-controls-enabled {
        padding-top: 0 !important;
    }

    .video-body {
        height: 45rem;
    }

`;

export const VideoModalBody = styled.div`
    height: 47rem;
    padding: 1rem;


    .upload-btn-wrapper {
        position: relative;
    }

    .btn-custom {
        padding: 14px 40px 14px 40px;
        width: 100%;
        height: 11rem;
        background: #F2F5F8;
        border: 2px dashed #ACB4BA;
        border-radius: 8px;
    }

    .upload-btn-wrapper input[type=file] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }

    h3{
        margin-top: 10px;
        padding-bottom: 25px;
    }

    .video-react-video {
        width: 100%;
    }

    .modal-content .video-react-controls-enabled {
        padding-top: 0 !important;
    }

`;

export const CommentTextArea = styled.textarea`
    background: #F6F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #D3D2E4;
    border-radius: 4px;
    resize: vertical;
    width: 95%;
    margin: 0.5rem 1rem;
    position: relative;

    &.block-comment{
        background-color: transparent !important;
        border: 0 !important;
        resize: none !important;
        font: italic normal normal 14px Montserrat;
        text-align: left;
    }

    &.all-versions {
        height: 5rem;
    }
`;


export const Container = styled.div`
    select{
        animation: flipInX;
        animation-duration: 1s;
        border-radius: 4px;
        cursor: pointer;
        padding: 0 6px ;
        border: 1px solid #ECECF3;
        color: #7F7F8A;
        height: 40px;

        option{
            background-color: #F6F8FA;
            color: black;
            cursor: pointer;
        }

        &:focus-visible{
            outline: none;
        }

        &:disabled{
            background-color: #e9ecef;
            border-radius: 5px;
            border: 1px solid #ced4da;
            cursor: auto;
        }
    }

    .hoverRow {
        &:hover {
            border: 1px solid #ced4da;
            border-radius: 5px;
        }
    }

    h2 {
        font-weight: bold !important;
        font-size: 22px;
        display: flex;
    }

    .icon-left {
            cursor: pointer;
            background-color:#B0B0BB;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            opacity: 0.5;
            margin-right: 10px;
            padding: 5px;

            &:hover{
                opacity: 1;
                animation: pulse;
                animation-duration: 1s;
            }
        }

    .folder {
        padding: 1rem;
        align-items: center;
        text-align: center;
        margin: 0 auto;
        position: relative;

        a {
            display: block
        }

        p {
            margin: 0;
            padding-right: 1rem;
        }
        
        .dropdown {
            position: relative;
            right: -5rem;
        }

        .icons {
            display: flex;
            align-items: center;
            justify-content: space-around;
        }

        /* .active_item {
            position: relative;
            right: -6.5rem;
        }

        .edit_item {
            position: relative;
            right: -3rem;
        }

        .active_item_folder {
            position: relative;
            right: -6.5rem;
            top: 0
        }

        .edit_item_folder {
            position: relative;
            right: -3rem;
            top: 0
        } */

        .btn-primary {
            color: #A2A2A2 !important;
            background-color: transparent;
            border-color: transparent;

            &:hover {
                color: #616161 !important;
                background-color: transparent;
                border-color: transparent;
            }
        }
    }

    label{
        animation: flipInX;
        animation-duration: 1s;
    }

    .form-check-input {
        color: #7F7F8A;
        animation: flipInX;
        animation-duration: 1s;
        margin-bottom: 10px;
    }

    .form-check-label {
        margin-left: 6px;
        margin-top: 1px;
    }

    input{
        &:focus{
            box-shadow: none;
        }
    }

    button{
        border-radius: 5px;
        color: #fff;
        margin-right: 19px;
        font-size: 13px;
        cursor: pointer;
        font-weight: bold;
        display: inline-block !important;
        vertical-align: middle !important;
        animation: flipInX;
        animation-duration: 1s;

        &:hover{
            opacity: 0.8;
        }
    }

    .apply-btn{
        background-color: #112A61;
        border: 1px solid #112A61;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;
    }
`;

export const Table = styled.table`
    font-size: 13px;
    width: 100%;
    animation: fadeIn;
    animation-duration: 1s;

    thead{
        background-color: #112A61;
        height: 40px;

        tr{
            th{
                font-weight: bold;
                color: #FFFFFFFF;
                padding: 14px;
            }

            th:first-child{
                border-radius: 8px 0px 0px 0px;
            }

            th:last-child{
                border-radius: 0px 8px 0px 0px;
            }
        }

        &.sticky-top{
            top: -1px!important;
        }
    }

    tbody:first-child{
        font-weight: bold;
    }

    tbody{
        background-color: #FFFFFFFF;
        box-shadow: 0px 3px 20px #0000000d;

        tr{
            border: 1px solid #ECECF3;
            border-left: #FFFFFF;
            border-right: #FFFFFF;

            &.shadow-box{
                box-shadow: 0 4px 2px -2px gray;
            }

            td{
                padding: 14px;

                &:last-child{
                    svg{
                        float: right;

                        &:hover{
                            opacity: 0.5;
                            cursor: pointer;
                        }
                    }
                }
            }

            &:last-child{
                td:first-child{
                    border-radius: 0px 0px 0px 8px;
                }

                td:last-child{
                    border-radius: 0px 0px 8px 0px;
                }

                border-bottom: #F6F8FA;
            }

            .read-only{
                text-align: center;
                background-color: transparent;
                border: 0;
            }

            .value-change{
                color: #4DAE49!important;
            }

            svg{
                color: #112A61;
                animation: flipInX;
                animation-duration: 2s;
            }

            &.sticky-top{
                top: -1px!important;
                background-color: #F6F8FA;
                font-weight: bold;
            }

            .expanded-modal {
            height: 100%; /* Adjust the height as per your requirement */
            }

            .badge{
                color: #fff!important;
                background-color: #112A61;
                border-radius: 10rem;
                color: #112A61;
                animation: flipInX;
                animation-duration: 1s;
            }
        }
    }
`;
export const CollapseStyled = styled(Collapse)`
    vertical-align: 'middle';
    background-color: rgb(250 250 250);

    animation: fadeIn !important;
    animation-duration: 1s !important;
`

