import React, { useState, useEffect } from 'react';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { useAuth } from '../../hooks/auth';

import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import Swal from 'sweetalert2';
import ChatDiscussion from '../Discussion/index';
import { Chat, Discussion } from './styles';
import { ICON_COLORS } from '../ChatButton/colors'

const ChatWindow = ({
    request, show, setShowChat, status
}) => {

    const emptyChat = {
        isDisabled: false,
        showButtons: false,
        discussions: [],
        currentDiscussionId: null,
        isLastDiscussionOpen: false
    };

    const { userRoles } = useAuth();
    const [sendingMessage, setSendingMessage] = useState(true);
    const [loadChat, setLoadChat] = useState(false);
    const [chatLoaded, setChatLoaded] = useState(false);
    const [userObject, setUserObject] = useState(JSON.parse(localStorage.getItem('@QualityApp:user')));
    const [chat, setChat] = useState({ ...emptyChat });
    const [chatStatus, setChatStatus] = useState("default");

    useEffect(() => {
        if (show == true)
            setLoadChat(!loadChat);
        else if (show == false) {
            setSendingMessage(true);
            setChat({ ...emptyChat });
        }
    }, [show]);

    useEffect(() => {
        loadData().then(scrollToEnd);
    }, [loadChat]);

    const loadData = async () => {
        setChatLoaded(false);
        if (request.kpiId) {
            var object = chat;

            await Api.get(`Discussion/get?${buildGetQuery()}`).then(async (result) => {
                if (result && result.data) {
                    object.discussions = result.data.response;
                    if (object.discussions.length > 0) {
                        var lastDiscussion = object.discussions[object.discussions.length - 1];
                        object.currentDiscussionId = lastDiscussion.discussionID;
                        object.isDisabled = lastDiscussion.dateEndMessage != null && !canStartDiscussion();

                        setChat(object);
                        setSendingMessage(false);
                        setChatStatus(lastDiscussion.dateEndMessage != null ? "closed" : "open");
                        setChatLoaded(true);
                    }
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });

            if (chat.discussions && chat.discussions.length < 1) {
                if (canStartDiscussion()) {
                    Swal.fire({
                        title: 'Do you want to start a new discussion?',
                        text: "There is no discussion open for this KPI. Do you want to start a new discussion?",
                        icon: 'warning',
                        showDenyButton: true,
                        confirmButtonColor: '#112A61',
                        denyButtonColor: '#B0B0BB',
                        denyButtonText: 'Cancel',
                        confirmButtonText: 'Start new discussion',
                        showCloseButton: true
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            await startNewDiscussion(object);
                            setChatLoaded(true);
                        }
                        else {
                            setShowChat(false);
                        }
                    });
                }
                else {
                    Swal.fire({
                        title: "",
                        text: "There is no discussion open for this KPI.",
                        icon: 'warning',
                        showDenyButton: false,
                        confirmButtonColor: '#112A61',
                        confirmButtonText: 'Ok',
                        showCloseButton: true
                    });
                    setShowChat(false);
                }
            }
        }
    }

    const editMessageDiscussion = async (message) => {
        Api.put(`Discussion/Message/Update`, message).then((result) => {
            if (result && result.data) {
                let chatUpdate = chat
                let discussionUpdate = chat.discussions[0];
                let messageToUpdate = discussionUpdate.messages.find(e => e.messageID == message.messageID);
                let messageToUpdateIndex = discussionUpdate.messages.findIndex(e => e.messageID == message.messageID);
                discussionUpdate.messages[messageToUpdateIndex] = result.data.response
                chatUpdate.discussions[0] = discussionUpdate
                setChat({ ...chatUpdate })
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }


    const buildGetQuery = () => {
        var query = "";
        if (request.reportYearID != null)
            query += `reportYearID=${request.reportYearID}`

        if (request.companyId != null)
            query += `&&companyId=${request.companyId}`

        if (request.moduleId != null)
            query += `&&moduleId=${request.moduleId}`

        if (request.kpiId != null)
            query += `&&kpiId=${request.kpiId}`

        if (request.tobaccoTypeID != null)
            query += `&&tobaccoTypeId=${request.tobaccoTypeID}`

        if (request.fuelTypeId != null)
            query += `&&fuelTypeId=${request.fuelTypeId}`

        if (request.curingBarnID != null)
            query += `&&curingBarnID=${request.curingBarnID}`

        if (request.countryId != null)
            query += `&&countryId=${request.countryId}`

        if (request.row != null)
            query += `&&row=${request.row}`

        if (request.column != null)
            query += `&&column=${request.column}`

        if (request.subSectionKPIID != null)
            query += `&&subSectionKPIID=${request.subSectionKPIID}`

        return query;
    }

    const onSendMessagePressed = async () => {
        setSendingMessage(true);
        var input = document.getElementsByClassName("message_input")[0];
        var object = chat;

        var lastDiscussion = object.discussions.find((d) => {
            return d.discussionID == object.currentDiscussionId
        })

        if (lastDiscussion.dateEndMessage != null && canStartDiscussion()) {
            await startNewDiscussion(object);

            setSendingMessage(true);

            lastDiscussion = object.discussions.find((d) => {
                return d.discussionID == object.currentDiscussionId
            })
        }

        var message = {
            "discussionID": chat.currentDiscussionId,
            "text": input.value
        };

        message.text = message.text ? message.text.trim() : "";

        if (message.text) {
            await Api.post("Message/send", message).then(async (result) => {
                if (result && result.data) {
                    lastDiscussion.messages.push(result.data.response);
                    setChat(object);
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
                setSendingMessage(false);
            });
        }

        input.value = "";
        setChatStatus("open");
        setSendingMessage(false);
        scrollToEnd();
    }

    const onEndDiscussionPressed = async () => {
        if (canEndDiscussion()) {
            if ((status && (status == 4 || status == 7)) || (request && request.status && (request.status == 4 || request.status == 7))) {
                setSendingMessage(true);
                var input = document.getElementsByClassName("message_input")[0];

                var discussion = {
                    "discussionID": chat.currentDiscussionId,
                    "endMessage": input.value
                }

                discussion.endMessage = discussion.endMessage ? discussion.endMessage.trim() : "Discussion ended without a message.";

                await Api.post("Discussion/end", discussion).then(async (result) => {
                    if (result && result.data) {
                        var object = chat;
                        var lastDiscussion = object.discussions.find((d) => {
                            return d.discussionID == object.currentDiscussionId
                        })
                        lastDiscussion.endMessage = result.data.response.endMessage;
                        lastDiscussion.dateEndMessage = result.data.response.dateEndMessage;

                        setChat(object);
                    }
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                    setSendingMessage(false);
                });

                input.value = "";
                setChatStatus("closed");
                setSendingMessage(false);
                scrollToEnd();
            } else {
                Swal.fire({
                    title: "You cannot close the discussion!",
                    text: "For you to be able to close the discussion, the module must have the status Sent to global approver or Approved.",
                    icon: 'warning',
                    showDenyButton: false,
                    confirmButtonColor: '#112A61',
                    confirmButtonText: 'Ok',
                    showCloseButton: true
                })
            }
        }
    }

    const canStartDiscussion = () => {
        var canStartDiscussion = false;

        canStartDiscussion = userRoles.includes("RoleReviewer") ? true : userRoles.includes("RoleApprover") ? userObject.companyID === null : canStartDiscussion;

        return canStartDiscussion;
    }

    const canEndDiscussion = () => {
        var canStartDiscussion = false;

        canStartDiscussion = userRoles.includes("RoleReviewer") ? true : userRoles.includes("RoleApprover") ? userObject.companyID === null : canStartDiscussion;

        return canStartDiscussion;
    }

    const startNewDiscussion = async (object) => {
        setSendingMessage(true);

        await Api.post(`Discussion/new`, request).then(async (result) => {
            if (result && result.data) {
                if (result.data.response != null) {
                    object.discussions = [...object.discussions, result.data.response];
                    object.currentDiscussionId = object.discussions[object.discussions.length - 1].discussionID;

                    setChat(object);
                    setSendingMessage(false);
                    setChatStatus("open");
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const scrollToEnd = async () => {
        const discussionElement = document.querySelector("#discussion");
        if (discussionElement)
            discussionElement.scrollTo(0, discussionElement.scrollHeight);
    }

    const sendOnEnterPressed = async (e) => {
        if (e.charCode === 13 && e.ctrlKey)
            onSendMessagePressed()
    }

    const setChatButtonIconColor = () => {
        var button = document.querySelector(`[id^=discussion-button-icon-` + request.subSectionKPIID
            + "-" + request.column ?? 0
            + "-" + request.row ?? 0
            + "-" + request.tobaccoTypeID ?? 0
            + "-" + request.fuelTypeID ?? 0
            + "-" + request.curingBarnID ?? 0
            + "-" + request.countryID ?? 0 + `]`);
        if (button)
            button.style.color = ICON_COLORS[chatStatus];
        if (chat.discussions[0]?.messages.length > 0) {
            if (chat.discussions[0]?.messages[chat.discussions[0].messages.length - 1].createdBy == userObject.id || chatStatus == "closed") {
                if (button.nextElementSibling != null) {
                    button.nextElementSibling.style.display = 'none';
                }
            }
        }

    }

    const closeChat = () => {
        var input = document.getElementsByClassName("message_input")[0];
        if (input.value != "" && input.value) {
            Swal.fire({
                title: 'Do you want to close discussion?',
                text: "There is message not send. Do you want to close discussion?",
                icon: 'warning',
                showDenyButton: true,
                confirmButtonColor: '#112A61',
                denyButtonColor: '#B0B0BB',
                denyButtonText: 'No',
                confirmButtonText: 'Yes',
                showCloseButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setShowChat(false);
                    setChatButtonIconColor();
                }
            });
        } else {
            setShowChat(false);
            setChatButtonIconColor();
        }
    }

    return chatLoaded && show &&
        <Modal
            show={show}
            size="lg"
            centered
            className={"chat_modal"}
            onHide={closeChat}
        >
            <ModalBody style={{ padding: "0" }}>
                <Chat class="chat_window">
                    <div class="top_menu">
                        <div class="title">
                            {chat.discussions.length > 0 ? chat.discussions[0].discussionTitle : "Discussion"}
                            <div class="button-close" onClick={closeChat}>
                                X
                            </div>
                        </div>

                    </div>
                    <Discussion id="discussion">
                        {!sendingMessage && chat.discussions.map((discussion) => {
                            return <ChatDiscussion discussion={discussion} currentUser={userObject.id} userObject={userObject} editMessage={editMessageDiscussion} />;
                        })}
                    </Discussion>
                    <div class="bottom_wrapper clearfix">
                        <div class="message_input_wrapper">
                            <textarea class="message_input" placeholder="Type your message here..." disabled={chat.isDisabled} row="4" onKeyPress={sendOnEnterPressed} />
                        </div>
                        <div class="button_wrapper">
                            <button class="send_message" onClick={onSendMessagePressed} disabled={chat.isDisabled} title="Ctrl + Enter">
                                <div class="text">Send</div>
                            </button>
                            {canEndDiscussion() && <div class="end_discussion" onClick={onEndDiscussionPressed} disabled={chat.isDisabled}>
                                <div class="text" >End Discussion</div>
                            </div>}
                        </div>
                    </div>
                </Chat>
            </ModalBody>
        </Modal>

}

export default ChatWindow;
