import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container } from './styles';
import { useAuth } from '../../hooks/auth';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { FcFolder, FcVideoFile, FcDocument, FcImageFile } from "react-icons/fc";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import { GrDocumentPdf } from "react-icons/gr";
import { SiMicrosoftexcel } from "react-icons/si";
import { MdEdit } from "react-icons/md";
import { Link } from 'react-router-dom';
import FormFiles from './FormFiles';
import FormFolder from './FormFolder';
import VideoPlayer from './VideoPlayer';
import { FiArrowLeft, FiMoreVertical } from 'react-icons/fi';
import Dropdown from 'react-bootstrap/Dropdown';
import { Player } from 'video-react';
import EditFiles from './EditFiles';
import TableLoading from '../../components/TableLoading';
import Swal from 'sweetalert2';

const IndexFolder = () => {
    const id = useParams(null);
    const [foldersList, setFoldersList] = useState([]);
    const [filesList, setFilesList] = useState([]);
    const [documentUploadIDSelected, setDocumentUploadIDSelected] = useState(id?.documentFolderID ?? null);
    const [documentUploadFileIDSelected, setDocumentUploadFileIDSelected] = useState(null);
    const [showFileEditModal, setShowFileEditModal] = useState(null);
    const [videoURLSelected, setVideoURLSelected] = useState(null);
    const [folderName, setFolderName] = useState("");
    const [showFileModal, setShowFileModal] = useState(false);
    const [empty, setEmpty] = useState(false);
    const [showFolderModal, setShowFolderModal] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [isNewFolder, setIsNewFolder] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [userRole, setUserRole] = useState(JSON.parse(localStorage.getItem('@QualityApp:userRoles')));

    useEffect(() => {
        setFoldersList([]);
        setFilesList([]);
        loadFolders();
    }, [id]);

    const loadFolders = () => {
        if (id.documentFolderID != null) {
            loadDataByFolder();
            getFolderName();
        }
        else {
            loadData();
            getFolderName();
        }
    }

    const loadData = async () => {
        setLoading(true);
        Api.get('DocumentUploadFolder/getAllFolders').then((result) => {
            if (result && result.data) {
                setFoldersList(result.data.response);
                setLoading(false)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadDataByFolder = async () => {
        setFoldersList([]);
        setFilesList([]);
        setEmpty(false)
        setLoading(true);
        if (id != null) {
            Api.get(`DocumentUploadFolder/getParentFolders?folderID=${id.documentFolderID}`).then((result) => {
                if (result && result.data) {
                    setFoldersList(result.data.response);

                    Api.get(`DocumentUploadFiles/getAll?folderID=${id.documentFolderID}`).then((resultx) => {
                        if (resultx && resultx.data) {
                            setFilesList(resultx.data.response);

                            if(result.data.response.length == 0 && resultx.data.response.length == 0){
                                setEmpty(true);
                            }
                        }
                    }).catch((error) => {
                        exceptionNotificationAPI(error);
                    });
                    setLoading(false)
                    
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
        else {
            Api.get(`DocumentUploadFolder/getAll`).then((result) => {
                if (result && result.data) {
                    setFoldersList(result.data.response);
                    setLoading(false)
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }

    const EditFolder = (documentFolderID) => {
        setShowFolderModal(true)
        setDocumentUploadIDSelected(documentFolderID)
        //history.push(`/documentUploadFolder/edit/${documentFolderID}`);
    }

    const IsActiveFolder = (id) => {
        Api.post(`DocumentUploadFolder/activateFolder?id=${id}`).then((resultx) => {
            if (resultx && resultx.data) {
                loadFolders()
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const IsActiveFile = (id) => {
        Api.post(`DocumentUploadFiles/activateFile?id=${id}`).then((resultx) => {
            if (resultx && resultx.data) {
                loadFolders()
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const DeleteFolder = (id) => {
        Swal.fire({
            title: 'Are you sure that want to delete this folder?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showDenyButton: true,
            width: '45rem',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#102A63',
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Confirm',
            showCloseButton: false,
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                Api.post(`DocumentUploadFolder/deleteFolder?id=${id}`).then((resultx) => {
                    if (resultx && resultx.data) {
                        window.location.reload();
                    }
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                });
            }
        })
       
    }

    const DeleteFile = (id) => {
        Swal.fire({
            title: 'Are you sure that want to delete this file?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showDenyButton: true,
            width: '45rem',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#102A63',
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Confirm',
            showCloseButton: false,
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                Api.post(`DocumentUploadFiles/deleteFile?id=${id}`).then((resultx) => {
                    if (resultx && resultx.data) {
                        window.location.reload();
                    }
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                });
            }
        })
        
    }

    const EditFile = (id) => {
        setIsNewFolder(false)
        setDocumentUploadFileIDSelected(id)
        setShowFileEditModal(true)
    }

    const getIcon = (format, isActive) => {
        if (format == "png" || format == "jpg") {
            return (
                <FcImageFile size={35} />
            )
        }
        else if (format == "pdf" || format == "xlsx" || format == "xls" || format == "csv" || format == "docx") {
            return (
                <FcDocument size={35} />
            )
        }
        else if (format == "mp4" || format == "avi" || format == "wmv") {
            return (
                <FcVideoFile size={35} />
            )
        }

    }

    const getFolderName = () => {
        if (id.documentFolderID) {
            Api.get(`DocumentUploadFolder/get?folderID=${id.documentFolderID}`).then((resultx) => {
                if (resultx && resultx.data) {
                    var s = resultx.data.response.folderName;
                    setFolderName(s);

                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });

        }
        else {
            setFolderName("Documents")
        }
    }

    const openOrDownloadFile = (url, format, fileName) => {
        if (format == "mp4") {
            setVideoURLSelected(url);
            setShowVideoModal(true);
        }
        else {
            getFile(url)
        }
    }

    const getFile = async (url) => {
        window.open(url);
    }

    const createfolder = async () => {
        setIsNewFolder(true)
        setShowFolderModal(true)
    }

    return (<>
        <Container className='container mt-5'>
            <div className="buttons_header">
                <div className="row">
                    <div className="col-1">                        
                            <FiArrowLeft
                                color={"#FFFFFF"}
                                className="icon-left"
                                size={10}
                                onClick={() =>JSON.stringify(id) === '{}' ? history.push("/") : history.goBack()}
                            />
                        
                    </div>
                    <div className="col-7 py-1">
                        <h2 className="text-left d-flex align-items-left justify-content-left">{folderName}</h2>
                    </div>
                    {
                        userRole == "RoleAdmin" &&
                        <>
                            <div className="col-2 text-right">
                                <button className='btn btn-primary px-3' onClick={() => createfolder()}>Add Folder</button>
                            </div>
                            <div className="col-2 text-left">
                                <button className='btn btn-primary px-4' onClick={() => setShowFileModal(true)}>Add File</button>
                            </div>
                        </>
                    }

                </div>
            </div>
        </Container>
        <Container className='container mt-5 bg-white'>
            <div className="row mt-5 ">
                {!loading ?
                    empty == true ?
                        <div className='pt-3 pb-5 mt-5 text-center'>No folders found!</div>
                        :
                        <>
                            {foldersList.map((folder) => {
                                return (
                                    <>
                                        <div className="col-12 hoverRow">
                                            <div className="folder">
                                                <div className="row">
                                                    <div className="col-10">
                                                        <Link to={`/Documents/id=${folder.documentUploadFolderID}`} title="folder">
                                                            <div className="row">
                                                                <div className="col-1 d-flex align-items-center">
                                                                    <FcFolder size={30} />
                                                                </div>
                                                                <div className="col-11 d-flex align-items-center">
                                                                    <p>{folder.folderName}</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-2 icons">
                                                        <div className="">
                                                            {userRole == "RoleAdmin" && !folder.isActive ?
                                                                <BsEyeSlash className='active_item_folder' size={17} onClick={() => IsActiveFolder(folder.documentUploadFolderID)} />
                                                                : userRole == "RoleAdmin" && folder.isActive ?
                                                                    <BsEye className='active_item_folder' size={17} onClick={() => IsActiveFolder(folder.documentUploadFolderID)} />
                                                                    : <></>
                                                            }
                                                        </div>
                                                        <div className="">
                                                            {
                                                                userRole == "RoleAdmin" &&
                                                                <><MdEdit className='edit_item_folder' size={17} onClick={() => EditFolder(folder.documentUploadFolderID)} /></>
                                                            }
                                                        </div>
                                                        <div className="">
                                                            {
                                                                userRole == "RoleAdmin" &&
                                                                <><RiDeleteBinLine className='edit_item_folder' size={17} onClick={() => DeleteFolder(folder.documentUploadFolderID)} /></>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div >
                                    </>
                                )
                            })
                            }
                            {filesList.map((file) => {
                                return (
                                    <div className="col-12 hoverRow">
                                        <div className="folder">
                                            <div className="row">
                                                <div className="col-10">
                                                    <div className="row" onClick={() => openOrDownloadFile(file.url, file.format, file.blobPath)}>
                                                        <div className="col-1 d-flex align-items-center">
                                                            {getIcon(file.format, file.isActive)}
                                                        </div>
                                                        <div className="col-11 d-flex align-items-center">
                                                            {file.fileName}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-2 icons">
                                                    <div className="">
                                                        {userRole == "RoleAdmin" && !file.isActive ?
                                                            <BsEyeSlash className='active_item' size={17} onClick={() => IsActiveFile(file.documentUploadFilesID)} />
                                                            : userRole == "RoleAdmin" && file.isActive ?
                                                                <BsEye className='active_item' size={17} onClick={() => IsActiveFile(file.documentUploadFilesID)} />
                                                                : <></>
                                                        }
                                                    </div>
                                                    <div className="">
                                                        {
                                                            userRole == "RoleAdmin" &&
                                                            <><MdEdit className='edit_item' size={17} onClick={() => EditFile(file.documentUploadFilesID)} /></>
                                                        }
                                                    </div>
                                                    <div className="">
                                                        {
                                                            userRole == "RoleAdmin" &&
                                                            <><RiDeleteBinLine className='edit_item_folder' size={17} onClick={() => DeleteFile(file.documentUploadFilesID)} /></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                )
                            })
                            }
                        </>


                    :
                    <TableLoading />
                }
            </div>


        </Container >
        <FormFiles
            showFileModal={showFileModal}
            setShowFileModal={setShowFileModal}
            loadFolders={loadFolders}
            id={id.documentFolderID}
        />
        <FormFolder
            showFolderModal={showFolderModal}
            setShowFolderModal={setShowFolderModal}
            ids={documentUploadIDSelected}
            loadFolders={loadFolders}
            isNewFolder={isNewFolder}
            setIsNewFolder={setIsNewFolder}
        />

        <VideoPlayer
            showVideoModal={showVideoModal}
            setShowVideoModal={setShowVideoModal}
            url={videoURLSelected}
        />

        <EditFiles
            showFileEditModal={showFileEditModal}
            setShowFileEditModal={setShowFileEditModal}
            fileID={documentUploadFileIDSelected}
            loadDataByFolder={loadDataByFolder}
        />
    </>
    );
}

export default IndexFolder;

