import { FaSpinner } from 'react-icons/fa';
import React from "react";
import {Modal} from './styles';

const ModalLoading = (props) => {
    if(props.open){
      document.body.style = "overflow: hidden!important";
    }else{
      document.body.style = "overflow: auto!important";
    }

    return (
      <Modal className={`modal fade ${props.open == true ? "show" : ""}`} id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" open={props.open}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="spinner-border text-primary" role="status"></div>
              <div className="message">{props.message}</div>
            </div>
          </div>
        </div>
      </Modal>
    );
}

export default ModalLoading;