import React from 'react';
import TableCrud from '../../components/TableCrud';

const ReportYearIndex = () => {
    const columns = [
        {
            label: "Report Year",
            name: "year",
            type: "text"
        },
        {
            label: "Start Date",
            name: "beginDate",
            type: "date"
        },
        {
            label: "End Date",
            name: "endDate",
            type: "date"
        }
    ]

    return (
        <TableCrud title={"Report Year"} table={"reportYear"} columns={columns}/>
    );
}

export default ReportYearIndex;
