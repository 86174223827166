import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import FormInput from '../../components/FormInput';
import CancelButton from '../../components/CancelButton';
import Api, { exceptionNotificationAPI } from '../../services/api';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import { Form } from '@unform/web';
import { Container } from './styles';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';



const KPIForm = () => {
    const history = useHistory();
    const { id } = useParams();

    const formRef = useRef(null);
    const [data, setData] = useState(null);

    const [multipleValues, setMultipleValues] = useState([]);
    const [companies, setCompanies] = useState(null);
    const [selectedSubSection, setSelectedSubSection] = useState([]);


    const [subSectionsOptions, setSubSectionsOptions] = useState([]);
    const [subSectionsAllOptions, setSubSectionsAllOptions] = useState([]);
    const [reportOptions, setReportOptions] = useState([]);
    const [selectedReportYearID, setSelectedReportYearID] = useState();
    const [companysOptions, setCompanysOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const booleanOptions = [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
    ];

    const loadData = async () => {
        if(id != null){
            await Api.get(`kpi/get?id=${id}`).then((result) => {
                if (result && result.data) {
                    var dataObj = {};

                    fields.forEach(f => {
                        if (f.name == "kpiCompany") {
                            var dataMultiple = result.data.response[f.name].map((sc) => {
                                var data = {
                                    value: sc.companyID,
                                    label: sc.company.name
                                };
                                return data;
                            });
                            setMultipleValues(dataMultiple);
                        }
                        else {
                            if(f.name == "reportYearID") {
                                if(selectedReportYearID == null) {
                                    setSelectedReportYearID(result.data.response[f.name]);
                                }
                                dataObj[f.name] = result.data.response[f.name];
                                loadSubSection(result.data.response[f.name]);
                            }
                            else {
                                dataObj[f.name] = result.data.response[f.name];
                            }
                        }
                    });


                    if(formRef != null && formRef?.current != null){
                        formRef.current.setData(dataObj);
                        setSelectedSubSection(dataObj?.subSectionID);
                        setData(dataObj);
                    }
                    return;
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }

    }



    const filterModules = async () => {
        var options = [];
        if (subSectionsAllOptions != null) {
            var filteredSS = subSectionsAllOptions.filter(x => x.reportYearID == selectedReportYearID);
            if(filteredSS.length > 0) {
                options = filteredSS.map(item => ({ value: item.subSectionID, text: `${item.section.description} -> ${item.description}`, reportYearID: item.reportYearID }));
            }
            else {
                options.push({
                    value: "",
                    text: `No items found, please select another reportYear`,
                })
            }

            setSubSectionsOptions(options);
        }
    }

    const fields = [
        {
            label: "Report Year",
            name: "reportYearID",
            type: "select",
            options: reportOptions
        },
        {
            label: "Sub Section",
            name: "subSectionID",
            type: "select",
            options: subSectionsOptions
        },
        {
            label: "KPI Description",
            name: "description",
            type: "textarea"
        },
        {
            label: "Guidance",
            name: "guidance",
            type: "textarea",
            required: false
        },
        {
            label: "Order",
            name: "order",
            type: "number"
        },
        {
            label: "Is Active",
            name: "isActive",
            type: "select",
            options: booleanOptions
        },
        {
            label: "Companies to exclude",
            name: "kpiCompany",
            type: "select",
            required: false,
            options: companysOptions,
            multiple: true
        },
    ]

    const loadReportYear = async () => {
        await Api.get('ReportYear/getAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.reportYearID, text: `${item.year}` }));
                setReportOptions(options);
                return;
            }

        }).catch((error) => {
            exceptionNotificationAPI(error);
        });

    }

    const loadSubSection = async (reportYearID) => {
        if(reportYearID != null){
            await Api.get(`subSection/getAllByReportYear?reportYearID=${reportYearID}`).then((result) => {
                if (result && result.data) {
                    const options = result.data.response.map(item => ({ value: item.subSectionID, text: `${item.section.module.description} -> ${item.section.description} -> ${item.description}`, reportYearID: item.reportYearID }));
                    setSubSectionsOptions(options);
                    return;
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
    }

    const loadCompanies = async () => {
        await Api.get('Company/getAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.companyID, text: `${item.name}` }));
                setCompanysOptions(options);
                return;
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    useEffect(() => {
        loadSubSection(selectedReportYearID);
        var options = [];
        if (subSectionsAllOptions != null) {
            var filteredSS = subSectionsAllOptions.filter(x => x.reportYearID == selectedReportYearID);
            if(filteredSS.length > 0) {
                options = filteredSS;
            }
            else {
                options.push({
                    value: "",
                    text: `No items found, please select another reportYear`
                })
            }

            setSubSectionsOptions(options);
        }
    }, [selectedReportYearID]);



    const handleSubmit = async (data) => {
        try {

            var formName = "KPI";
            var obj = {}
            var postObj = {};
            const submitUrl = `${formName}/${(id != null) ? "edit" : "new"}`;
            postObj[formName + "id"] = id;
            fields.map(f => {
                if (f.editable !== undefined && f.editable === false) {
                    return null;
                } else if(f.required){
                    return obj[f.name] = Yup.string().required(`${f.label} is required`);
                }
            })

            const schema = Yup.object().shape(obj);

            await schema.validate(data, {
                abortEarly: false,
            });

            fields.map(f => {
                return postObj[f.name] = data[f.name]
            })

            var selector = formName + "Company"
            // FIXO, CUIDAR (SECTION COMPANY)
            if (multipleValues.length > 0) {
                postObj[selector] = multipleValues.map((m) => {
                    var data = {
                        companyID: m.value ? m.value : 0
                    }
                    return data;
                });
            }

            await Api.post(submitUrl, postObj);
            history.push(`/${formName}`);

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error);

                formRef.current.setErrors(errors);

                return;
            }

            exceptionNotificationAPI(error);
        }
    }





    useEffect(() => {
        loadCompanies();
        loadReportYear();
        filterModules();
        loadData();
        setIsLoading(false)
    }, []);

    return (
        <Container className="container">
            <div>
                <h3>{(id != null) ? "Edit" : "New"} {"KPI"}</h3>
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <table className="table table-striped">
                        <tbody>
                            {
                                fields.map((f, i) => {
                                    if (f.type === "select") {
                                        return <tr key={i + "Tr"} id={f.name + "Tr"}>
                                            <td width="25%">
                                                <label>
                                                    {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                </label>
                                            </td>
                                            <td width="75%">
                                                {!f.multiple ?
                                                    f.name === "subSectionID" ?
                                                    <FormInput
                                                    name={f.name}
                                                    id={f.name}
                                                    checked={f.checked}
                                                    inputType={f.type}
                                                    placeholder={f.placeholder}
                                                    options={subSectionsOptions}
                                                    value={selectedSubSection}
                                                    disabled={f.disabled ? f.disabled : false}
                                                    onChange={(a) => {
                                                        setSelectedSubSection(a.target.value);
                                                        return;
                                                    }} />
                                                    :
                                                    f.name === "reportYearID" ?
                                                    <FormInput
                                                    name={f.name}
                                                    id={f.name}
                                                    checked={f.checked}
                                                    inputType={f.type}
                                                    placeholder={f.placeholder}
                                                    options={f.options}
                                                    value={selectedReportYearID != null ? selectedReportYearID : f?.options[0] != null ? f?.options[0] : []}
                                                    disabled={f.disabled ? f.disabled : false}
                                                    onChange={(a) => {
                                                        setSelectedReportYearID(a.target.value);
                                                        return;
                                                    }} />
                                                    :
                                                    <FormInput
                                                        name={f.name}
                                                        id={f.name}
                                                        checked={f.checked}
                                                        inputType={f.type}
                                                        placeholder={f.placeholder}
                                                        options={f.options}
                                                        disabled={f.disabled ? f.disabled : false}
                                                        onChange={(a) => {
                                                            if (f.onChanged != null) {
                                                                f.onChanged(formRef.current)
                                                            }
                                                            return;
                                                        }} />
                                                    :
                                                    <Select
                                                        options={f.name == "reportYearCompany" ?
                                                            companies
                                                            : f.options.map((o) => {
                                                                var data = {
                                                                    value: o.value,
                                                                    label: o.text
                                                                }
                                                                return data;
                                                            })}
                                                        value={multipleValues}
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        onChange={(a) => setMultipleValues(a)}
                                                    />}
                                            </td>
                                        </tr>
                                    }
                                    else {
                                        if (id !== undefined && f.editable !== undefined && f.editable === false) {
                                            return <></>
                                        }
                                        else {
                                            return <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                <td width="25%">
                                                    <label>
                                                        {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                    </label>
                                                </td>
                                                <td width="75%">
                                                    <FormInput
                                                        className="form-control"
                                                        name={f.name}
                                                        id={f.name}
                                                        disabled={f.disabled ? f.disabled : false}
                                                        checked={f.checked}
                                                        inputType={f.type} />
                                                </td>
                                            </tr>
                                        }
                                    }
                                })
                            }
                        </tbody>
                    </table>
                    <div className="d-flex flex-row mt-2">
                        <CancelButton />
                        <Button type="submit">Save</Button>
                    </div>
                </Form>
            </div>
        </Container>
    );
}

export default KPIForm;
