import React, { useEffect, useRef, useState }  from 'react';
import Api, { exceptionNotificationAPI } from '../../services/api';
import FormCrud from '../../components/FormCrud';

const OperationForm = () => {
    const [reportOptions, setReportOptions] = useState();

    const activeOptions = [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
    ];

    const fields = [
        {
            label: "Report Year",
            name: "reportYearID",
            type: "select",
            options: reportOptions
        },
        {
            label: "Form Description",
            name: "description",
            type: "input"
        },
        {
            label: "Guidance",
            name: "guidance",
            type: "textarea",
            required: false
        },
        {
            label: "Order",
            name: "order",
            type: "number"
        },
        {
            label: "Is Active",
            name: "isActive",
            type: "select",
            options: activeOptions
        },
    ];

    const loadReportYear = async () => {
        Api.get('ReportYear/getAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.reportYearID, text: `${item.year}` }));
    
                setReportOptions(options);
            }
    
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    useEffect(() => {
        loadReportYear();
    }, []);

    return (
        <FormCrud formName={"operation"} title={"Form"} fields={fields}/>
    );
}

export default OperationForm;
