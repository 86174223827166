import styled from 'styled-components';

export const Chat = styled.div`
    width: 1000px;
    height: 700px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    background-color: #f8f8f8;
    overflow: hidden;

    .top_menu {
        background-color: #234285;
        width: 100%;
        padding: 20px 0 15px;
        box-shadow: 0 1px 30px rgba(0, 0, 0, 0.2);
      }
      
      .top_menu .button-close {
        margin-right: 20px;
        float: right;
      }
      .top_menu .button-close:hover {
        color: lightgray;
        cursor:pointer;
      }
      
      .top_menu .title {
        text-align: center;
        color: white;
        font-size: 20px;
        font-weight: bold;
      }

      .bottom_wrapper {
        position: relative;
        width: 100%;
        background-color: #fff;
        padding: 20px 20px;
        position: absolute;
        bottom: 0;
      }
      .bottom_wrapper .message_input_wrapper {
        display: inline-block;
        height: 100px;
        border-radius: 6px;
        border: 1px solid #bcbdc0;
        width: calc(100% - 160px);
        position: relative;
        padding: 0 20px;
      }
      .bottom_wrapper .message_input_wrapper .message_input {
        border: none;
        height: 100%;
        box-sizing: border-box;
        width: calc(100% - 40px);
        position: absolute;
        outline-width: 0;
        color: gray;
        font-size: 15px;
        resize: none;
      }
      .bottom_wrapper .send_message, .bottom_wrapper .end_discussion {
        width: 140px;
        height: 50px;
        display: inline-block;
        border-radius: 6px;
        background-color: #234285;
        border: 2px solid #234285;
        color: #fff;
        cursor: pointer;
        transition: all 0.2s linear;
        text-align: center;
        float: right;
      }
      .bottom_wrapper .send_message:hover:enabled, .bottom_wrapper .end_discussion:hover {
        color: #234285;
        background-color: #fff;
      }

      .bottom_wrapper .end_discussion{
        margin-top: 5px;
        height: 45px;
      }

      .bottom_wrapper .send_message .text {
        font-size: 18px;
        display: inline-block;
        line-height: 48px;
        font-weight: bold;
      }

      .bottom_wrapper .end_discussion .text {
        font-size: 14px;
        display: inline-block;
        line-height: 40px;
        font-weight: bold;
      }

      .bottom_wrapper .button_wrapper {
        float: right;
        width: 140px;
        height: 100px;
      }
`;

export const Discussion = styled.ul`
    position: relative;
    list-style: none;
    padding: 10px 10px 0 10px;
    margin: 0;
    height: 510px;
    overflow: scroll;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 50%);

    .end_date {
        text-align: center;
        color: #a1a1a1;
        font-size: 12px;
    }

    .end_message {
        text-align: center;
        color: #a1a1a1;
        margin-bottom: 20px;
        margin-right: 20px;
        margin-left: 20px;
        font-weight: 500;
    }
`;