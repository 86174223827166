import styled from 'styled-components';

export const Modal = styled.div`
    display: ${props => (props.open == true ? 'block' : 'none')}; 
    padding-right: 16px;
    background-color: rgb(0, 0, 0, 0.5);

    .modal-content{
        background-color: transparent;
        border: 0;

        .modal-body{
            text-align: center;

            .spinner-border{
                width: 5rem;
                height: 5rem;
                border: 0.5rem solid #00B1EB;
                border-right-color: transparent;
            }

            .message{
                font-weight: bold;
                color: white;
            }
        }
    }
`;

