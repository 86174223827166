import React, { useEffect, useState } from 'react';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { ContainerHeader, Table } from './styles';
import { useHistory } from 'react-router-dom';
import { FiMoreVertical } from 'react-icons/fi';
import { FaCheck, FaTimes } from 'react-icons/fa';
import Dropdown from 'react-bootstrap/Dropdown';
import Swal from 'sweetalert2';
import moment from 'moment';

import Button from '../Button';
import TableLoading from '../TableLoading';

const TableCrud = ({ columns, table, title }) => {
    const history = useHistory();
    const [data, setData] = useState(null);
    const [userObject, setUserObject] = useState(JSON.parse(localStorage.getItem('@QualityApp:user')));

    const loadData = async () => {
        Api.get(`${table}/getAll?reportYearID=${userObject.selectedReportYearID}`).then((result) => {
            if (result && result.data) {
                var data = result.data.response;
                if(table === "user"){
                    data = data.sort((a, b) => {
                        if(a.company == null){
                            a.company = {'name': 'Global'}
                        }
                        if(b.company == null){
                            b.company = {'name': 'Global'}
                        }
                        if(a.company.name > b.company.name)
                            return 1
                        else if (a.company.name < b.company.name)
                            return -1
                        else
                            return 0
                    });
                }

                setData(data);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const getObjects = (currentObject, fragments) => {
        if(fragments.length > 0){
            var object = currentObject[fragments[0]];
            if(object != null){
                fragments.shift();
                return getObjects(object, fragments);
            }
            else {
                if(table === "user"){
                    return "Global";
                }else{
                    return "";
                }
            }
        }
        return currentObject;
    }

    const deleteKPI = (id) => {
        Swal.fire({
            title: 'Are you sure, you want delete the register?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonColor: '#102A63',
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes'
        }).then((result) => {
        if (result.isConfirmed) {
            Api.post(`/kpi/delete?id=${id}`).then((result) => {
                if (result && result.data) {
                    loadData();
                }
            });
        }
        })
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="container">
            <ContainerHeader>
                <h3>{title}</h3>
                <div>
                    {
                        table !== "readConsent" && table !== "FSMIntegrationLog" ?
                            table === "unitMeasure" ?
                                <Button style={{ marginRight: '15px' }} onClick={() => history.push(`/answerTypeKPI/new`)}>Add</Button>
                            :
                                <Button style={{ marginRight: '15px' }} onClick={() => history.push(`/${table}/new`)}>Add</Button>
                        :
                            <></>
                    }
                </div>
            </ContainerHeader>
            {
                data ? <Table>
                    <thead className="sticky-top">
                        <tr>
                            {
                                columns.map((c, i) => {
                                    return <th key={i + "th"}>{c.label}</th>
                                })
                            }
                            <th width="1%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.length > 0 ?
                                data.map((d, i) => {
                                    return <tr key={i + "tr"}>
                                        {
                                            columns.map((c, x)=> {
                                                if(typeof(d[c.name]) === "boolean"){
                                                    if(c.name == "isDeleted"){
                                                        return <td key={x}>
                                                        {
                                                            d[c.name] === true ? 
                                                                <FaTimes color="#ff0000" size={14} />
                                                            : 
                                                                <FaCheck color="#102A63" size={14} /> 
                                                        }
                                                        </td>
                                                    }else{
                                                        return <td key={x}>
                                                        {
                                                            d[c.name] === true ? 
                                                                <FaCheck color="#102A63" size={14} /> 
                                                            : 
                                                                <FaTimes color="#ff0000" size={14} />
                                                        }
                                                        </td>
                                                    }
                                                }
                                                else if(c.type === "date"){
                                                    return <td key={x}>{moment(d[c.name]).format("DD-MMM-yyyy")}</td>
                                                }
                                                else{
                                                    if(c.name.indexOf(".") > -1){
                                                        var fragments = c.name.split('.');
                                                        
                                                        var object = getObjects(d, fragments);                                                        

                                                        if(table === "user"){
                                                            return <td key={x}>{(object != null ? object : "Global")}</td>
                                                        }else{
                                                            return <td key={x}>{(object != null ? object : "")}</td>
                                                        }
                                                    }else{
                                                        return <td key={x}>{d[c.name]}</td>
                                                    }
                                                }
                                            })
                                        }
                                        <td>
                                            {
                                                
                                                    <Dropdown>
                                                        <Dropdown.Toggle>
                                                            <FiMoreVertical size={16} />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {
                                                                table === "unitMeasure"?
                                                                    <Dropdown.Item onClick={() => history.push(`/answerTypeKPI/edit/${d[(table === "user" ? "id" : ( table === "kpi" ? table + "id" : table + "ID"))]}`)}>{"Edit"}</Dropdown.Item>
                                                                :
                                                                    <Dropdown.Item onClick={() => history.push(`/${table}/edit/${d[(table === "user" ? "id" : ( table === "kpi" ? table + "id" : table + "ID"))]}`)}>{table === "readConsent" ? "Detail" : "Edit"}</Dropdown.Item>    
                                                            }
                                                            {(table === "kpi" ? <Dropdown.Item onClick={() => deleteKPI(d["kpiid"])}>Delete</Dropdown.Item> : "") } 
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                
                                            }
                                        </td>
                                    </tr>
                                })
                                :
                                <tr><td className="no-records" colSpan={columns.length + 1}>No records found</td></tr>
                        }
                    </tbody>
                </Table>
                    :
                    <TableLoading />
            }
        </div>
    );
}

export default TableCrud;
