import styled from 'styled-components';

export const Container = styled.div`

.notifications .container .header .header-title {
  color: #FFF;
}

.notifications .container {
  padding: 0 !important;
  z-index: 999;
}

.notifications .card {
  background-color: #FFF;
}

.notifications .card .image {
  margin: 5px;
  display: none;
}

.notifications .icon .count {
  background-color: red;
}

.notifications .container .header .header-option {
  margin: 0 5px;
  color: #FFF;
  text-decoration: underline;
}

.notifications .container .empty-notifications {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  color: #000;
}

.not-read {
  background-color: #ebedf0 !important;
}
`;