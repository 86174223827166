import React, { useState } from 'react';
import TableCrud from '../../components/TableCrud';

import { Spreadsheet, createEmptyMatrix } from 'react-spreadsheet';
import { useEffect } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { IoMdOptions } from 'react-icons/io';
import { Si } from 'react-icons/si';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { useParams } from 'react-router-dom';
import { Container, ContainerHeader } from './styles';
import Button from '../../components/Button';
import { success_message, info_message, error_message } from '../../components/Toast/index';
import TableLoading from '../../components/TableLoading';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2';

import optionsLogo from '../../assets/icons/options.svg';




const AdditionalDataDefaultValuesForm = () => {
    const columns = ["RefNumber", "Value", "Disable Input", "RefNumber Reference" , " "]

    //Crop Year Select Variables
    const [reportYears, setReportYears] = useState([]);
    const [selectedReportYearID, setSelectedReportYearID] = useState(null);

    const [companies, setCompanies] = useState([]);
    const [selectedCompanyID, setSelectedCompanyID] = useState(null);

    const [rowLabels, setRowLabels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dataSheet, setDataSheet] = useState([
        [],
        [],
    ]);

    const removeRow = (id) => {
        Swal.fire({
            title: 'Are you sure, you want delete the register?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonColor: '#102A63',
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.post(`SubSectionKPI/deleteDefaultValue?id=${id}`).then((result) => {
                    if (selectedReportYearID && selectedCompanyID) {
                        loadDataByReportYear(selectedReportYearID, selectedCompanyID)
                    }
                })
            }
        })
    }

    const deleteAll = () => {
        Swal.fire({
            title: 'Are you sure, you want delete all?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonColor: '#102A63',
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.post(`SubSectionKPI/deleteAllDefaultValue?reportYearID=${selectedReportYearID}&companyID=${selectedCompanyID}`).then((result) => {
                    if (result.data.response == true) {
                        if (selectedReportYearID && selectedCompanyID) {
                            success_message("All values was deleted successfully!")
                            setTimeout(() => {
                                loadDataByReportYear(selectedReportYearID, selectedCompanyID)
                            }, 2000)

                        }
                    }
                    else {
                        error_message("Some error was happened. Please try again or contact the administrator.")
                    }
                })
            }
        })
    }

    const InputNumberEdit = ({ cell, onChange }) => {
        var rowID = cell.className.substring(cell.className.indexOf('-') + 1)
        return (
            <div style={{ padding: '0.5rem 1rem', textAlign: "center" }} onClick={() => removeRow(rowID)}>
                <FaTrashAlt />
            </div>
        );
    };

    const InputOptions = ({ cell, onChange }) => {

        return (
            <div style={{ padding: '0.5rem 1rem' }}>
                <img src={optionsLogo} alt="" />
            </div>
        );
    };


    useEffect(() => {
        setIsLoading(true);
        loadReportYears();
        loadCompanies();
    }, [])


    useEffect(() => {
        if (selectedReportYearID && selectedCompanyID) {
            setIsLoading(true);
            loadDataByReportYear(selectedReportYearID, selectedCompanyID)
        }
    }, [selectedReportYearID, selectedCompanyID])

    const loadReportYears = (userAux) => {
        Api.get(`FormValue/getAllCropsYears`).then((result) => {
            if (result && result.data) {
                setReportYears(result.data.response);
                var currentYear = result.data.response.find(x => x.year == new Date().getFullYear());
                setSelectedReportYearID(currentYear?.reportYearID != null ? currentYear?.reportYearID  : result.data.response[0].reportYearID);
                setIsLoading(false)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeReportYearID = (value) => {
        setSelectedReportYearID(value);
    }

    const loadCompanies = () => {
        Api.get(`Company/getAll`).then((result) => {
            if (result && result.data) {
                setCompanies(result.data.response);
                setSelectedCompanyID(result.data.response[0].companyID);
                setIsLoading(false)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeCompanyID = (value) => {
        setSelectedCompanyID(value);
    }

    const loadDataByReportYear = () => {
        if (selectedReportYearID && selectedCompanyID) {
            Api.get(`SubSectionKPI/getAllDefaultValuesByReportYear?reportYearID=${selectedReportYearID}&companyID=${selectedCompanyID}`).then((result) => {
                if (result && result.data) {

                    var resultList = [...result.data.response];
                    var newLine = [
                        { value: "" }, { value: "" }
                    ]
                    resultList.push(newLine);
                    setIsLoading(false)

                    try {
                        var resultDatasheet = [];
                        try {
                            var rowLabel = [];
                            resultDatasheet = resultList.map((row, i) => {
                                var res = [];

                                var id = i + 1;
                                var answer = row.numberValue ? row.numberValue : row.textValue;
                                res[0] = { value: row.refNumber }
                                res[1] = { value: answer }
                                res[2] = { value: row.disableInputText }
                                res[3] = { value: row.refNumberReference }
                                res[4] = { readOnly: false, className: 'remove-' + row.subsectionKPICompanyDefaultValueID, onClick: () => removeRow(row.subsectionKPICompanyDefaultValueID) };
                                res[4].DataViewer = InputOptions;
                                res[4].DataEditor = InputNumberEdit;
                                rowLabel.push(id);
                                return res;
                            })
                            setRowLabels(rowLabel);
                        } catch { }


                        setDataSheet(resultDatasheet);
                    } catch (e) {
                        console.log(e)
                    }

                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }

    }

    const save = () => {

        var objDefaultValues = {
            reportYearID: selectedReportYearID,
            companyID: selectedCompanyID,
            IDs: rowLabels,
            data: dataSheet
        }
        setIsLoading(true);

        Api.post(`SubSectionKpi/NewDefaultValue`, objDefaultValues).then((result) => {
            if (result && result.data) {
                setIsLoading(false);
                if (result.data.response == true) {
                    success_message("The data was saved!");
                    loadDataByReportYear(selectedReportYearID, selectedCompanyID)
                }
                else {
                    info_message("No data to saved!");
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeTable = (e) => {
        try {
            setDataSheet(e);
        } catch { }
    }

    const onBlurTable = () => {
        try {
            var nesw = dataSheet.map((row) => {
                var newRow = row.map((col) => {
                    var newCol = { ...col };
                    return newCol;
                })
                return newRow;
            })

            try {
                nesw.map((row, i) => {
                    row[0] = { value: row[0].value }
                    row[4] = { readOnly: false, className: 'remove-' + row[0].value, onClick: () => removeRow(row[0].value) };
                    row[4].DataViewer = InputOptions;
                    row[4].DataEditor = InputNumberEdit;
                })

            } catch { }
            setDataSheet(nesw);
        } catch (e) {
            console.log(e)
        }
    }



    return (
        <Container className="container">
            <ContainerHeader style={{ margin: '2rem 0' }}>
                <h3>Additional Data - Default Values</h3>
                <div>
                    <Button style={{ marginRight: '15px' }} onClick={() => save()}>Save</Button>
                    <Button style={{ marginRight: '15px' }} onClick={() => deleteAll()}>Delete All</Button>
                </div>
            </ContainerHeader>
            <div className="row mt-2">
                <div className="col-md-6 mt-2">
                    <label className='label'>Select Reporting Year:</label>
                    <select className="col-md-6 mx-4 mt-2" onChange={(x) => onChangeReportYearID(x.target.value)} value={selectedReportYearID ? selectedReportYearID : ""}>
                        {
                            reportYears ?
                            reportYears.map(reportYear => {
                                    return <option key={reportYear.year} value={reportYear.reportYearID}>{reportYear.year}</option>
                                })
                                :
                                <></>
                        }
                    </select>
                </div>
                <div className="col-md-6 mt-2">
                    <label className='label'>Select Company:</label>

                    <select className="col-md-6 mx-4 mt-2" onChange={(x) => onChangeCompanyID(x.target.value)} value={selectedCompanyID ? selectedCompanyID : ""}>
                        {
                            companies ?
                            companies.map(company => {
                                    return <option key={company.companyID} value={company.companyID}>{company.name}</option>
                                })
                                :
                                <></>
                        }
                    </select>

                </div>
            </div>
            {!isLoading ?
                <Spreadsheet data={dataSheet} rowLabels={rowLabels} columnLabels={columns} onBlur={() => onBlurTable()} onChange={(e) => onChangeTable(e)} />
                :
                <TableLoading />
            }
        </Container>
    )
}

export default AdditionalDataDefaultValuesForm;
