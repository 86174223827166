const GetTypeInput = (unitId, unitType) => {
    var unitMeasure = {
        inputType: "",
        decimalSeparator: "",
        suffix: "",
        decimalPrecision: 0
    }

    if (unitId.toUpperCase() == "A115BFBB-3E3C-4DB2-991D-DF6E84F7549B")
        unitMeasure.inputType = "input";
    if (unitId == 1 || unitId == 9 || unitId == 10 || unitId == 11
        || unitId == 12 || unitId == 13 || unitId == 16 || unitId == 19 || unitId == 20
        || unitId == 21 || unitId == 23 || unitId == 29 || unitId == 30 || unitId == 33
        || unitId == 41 || unitId == 42 || unitId == 44 || unitId == 45 || unitId == 47
        || unitId == 49 || unitId == 52 || unitId.toUpperCase() == "21490B6D-693E-48EE-BC17-08DBE47664CE" || unitId.toUpperCase() == "D6A852D3-C8AE-47BB-BC1A-08DBE47664CE" || unitId.toUpperCase() == "3B068905-EE1d-4A59-BC19-08DBE47664CE" || unitId.toUpperCase() == "B9D941EC-E24D-4A50-BC15-08DBE47664CE"
        || unitId.toUpperCase() == "F0B64AE8-3951-49E3-BC18-08DBE47664CE" || unitId.toUpperCase() == "8E8B584D-9E69-4EFC-BC16-08DBE47664CE" || unitId.toUpperCase() == "9B860211-73AC-4862-BC14-08DBE47664CE")
        unitMeasure.inputType = "number";
    else if (unitId.toUpperCase() == "3FA61576-140F-420C-AD3E-50F0C7E8E0CB" || unitId.toUpperCase() == "D6F7DAE7-2796-492B-9E95-F64E8FFD8A7C" || unitId.toUpperCase() == "0A7F49CD-9D07-41F8-B025-F948AE7A1662" || unitId.toUpperCase() == "40BE1D12-B5F2-45B0-8FC5-B1EE0A426D78")
        unitMeasure.inputType = "decimal";
    else if (unitId == 74)
        unitMeasure.inputType = 'targetBAT'
    else if (unitId == "29a066bf-a19c-4a78-b601-7ff8b5025d92")
        unitMeasure.inputType = "selectyn";
    else if (unitId.toUpperCase() == '9C6189F5-21D4-4EFD-BD89-05B74D6F691A' || unitId.toUpperCase() == "29A066BF-A19C-4A78-B601-7FF8B5025D92")
        unitMeasure.inputType = "selectynu";
    else if (unitId === "bfb810a1-758c-4623-be44-82ca1d94f583")
        unitMeasure.inputType = "selectcustom";
    else if (unitId == 78)
        unitMeasure.inputType = "multiselectcustom";
    else if (unitId == 25)
        unitMeasure.inputType = "selectcharge";
    else if (unitId.toUpperCase() == "2BA476E0-64E7-4D4A-8477-C20458F34437")
        unitMeasure.inputType = "selectcountry";
    else if (unitId.toUpperCase() == "B340236B-6947-4E23-A289-7E98525192EB")
        unitMeasure.inputType = "selecttobacco";
    else if (unitId == 68)
        unitMeasure.inputType = "selectfuel";
    else if (unitId == 28)
        unitMeasure.inputType = "selecthousing";
    else if (unitId.toUpperCase() == "E9874DB9-77B7-48D0-8281-6B5D343E787F")
        unitMeasure.inputType = "selectasnn";
    else if (unitId.toUpperCase() == "C2FC2905-3486-4BCF-8A4E-D32A4D13B0D3")
        unitMeasure.inputType = "selectasn";
    else if (unitId.toUpperCase() == "5BEE2564-DBDB-4B4B-8E10-E3A93FCC0331")
        unitMeasure.inputType = "date";
    else if (unitId.toUpperCase() == "8D5D6F17-1CD3-4B92-8B8D-9C3BE3F89893")
        unitMeasure.inputType = "time";
    else
        unitMeasure.inputType = "";

    if (unitType == "Natural Number") {
        unitMeasure.decimalSeparator = "";
        unitMeasure.suffix = "";
        unitMeasure.decimalPrecision = 0;
    } else if (unitType == "%, no decimal") {
        unitMeasure.decimalSeparator = "";
        unitMeasure.suffix = "%";
        unitMeasure.decimalPrecision = 0;
    } else if (unitType == "%, one decimal place") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "%";
        unitMeasure.decimalPrecision = 1;
    } else if (unitType == "%, two decimal cases") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "%";
        unitMeasure.decimalPrecision = 2;
    } else if (unitType == "%, four decimal cases") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "%";
        unitMeasure.decimalPrecision = 4;
    } else if (unitType == "No decimal") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "";
        unitMeasure.decimalPrecision = 0;
    } else if (unitType == "Two decimal places") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "";
        unitMeasure.decimalPrecision = 2;
    } else if (unitType == "Three decimal places") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "";
        unitMeasure.decimalPrecision = 3;
    } else if (unitType == "Four decimal places") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "";
        unitMeasure.decimalPrecision = 4;
    } else if (unitType == "Six decimal places") {
        unitMeasure.decimalSeparator = ".";
        unitMeasure.suffix = "";
        unitMeasure.decimalPrecision = 6;
    } else {
        unitMeasure.decimalSeparator = "";
        unitMeasure.suffix = "";
        unitMeasure.decimalPrecision = 0;
    }

    return unitMeasure;
}

export default GetTypeInput;
