import React from 'react';
import TableCrud from '../../components/TableCrud';

const SubSectionIndex = () => {
    const columns = [
        {
            label: "Form",
            name: "section.module.operation.description"
        },
        {
            label: "Module",
            name: "section.module.description"
        },
        {
            label: "Section",
            name: "section.description"
        },
        {
            label: "Sub Section Description",
            name: "description"
        },
        {
            label: "Guidance",
            name: "guidance"
        },
        {
            label: "Report Year",
            name: "reportYear.year"
        },
        {
            label: "Order",
            name: "order"
        }
    ]

    return (
        <TableCrud title={"Sub Section"} table={"subSection"} columns={columns}/>
    );
}

export default SubSectionIndex;
