import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { FiUpload } from "react-icons/fi";
import Api, { exceptionNotificationAPI } from "../../services/api";
import Button from "../Button";
import { error_message, info_message, success_message } from "../Toast";
// import ModalBody from 'react-bootstrap/ModalBody';
import { Header, ContentTitle, CommentTextArea, ModalBody } from './styles';

export default function ModalUploadFile({ showModalUpload, setShowModalUpload, companyID, reportYearID, moduleID, versionID }) {
    const [isUploading, setIsUploading] = useState(false)
    const [showButtons, setShowButtons] = useState(true)
    const [showImportButton, setShowImportButton] = useState(false)
    const [showModalCompareDataFSM, setShowModalCompareDataFSM] = useState(false)
    const [kPIsToCompare, setKPIsToCompare] = useState(null)
    const [lastFSMImportation, setLastFSMImportation] = useState("")
    const [userObject, setUserObject] = useState(JSON.parse(localStorage.getItem('@QualityApp:user')));

    
    useEffect(() => {        
        if(showModalUpload == true && userObject != null) {
            try {
                Api.get(`FSMIntegrationData/GetLastFSMImportationDate?reportYearID=${reportYearID}&companyID=${companyID}&moduleID=${moduleID}&FormValueVersionID=${versionID ?? ""}`).then((result) => {
                    if (result && result.data) {
                        // console.log(result.data.response)
                        // var lastImportationDate = result.data.response;
                        // var today = new moment();
                        // var todayF = new moment().format("DD/MM/YYYY");
                        // var yesterday = moment(today).subtract(1, 'days');
                        
                        // var yesterdayF = moment(yesterday).format("DD/MM/YYYY");
                        
                        // if (todayF == lastImportationDate || yesterdayF == lastImportationDate){
                            // }
                            
                        setShowImportButton(true)
                        setLastFSMImportation(result.data.response)

                    }
                    
                }).catch((error) => {
                    exceptionNotificationAPI(error);
                });
            }
            catch (error) {
                exceptionNotificationAPI(error);
            }        
        }        
    }, [showModalUpload]);
    
    async function uploadFile(files, companyID, reportYearID, moduleID, versionID) {
        try {
            
            if (files.target.files[0].size / 1024 > 30720) {
                error_message("File is too big (max 30mb)!");
                return;
            };
            info_message("The upload was sent!");
            const data = new FormData();
            data.append("files", files.target.files[0]);
            setIsUploading(true);
            setShowButtons(false);
            await Api.post(`FSMIntegrationData/readBySystem`, data)
                .then(response => {                    
                    success_message("The upload was successfully!");
                    setIsUploading(false);
                    setShowButtons(true);
                    CompareFSMData(companyID, reportYearID, moduleID, versionID)
                })
        }
        catch (error) {
            exceptionNotificationAPI(error);
        }
    }

    async function CompareFSMData (companyID, reportYearID, moduleID, versionID) {
        try {
            Api.get(`FSMIntegrationData/CompareValuesFromFSM?reportYearID=${reportYearID}&companyID=${companyID}&moduleID=${moduleID}&FormValueVersionID=${versionID ?? ""}`).then((result) => {
                if (result && result.data) {
                    console.log(result.data.response)
                    setKPIsToCompare(result.data.response)
                    setShowModalUpload(false)
                    setShowModalCompareDataFSM(true)
                }
                
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
        catch (error) {
            exceptionNotificationAPI(error);
        }
    }
    
    return (<>
        <Modal
            show={showModalUpload}
            onHide={() => {
                setShowModalUpload(false);
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader closeButton>
                <ModalTitle id="contained-modal-title-vcenter">
                    <Header>Upload FSM Data</Header>
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div className="upload-btn-wrapper">
                    {/* <button className="btn-custom"> 
                        <FiUpload
                            color={"#0E2B63"}                                        
                            className="icon-comment"
                            style={{padding: '0 0.5rem'}}
                            size={70}                            
                        />
                        <div>
                            Click to select file you wish to upload.<br/>
                            <strong>File extensions must be .XLSX or .XLS</strong>  
                        </div>
                    </button>
                    <input type="file" onChange={(files) => uploadFile(files, companyID, reportYearID, moduleID, versionID)} /> */}
                    {isUploading ? <div style={{textAlign: "center", margin: "4rem 0"}}> Informations will be updated and replace any input already on the page once you hit “Ok”.<br />Loading may take a few moments.</div> : <div style={{textAlign: "center", margin: "4rem 0"}}> Last FSM Importation Date: {lastFSMImportation}</div>}                   
                     
                    {showButtons && !isUploading? 
                    <div style={{textAlign: "center", margin: "5rem 0 2rem"}}>
                        {
                            showImportButton == true ?
                            <Button onClick={() => CompareFSMData(companyID, reportYearID, moduleID, versionID)}>Imported Data</Button>
                            : 
                            <>
                                <div style={{fontSize: '16px', textDecoration: 'underline', textAlign: 'center'}}>
                                    There is no data to be imported!<br/>Please try again later.
                                </div>
                            </>
                        }
                    </div> 
                    : <></>}   
                    
                                    
                </div>
            </ModalBody>
            
        </Modal>
        </>
    );
}