import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import Button from 'react-bootstrap/Button';
import { ModalHeaderStyled, ModalTitleStyled, ModalFooterStyled, Text } from './style';

const SessionTimeoutDialog = ({ show, countdown, onContinue }) => {
    var minutes, seconds, totalTime;

    totalTime = countdown / 60;
    minutes = parseInt(totalTime);
    seconds = parseInt((totalTime - minutes) * 60);

    return (
        <Modal
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onContinue}
        >
            <ModalHeaderStyled closeButton>
                <ModalTitleStyled>
                    Session Timeout
                </ModalTitleStyled>
            </ModalHeaderStyled>
            <ModalBody>
                <Text>
                    The session will be ended in{" "} <span>{`${minutes}:${seconds >= 10 ? seconds : `0${seconds}` }`}</span> minutes due to inactivity.
                </Text>
            </ModalBody>
            <ModalFooterStyled>
                <Button style={{backgroundColor: '#102A63', borderColor: '#102A63', fontWeight: 'bold'}} onClick={onContinue}>
                    Continue Session
                </Button>
            </ModalFooterStyled>
        </Modal>
    );
}

export default SessionTimeoutDialog;