import React from 'react';
import TableCrud from '../../components/TableCrud';

const KPIIndex = () => {
    const columns = [
        {
            label: "SubSection Description",
            name: "subSection.description"
        },
        {
            label: "KPI Description",
            name: "description"
        },
        {
            label: "Report Year",
            name: "reportYear.year"
        },        
    ]

    return (
        <TableCrud title={"KPI"} table={"kpi"} columns={columns}/>
    );
}

export default KPIIndex;
