import styled from 'styled-components';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalFooter from 'react-bootstrap/ModalFooter';

export const Text = styled.div`
    text-align: left;
    padding: 1rem; 
    font-size: 14px;
    font-style: italic;

    span{
        color: red;
        font-weight: bold;
        font-size: 20px;
    }
`

export const ModalHeaderStyled = styled(ModalHeader)`
    .btn-close{
        padding-right: 2rem;
    }
`

export const ModalTitleStyled = styled(ModalTitle)`
    text-align: left;
    font-size: 13px;
    letter-spacing: 0px;
    color: #000000;
    text-shadow: 0px 3px 20px #0000000D;
    opacity: 1;
    padding: 1rem;
`

export const ModalFooterStyled = styled(ModalFooter)`
    border-top: 0;

    button{
        &:hover{
            opacity: 0.8;
        }
    }
`