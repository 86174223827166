import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  padding-top: 7px;    
  transform: translate(-100%, 0%);
  animation: fadeIn;
  animation-duration: 1s;

  button{
    background-color: transparent;
    border: 0;
    cursor: default;
    padding-right: 3px;

    svg{
      cursor: default;
      background-color: #FFFFFF;
    }
  }
`;