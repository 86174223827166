import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { FiUpload } from "react-icons/fi";
import { Header, ContentTitle, CommentTextArea, ModalBody } from './styles';
import { error_message, info_message, success_message } from '../../components/Toast';

const FormFiles = ({ showFileModal, setShowFileModal, loadFolders, id }) => {
    const [foldersOptions, setFoldersOptions] = useState([]);
    const [companysOptions, setCompanysOptions] = useState([]);
    const [isUploading, setIsUploading] = useState(false)
    const [showButtons, setShowButtons] = useState(true)
    const [showImportButton, setShowImportButton] = useState(false)
    const [selectedFolder, setSelectedFolder] = useState(null)
    const [filename, setFilename] = useState(null)
    const [contentType, setContentType] = useState(null)
    const [extension, setExtension] = useState(null)
    const [file, setFile] = useState(null);
    const [folderName, setFolderName] = useState("")
    const [userObject, setUserObject] = useState(JSON.parse(localStorage.getItem('@QualityApp:userRoles')));


    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [id]);


    useEffect(() => {
        setShowButtons(true);
        setShowImportButton(true);
    }, [file]);

    async function uploadFile(files) {
        try {
            setIsUploading(true);

            if (files.target.files[0].size / 1024 > 64000) {
                error_message("File is too big (max 60mb)!");
                return;
            };
            info_message("The upload was sent!");
            const data = new FormData();
            data.append("files", files.target.files[0]);
            data.append("documentUploadFolderID", selectedFolder);
            data.append("extension", files.target.files[0].name.split(".").pop());
            data.append("URL", window.location.href);

            await Api.post(`DocumentUploadFiles/InsertFile`, data)
                .then(response => {
                    success_message("The upload was successfully!");
                    setIsUploading(false)
                    setShowFileModal(false)
                    loadFolders();
                })

        }
        catch (error) {
            exceptionNotificationAPI(error);
        }
    }

    const loadData = async () => {
        if (id != null) {
            await Api.get(`DocumentUploadFolder/getParentFolders?folderID=${id}`).then(async (result) => {
                if (result && result.data) {
                    var options = result.data.response;
                    // options = [{documentUploadFolderID: id, folderName: "Actual folder"}, ...options];

                    setSelectedFolder(id)
                    setFoldersOptions(options);

                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }
        else {
            await Api.get(`DocumentUploadFolder/getAll`).then(async (result) => {
                if (result && result.data) {
                    if (result.data.response.length > 0) {
                        var options = result.data.response;

                        setSelectedFolder(options[0].documentUploadFolderID)
                        setFoldersOptions(options);
                    }

                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }

    }

    return (<>
        <Modal
            show={showFileModal}
            onHide={() => {
                setShowFileModal(false);
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader closeButton>
                <ModalTitle id="contained-modal-title-vcenter">
                    <Header>Upload File</Header>
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <p>Select Folder: </p>
                            <select className='mb-4 px-4 py-2'
                                onChange={(x) => setSelectedFolder(x.target.value)} value={selectedFolder}>
                                <option key={JSON.stringify(id) === '{}' ? null : id} value={JSON.stringify(id) === '{}' ? "" : id}>Actual Folder</option>
                                {foldersOptions.length > 0 && foldersOptions.map(folder => {
                                    if (folder.parentFolders == null) {
                                        return <option key={folder.documentUploadFolderID} value={folder.documentUploadFolderID}>{folder.folderName}</option>
                                    } else {
                                        return (<>
                                            <optgroup label={folder.folderName}>
                                                <option key={folder.documentUploadFolderID} value={folder.documentUploadFolderID}>{folder.folderName}</option>
                                                {folder.parentFolders.length > 0 && folder.parentFolders.map((pfolder) => {
                                                    return (
                                                        <option key={pfolder.documentUploadFolderID} value={pfolder.documentUploadFolderID}>{pfolder.folderName}</option>
                                                    )

                                                })}
                                            </optgroup>
                                        </>
                                        )

                                    }

                                })}
                            </select>
                            {/* <select className='mb-4 px-4 py-2' onChange={(x) => setSelectedFolder(x.target.value)} value={selectedFolder}>
                                {
                                    foldersOptions ?
                                        foldersOptions.map(f => {
                                            return <option key={f.value} value={f.value}>{f.text}</option>
                                        })
                                        :
                                        <></>
                                }
                            </select> */}
                        </div>
                    </div>
                </div>
                <div className="upload-btn-wrapper">
                    <button className="btn-custom">
                        <FiUpload
                            color={"#0E2B63"}
                            className="icon-comment"
                            style={{ padding: '0 0.5rem' }}
                            size={70}
                        />
                        <div>
                            Click to select file you wish to upload.<br />
                            <strong>File extensions must be .XLSX, .XLS, .DOCX, PDF, or .MP4</strong>
                        </div>
                    </button>
                    <input type="file" onChange={(files) => uploadFile(files)} />
                </div>
                {isUploading &&
                    <>
                        <div style={{ textAlign: "center", margin: "4rem 0" }}> Uploading file, please wait.</div>
                    </>}
            </ModalBody >
        </Modal >

    </>
    );
}

export default FormFiles;