import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import Api, { exceptionNotificationAPI } from '../../services/api';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { Container } from './styles';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import getValidationErrors from '../../utils/getValidationErrors';
import Button from '../../components/Button';
import FormInput from '../../components/FormInput';
import CancelButton from '../../components/CancelButton';
import Paragraph from '../../components/Paragraph';
import RichTextEditor from '../../components/Paragraph';

const ModuleForm = () => {
    const formRef = useRef(null);
    const { id } = useParams();
    const [operationsOptions, setOperationsOptions] = useState(null);
    const [operations, setOperations] = useState(null);
    const [reportOptions, setReportOptions] = useState();
    const [selectedReportYearID, setSelectedReportYearID] = useState();
    const activeOptions = [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
    ];

    const activeOptionsButtonZero = [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
    ];

    const deadLineOptions = [
        { value: 1, text: "Other" },
        { value: 2, text: "Crop Calendar" },
    ];
    
    const history = useHistory();
    const [data, setData] = useState(null);

    const [multipleValues, setMultipleValues] = useState([]);
    const [selectCustomValue, setSelectCustomValue] = useState([]);
    const [selectCustomInput, setSelectCustomInput] = useState("");
    const [disabledCustomSelect, setDisabledCustomSelect] = useState(true);
    const [companies, setCompanies] = useState(null);
    const [guidanceObj, setGuidanceObj] = useState(null);
    const [esgCompanies, setEsgCompanies] = useState([]);
    const [richTextValue, setRichTextValue] = useState("");


    const animatedComponents = makeAnimated();

    const filterOperations = async () => {
        if (operations != null) {
            var filteredModules = operations.filter(x => x.reportYearID == selectedReportYearID);
            const options = filteredModules.map(item => ({ value: item.operationID, text: item.description, reportYearID: item.reportYearID }));
            setOperationsOptions(options);
        }
    }

    const selectReportYear = async (e) => {
        setSelectedReportYearID(e.getFieldRef("reportYearID").current.value);
    }

    const fields = [
        {
            label: "Report Year",
            name: "reportYearID",
            type: "select",
            options: reportOptions,
            onChanged: selectReportYear
        },
        {
            label: "Form",
            name: "operationID",
            type: "select",
            options: operations?.filter(x => x.reportYearID == selectedReportYearID)
        },
        {
            label: "Module Description",
            name: "description",
            type: "input",
            disabled: false
        },
        {
            label: "Guidance",
            name: "guidance",
            type: "textareaRichText",
            required: false
        },
        {
            label: "Order",
            name: "order",
            type: "number"
        },
        {
            label: "Deadline Type",
            name: "deadlineType",
            type: "select",
            options: deadLineOptions
        },
        {
            label: "Is Active",
            name: "isActive",
            type: "select",
            options: activeOptions
        },
        {
            label: "Add Zero Button",
            name: "IsHandleAddZeros",
            type: "select",
            options: activeOptionsButtonZero
        },
        
    ];

    useEffect(() => {
        loadCompanies();
    }, []);

    const handleRichTextChange = (value) => {
        setRichTextValue(value);
      };

    useEffect(() => {
        if (data === null && id != null) {

            loadData();
        }
    }, []);

    useEffect(() => {
        console.log("value", selectCustomValue)
        console.log("input", selectCustomInput)
    }, [selectCustomValue, selectCustomInput]);

    const loadCompanies = async () => {
        await Api.get('Company/getAll').then((result) => {
            if (result && result.data) {
                var companyOptions = result.data.response.map(item => ({ value: item.companyID, label: item.name }));

                companyOptions = [{ value: "", label: "Global" }, ...companyOptions];

                var esgCompaniesOptions = result.data.response.filter(c => c.isESGYear).map(item => ({ value: item.companyID, label: item.name }));

                setCompanies(companyOptions);
                setEsgCompanies(esgCompaniesOptions);

                console.log(result.data.response);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }


    const getObjects = (currentObject, fragments) => {
        if (fragments.length > 0) {
            var object = currentObject[fragments[0]];

            if (object != null) {
                fragments.shift();
                return getObjects(object, fragments);
            }
            else {
                return "";
            }
        }

        return currentObject;
    }


    const loadOperations = async () => {
        Api.get('Operation/getAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.operationID, text: item.description, reportYearID: item.reportYearID }));
                setOperations(options)
                setOperationsOptions(options);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadReportYear = async () => {
        Api.get('ReportYear/getAll').then((result) => {
            if (result && result.data) {
                const options = result.data.response.map(item => ({ value: item.reportYearID, text: `${item.year}` }));

                if (!id) setSelectedReportYearID(options[0].value)
                setReportOptions(options);
            }

        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    useEffect(() => {
        if (operations != null && selectedReportYearID) {
            var filteredModules = operations.filter(x => x.reportYearID == selectedReportYearID);
            const options = filteredModules;
            setOperationsOptions(options);
        }
    }, [selectedReportYearID, operations]);

    const loadData = async () => {
        await Api.get(`module/get?id=${id}`).then((result) => {
            if (result && result.data) {
                var dataObj = {};
                var companyOptions = [];
                var groupOptions = [];

                fields.forEach(f => {
                    if (f.name == "customOptions") {
                        if (result.data.response["customOptions"] != null) {
                            var exist = result.data.response["customOptions"].indexOf(";") > -1;
                            if (exist) {
                                var dataMultiple = result.data.response["customOptions"].split(";");
                                var newDataMulti = dataMultiple.map((e) => {
                                    var newData = {
                                        "value": e,
                                        "label": e
                                    }
                                    return newData;
                                });


                                setSelectCustomValue(newDataMulti);
                            } else {
                                setSelectCustomValue([]);
                            }
                        } else {
                            setSelectCustomValue([]);
                        }
                    } else if (f.name === "guidance") {
                        dataObj[f.name] = result.data.response[f.name];
                        setRichTextValue(result.data.response["guidance"])
                    } else {
                        if (f.name.indexOf(".") > -1) {
                            var fragments = f.name.split('.');

                            var object = getObjects(result.data.response, fragments);

                            dataObj[f.name] = (object != null ? object : "");
                        }
                        else if (f.name == "reportYearID") {
                            if (setSelectedReportYearID != null) {
                                setSelectedReportYearID(result.data.response[f.name]);
                            }
                            dataObj[f.name] = result.data.response[f.name];
                        }
                        else {
                            dataObj[f.name] = result.data.response[f.name];


                        }
                    }
                });

                formRef.current.setData(dataObj);
                setData(dataObj);

            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const handleSubmit = async (data) => {
        try {
            var obj = {}
            var postObj = {};
            const submitUrl = `module/${(id != null) ? "edit" : "new"}`;

            postObj["moduleID"] = id;


            fields.map(f => {                
                if (f.required !== undefined && f.required === false) {
                    return null;
                } else {
                    return obj[f.name] = Yup.string().required(`${f.label} is required`);
                }                
            })

            console.log(obj);
            console.log("data", data);

            const schema = Yup.object().shape(obj);

            await schema.validate(data, {
                abortEarly: false,
            });

            fields.map(f => {
                return postObj[f.name] = data[f.name]
            })

            // FIXO, CUIDAR (CUSTOM OPTIONS)
            if (selectCustomValue.length > 0) {
                postObj.customOptions = selectCustomValue.map((e) => { return e.value }).join(';');
            }


            // FIXO, CUIDAR (SECTION COMPANY)
            if (multipleValues.length > 0) {
                postObj["moduleCompany"] = multipleValues.map((m) => {
                    var data = {
                        companyID: m.value ? m.value : 0
                    }
                    return data;
                });
            }

            if (richTextValue != null) {
                postObj.guidance = richTextValue;
            }


            await Api.post(submitUrl, postObj);
            history.push(`/module`);

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error);

                formRef.current.setErrors(errors);

                return;
            }

            exceptionNotificationAPI(error);
        }
    }


    useEffect(() => {
        loadReportYear();
        loadOperations();
        loadData();
        filterOperations();
    }, []);


    return (
        <Container className="container">
            <div>
                <h3>{(id != null) ? "Edit" : "New"} {"Module"}</h3>
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <table className="table table-striped">
                        <tbody>
                            {
                                fields.map((f, i) => {
                                    if (f.type === "selectcustom") {
                                        return <>
                                            <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                <td>
                                                    <label>
                                                        {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                    </label>
                                                </td>
                                                <td>
                                                    <CreatableSelect
                                                        name="customOptions"
                                                        id={`customOptions_${f.name}`}
                                                        // isDisabled={disabledCustomSelect}
                                                        components={{ DropdownIndicator: null }}
                                                        isClearable={true}
                                                        isMulti
                                                        menuIsOpen={false}
                                                        placeholder="Type something and press enter..."
                                                        onChange={(e) => { setSelectCustomValue(e); }}
                                                        onInputChange={(e) => setSelectCustomInput(e)}
                                                        onKeyDown={(e) => {
                                                            switch (e.key) {
                                                                case 'Enter':
                                                                case 'Tab':
                                                                    if (selectCustomInput == "")
                                                                        return;
                                                                    var newData = {
                                                                        "value": selectCustomInput,
                                                                        "label": selectCustomInput
                                                                    }
                                                                    var newArray = [...selectCustomValue, newData];
                                                                    setSelectCustomInput("");
                                                                    setSelectCustomValue(newArray);
                                                                    e.preventDefault();
                                                            }
                                                        }}
                                                        value={selectCustomValue}
                                                        inputValue={selectCustomInput}
                                                    />

                                                </td>
                                            </tr>
                                        </>
                                    }
                                    else if (f.type === "textareaRichText") {
                                        return <>
                                            <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                <td>
                                                    <label>
                                                        {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                    </label>
                                                </td>
                                                <td style={{ display: "flex" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <RichTextEditor
                                                            value={richTextValue}
                                                            onChange={handleRichTextChange}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    else if (f.type === "select") {
                                        return <tr key={i + "Tr"} id={f.name + "Tr"}>
                                            <td width="25%">
                                                <label>
                                                    {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                </label>
                                            </td>
                                            <td width="75%">
                                                {!f.multiple ?
                                                    <FormInput
                                                        name={f.name}
                                                        id={f.name}
                                                        checked={f.checked}
                                                        inputType={f.type}
                                                        placeholder={f.placeholder}
                                                        options={f.options}
                                                        disabled={f.disabled ? f.disabled : false}
                                                        onChange={(a) => {
                                                            if (f.name == "unitMeasureID" && a.target.value != 24) {
                                                                setDisabledCustomSelect(true);
                                                                setSelectCustomValue([]);
                                                            } else if (f.name == "unitMeasureID" && a.target.value == 24) {
                                                                setDisabledCustomSelect(false);
                                                                setSelectCustomValue([]);
                                                            } else {
                                                                setDisabledCustomSelect(true);
                                                            }

                                                            if (f.onChanged != null) {
                                                                f.onChanged(formRef.current)
                                                            }
                                                            return;
                                                        }} />
                                                    :
                                                    <Select
                                                        options={f.name == "reportYearCompany" || f.name == "yearCompany" ?
                                                            companies
                                                            : f.options.map((o) => {
                                                                var data = {
                                                                    value: o.value,
                                                                    label: o.text
                                                                }
                                                                return data;
                                                            })}
                                                        value={multipleValues}
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        onChange={(a) => setMultipleValues(a)}
                                                    />}
                                            </td>
                                        </tr>

                                    }
                                    else {
                                        if (id !== undefined && f.editable !== undefined && f.editable === false) {
                                            return <></>
                                        }
                                        else {
                                            return <tr key={i + "Tr"} id={f.name + "Tr"}>
                                                <td width="25%">
                                                    <label>
                                                        {`${f.label}`}  {f.required === undefined || f.required === true ? <span className="required">*</span> : <></>}
                                                    </label>
                                                </td>
                                                <td width="75%">
                                                    <FormInput
                                                        className="form-control"
                                                        name={f.name}
                                                        id={f.name}
                                                        disabled={f.disabled ? f.disabled : false}
                                                        checked={f.checked}
                                                        inputType={f.type} />
                                                </td>
                                            </tr>
                                        }
                                    }
                                })
                            }
                        </tbody>
                    </table>
                    <div className="d-flex flex-row mt-2">
                        <CancelButton />
                        <Button type="submit">Save</Button>
                    </div>
                </Form>
            </div>
        </Container>
    );
}

export default ModuleForm;
