import React, { useEffect, useState } from 'react';

import Api, { exceptionNotificationAPI } from '../../services/api';
import FormCrud from '../../components/FormCrud';

const GroupForm = () => {
    const booleanOptions = [
        { value: true, text: "Yes" },
        { value: false, text: "No" }
    ];
    
    const fields = [
        {
            label: "Name",
            name: "name",
            type: "input"
        },
        {
            label: "Companies",
            name: "customOptions",
            type: "selectcustom",
            required: false,
        },
        {
            label: "Is PowerBI Group?",
            name: "isPowerBI",
            type: "select",
            options: booleanOptions
        },
        {
            label: "Is ReadOnly View?",
            name: "isReadOnlyView",
            type: "select",
            options: booleanOptions
        },
        {
            label: "Is Active",
            name: "isActive",
            type: "select",
            options: booleanOptions
        },
        {
            label: "Enable Simulation",
            name: "enableSimulation",
            type: "select",
            options: booleanOptions
        }
    ]

    return (
       <FormCrud formName={"group"} title={"Group"} fields={fields} />
    );
}

export default GroupForm;
