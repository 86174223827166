import React from "react";
import { FiAlertCircle } from 'react-icons/fi';
import { Container } from './styles'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

const TooltipAlertComponent = ({ message, placement, className, id, ...rest }) => {
  return (
    <Container className={className} id={id} {...rest}>
      <OverlayTrigger
        delay={{ hide: 100, show: 100 }}
        overlay={(props) => (
          <Tooltip {...props}>
            {message}
          </Tooltip>
        )}
        placement={placement}>
        <button onClick={(e) => e.preventDefault()} className="text-center d-flex align-items-center justify-content-center"><FiAlertCircle color="#e71700" size={17} /></button>
      </OverlayTrigger>
    </Container>
  );
}

export default TooltipAlertComponent;