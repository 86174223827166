import React, { useState } from 'react';
import TableCrud from '../../components/TableCrud';

import { Spreadsheet, createEmptyMatrix } from 'react-spreadsheet';
import { useEffect } from 'react';
import { FaNetworkWired } from 'react-icons/fa';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { useParams } from 'react-router-dom';
import { Container, ContainerHeader } from './styles';
import Button from '../../components/Button';
import { success_message, info_message, error_message } from '../../components/Toast/index';
import TableLoading from '../../components/TableLoading';
import NumberFormat from 'react-number-format';
import { useAuth } from '../../hooks/auth';
import Swal from 'sweetalert2';
import { FaTrashAlt } from 'react-icons/fa';
import optionsLogo from '../../assets/icons/options.svg';


const AdditionalDataDeadlineFormIndex = () => {
    const columns = ["Supplier", "Start Date", "End Date", " "]

    //Crop Year Select Variables
    const { userRoles } = useAuth();
    const [reportYears, setReportYears] = useState([]);
    const [modules, setModules] = useState([]);
    const [version, setVersion] = useState(null);
    const [isEditable, setIsEditable] = useState(null);
    const [iDsList, setIDsList] = useState([]);
    const [selectedReportYearID, setSelectedReportYearID] = useState(null);
    const [selectedModuleID, setSelectedModuleID] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [rowLabels, setRowLabels] = useState([]);
    const [dataSheet, setDataSheet] = useState([
        [],
        [],
    ]);


    const removeRow = (id) => {
        Swal.fire({
            title: 'Are you sure you want delete the register?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonColor: '#102A63',
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.post(`AdditionalDataDeadline/delete?id=${id}`).then((result) => {
                    if (selectedReportYearID && selectedModuleID) {
                        loadDataByReportYear(selectedReportYearID, selectedModuleID)
                    }
                })
            }
        })
     }

     const deleteAll = () => {
        Swal.fire({
            title: 'Are you sure, you want delete all?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonColor: '#102A63',
            cancelButtonColor: '#B0B0BB',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                Api.post(`AdditionalDataDeadline/deleteAll?reportYearID=${selectedReportYearID}&moduleID=${selectedModuleID}`).then((result) => {                
                    if(result.data.response == true) {
                        if (selectedReportYearID && selectedModuleID) {
                            success_message("All values was deleted successfully!")
                            setTimeout(() => {
                                loadDataByReportYear(selectedReportYearID, selectedModuleID)
                            },2000)                        
                        }
                    }
                    else {
                        error_message("Some error was happened. Please try again or contact the administrator.")
                    }
                })
            }
        })
     }


    const InputNumberEdit = ({ cell, onChange }) => {
        var rowID = cell.className.split('_')[1];
        return (
            <div style={{ padding: '0.5rem 1rem', textAlign:"center"}}  onClick={() => removeRow(rowID)}>
                <FaTrashAlt />
            </div>
        );
    };

    const InputOptions = ({ cell, onChange }) => {

        return (
            <div style={{ padding: '0.5rem 1rem'}}>
                <img src={optionsLogo} alt="" />
            </div>
        );
    };


    useEffect(() => {
        loadReportYears()
    }, [])

    useEffect(() => {
        if(selectedReportYearID != null) {
            loadAllModulesByReportYear(selectedReportYearID)
        }
            
    }, [selectedReportYearID])


    useEffect(() => {
        if (selectedReportYearID && selectedModuleID) {
            loadDataByReportYear(selectedReportYearID, selectedModuleID)
            setIsLoading(true);
        }
    }, [selectedReportYearID, selectedModuleID])

    const loadReportYears = (userAux) => {
        setIsLoading(true)
        Api.get(`FormValue/getAllCropsYears`).then((result) => {
            if (result && result.data) {
                setReportYears(result.data.response);
                setSelectedReportYearID(result.data.response[0].reportYearID);
                loadAllModulesByReportYear(result.data.response[0].reportYearID)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }   

    const loadAllModulesByReportYear = (reportYearID) => {
        setIsLoading(true)
        Api.get(`Module/getAllByReportYear?reportYearID=${reportYearID}`).then((result) => {
            if (result && result.data) {
                if(result.data.response.length > 0) {
                    setModules(result.data.response);
                    setSelectedModuleID(result.data.response[0].moduleID);
                }
                
                setIsLoading(false)
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }   

    const onChangeCropID = (value) => {
        setSelectedReportYearID(value);
    }

    const onChangeModuleID = (value) => {
        setSelectedModuleID(value);
    }


    const loadDataByReportYear = (selectedReportYear, selectedModule) => {
        if (selectedReportYear && selectedModule) {
            Api.get(`AdditionalDataDeadline/getAllByCrop?reportYearID=${selectedReportYear}&moduleID=${selectedModule}`).then((result) => {
                if (result && result.data) {
                    console.log(result.data.response)
                    setIDsList(result.data.response.iDs)
                    var isDataEditable = false;
                    var resultList = [...result.data.response.data];
                    var newLine = [
                        { value: "" }, { value: "" }, { value: "" }, { value: "" }
                    ]
                    resultList.push(newLine)                   
                    

                    try {
                        var resultDatasheet = [];
                        var rowLabel = [];
                        resultDatasheet = resultList.map((row, i) => {
                            var res = [];
                            var id = i + 1;

                                row[0] = { value: row[0].value };
                                row[1] = { value: row[1].value };
                                row[2] = { value: row[2].value };
                                row[3] = { readOnly: false, className: 'remove_' + row[3].value, onClick: () => removeRow(row[3].value) };
                                row[3].DataViewer = InputOptions;
                                row[3].DataEditor = InputNumberEdit; 

                                rowLabel.push(id);
                                return res;
                            })
                        setRowLabels(rowLabel);
                        setDataSheet(resultList);
                        setIsLoading(false)
                    } catch (e) {
                        console.log(e)
                    }
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }

    }

    const save = () => {
        var objAdditionalData = {
            reportYearID: selectedReportYearID,
            data: dataSheet,
            moduleID: selectedModuleID,
            IDs: iDsList
        }
        setIsLoading(true);
        console.log(objAdditionalData)

        Api.post(`AdditionalDataDeadline/New`, objAdditionalData).then((result) => {
            if (result && result.data) {
                setIsLoading(false);
                if (result.data.response == true) {
                    success_message("The data was saved!");
                    loadDataByReportYear(selectedReportYearID, selectedModuleID)
                }
                else {
                    info_message("No data to saved!");
                }
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeTable = (e) => {
        try {
            setDataSheet(e);
        } catch { }
    }


    const onBlurTable = () => {
        try {
            var nesw = dataSheet.map((row) => {
                var newRow = row.map((col) => {
                    var newCol = { ...col };
                    return newCol;
                })
                return newRow;
            })

            try {
                nesw.map((row) => {
                    var value1 = row[0].value != "" ? row[0].value : "";
                    var value2 = row[1].value != "" ? row[1].value : "";
                    var value3 = row[2].value != "" ? row[2].value : "";
                    row[0] = { value: value1.includes(" - ") || value1 == " " || value1 == "-" || value1 == null || value1 == undefined ? "" : value1 };
                    row[1] = { value: value2.includes(" - ") || value2 == " " || value2 == "-" || value2 == null || value2 == undefined ? "" : value2 };
                    row[2] = { value: value3 == " " || value3 == null || value3 == undefined ? "" : value3 };
                })

            } catch { }
            setDataSheet(nesw);
        } catch (e) {
            console.log(e)
        }
    }



    return (
        <Container className="container">
            <div style={{ margin: '2rem' }}>
                <div className='row'>
                    <div className="col-7 d-flex header">
                        <h3>Additional Data - Deadline</h3>
                        </div>
                        <div className="col-4 d-flex justify-content-end header">
                            <Button style={{ marginRight: '15px' }} onClick={() => save()}>Save</Button>
                            <Button style={{ marginRight: '15px' }} onClick={() => deleteAll()}>Delete All</Button>
                        </div>
                                        
                </div>
            </div>
            <div className="row mt-2 mx-4">
                <div className="col-md-6 mt-2">
                    <label className='label'>Select Reporting Year</label>
                    <select className="col-md-12 mt-2" onChange={(x) => onChangeCropID(x.target.value)} value={selectedReportYearID ? selectedReportYearID : ""}>
                        {
                            reportYears ?
                                reportYears.map(reportYear => {
                                    return <option key={reportYear.year} value={reportYear.reportYearID}>{reportYear.year}</option>
                                })
                                :
                                <></>
                        }
                    </select>
                </div>
                <div className="col-md-6 mt-2">
                    <label className='label'>Select Module</label>
                    <select className="col-md-12 mt-2" onChange={(x) => onChangeModuleID(x.target.value)} value={selectedModuleID ? selectedModuleID : ""}>
                        {
                            modules ?
                                modules.filter(module => module.isActive === true).map(module => {
                                    return <option key={module.moduleID} value={module.moduleID}>{module.description}</option>
                                })
                                :
                                <></>
                        }
                    </select>
                </div>
            </div>
            {!isLoading && selectedModuleID && selectedReportYearID ?
                <Spreadsheet data={dataSheet} columnLabels={columns} rowLabels={rowLabels} onBlur={() => onBlurTable()} onChange={(e) => onChangeTable(e)} />
                :
                !isLoading ?
                    <>No data found, please select a valid module</>
                :
                <TableLoading />
            }

        </Container>

    )
}

export default AdditionalDataDeadlineFormIndex;
