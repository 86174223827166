import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { FiUpload } from "react-icons/fi";
import FormCrud from '../../components/FormCrud';
import { options } from 'toastr';
import Button from "..//../components/Button";
import { Header, ContentTitle, CommentTextArea, ModalBody } from './styles';
import { error_message, info_message, success_message } from '../../components/Toast';
import { is } from 'date-fns/locale';

const EditFiles = ({ showFileEditModal, setShowFileEditModal, fileID, loadDataByFolder }) => {
    const [filename, setFilename] = useState(null)
    const [userObject, setUserObject] = useState(JSON.parse(localStorage.getItem('@QualityApp:userRoles')));

    const EditFile = (id) => {
        if(filename != null) {
            Api.post(`DocumentUploadFiles/edit?id=${fileID}&filename=${filename}`).then((resultx) => {
                if (resultx && resultx.data) {
                    loadDataByFolder();
                    setShowFileEditModal(false);
                }
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
        }        
    }

    return (<>
        <Modal
            show={showFileEditModal}
            onHide={() => {
                setShowFileEditModal(false);
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader closeButton>
                <ModalTitle id="contained-modal-title-vcenter">
                    <Header>Edit File</Header>
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <label>File name:</label>
                            <input type="text" className="form-control" id="filename" onChange={(e) => setFilename(e.target.value)}/>
                            
                        </div>                        
                        <div className="col-12 mt-4">                            
                            <button className='btn btn-primary' onClick={() => EditFile()}>Save</button>
                        </div>                        
                    </div>
                </div>                
            </ModalBody>
        </Modal>

    </>
    );
}

export default EditFiles;