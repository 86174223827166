import React from 'react';
import TableCrud from '../../components/TableCrud';

const UnitMeasureIndex = () => {
    const columns = [
        {
            label: "Answer Type KPI Description",
            name: "description"
        },
        {
            label: "Type",
            name: "type"
        }
    ]

    return (
        <TableCrud title={"Answer Type KPI"} table={"unitMeasure"} columns={columns}/>
    );
}

export default UnitMeasureIndex;
