import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    select{
        animation: flipInX;
        animation-duration: 1s;
        border-radius: 4px;
        cursor: pointer;
        padding: 0 6px ;
        border: 1px solid #ECECF3;
        color: #7F7F8A;
        height: 40px;
        
        option{
            background-color: #F6F8FA;
            color: black;
            cursor: pointer;
        }

        &:focus-visible{
            outline: none;
        }

        &:disabled{
            background-color: #e9ecef;
            border-radius: 5px;
            border: 1px solid #ced4da;
            cursor: auto;
        }
    }

    .header {
        align-items: center;

        h3 {
            margin: 0;
        }
    }

    .Spreadsheet {
        margin: 2rem auto;
    }

    .Spreadsheet__header {
        white-space: break-spaces;
        text-align: center;
        min-width: 2em;
        background-color: #0494cf;
        font-weight: 900;
        color: #FFF;
        padding: 1rem 2rem;
    }

    .Spreadsheet__cell {
        white-space: break-spaces; 
        height: 10px;
    }
        
    .Spreadsheet__cell textarea:focus-visible {
        outline: none;        
    }


    .Spreadsheet__header:last-child {
        background-color: #F6F8FA;
        min-width: 2rem;
        border: 0;
    }
    

    /* .Spreadsheet__active-cell {
        border: 0;
        outline: 0;
    } */

    tr .Spreadsheet__cell:last-child {
        background-color: #F6F8FA;
        min-width: 2rem;
        border: 0;
    }
    label{
        animation: flipInX;
        animation-duration: 1s;
        padding-left: 0;
    }

    .form-check{
        color: #7F7F8A;
        animation: flipInX;
        animation-duration: 1s;
    }

    input{
        &:focus{
            box-shadow: none;
        }
    }

    /* button{
        border-radius: 5px;
        color: #fff;
        margin-right: 19px;
        font-size: 13px;
        cursor: pointer;
        font-weight: bold;
        display: inline-block !important;
        vertical-align: middle !important;
        animation: flipInX;
        animation-duration: 1s;
        background-color: #112A61;
        padding: 0.5rem 3rem;

        &:hover{
            opacity: 0.8;
        }
    } */

    .approve-btn {
        margin: 0 1rem;
    }

    .save-btn {
        margin: 0 1rem;
    }

    .save-btn button{
        background-color: transparent;
        border: 1px solid #B0B0BB;
        color: #B0B0BB;
        padding: 0.8rem 2rem;
        border-radius: 5px;
        width: 100%;

        &:hover{
            border: 1px solid #FFF;
            color: #FFF;
            background-color: #B0B0BB;
        }
    }

    .send-btn button{
        background-color: transparent;
        border: 1px solid #B0B0BB;
        color: #B0B0BB;
        padding: 0.8rem 2.5rem;
        border-radius: 5px;
        width: 100%;
        
        &:hover{
            border: 1px solid #FFF;
            color: #FFF;
            background-color: #B0B0BB;
        }
    }

    .approve-btn button{
        background-color: transparent;
        border: 1px solid #28a745;
        color: #28a745;
        padding: 0.8rem 3rem;
        border-radius: 5px;
        
        &:hover{
            border: 1px solid #FFF;
            color: #FFF;
            background-color: #28a745;
        }
    }

    .reject-btn button{
        background-color: transparent;
        border: 1px solid #dc3545;
        color: #dc3545;
        padding: 0.8rem 3rem;
        border-radius: 5px;
        
        &:hover{
            border: 1px solid #FFF;
            color: #FFF;
            background-color: #dc3545;
        }
    }

    .status {
        margin: 0 2rem;
        border-radius: 10px;
        padding: 0 1rem;
        margin-block-end: 0;
        height: 2rem;
        align-items: center;
        display: flex;
        color: #000;
        font-weight: 600;
    }

    .apply-btn{
        background-color: #112A61;
        border: 1px solid #112A61;
        padding-top: 10px; 
        padding-bottom: 10px; 
        cursor: pointer;
    }
`;

export const ContainerHeader = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 25px;

    
`;

export const Title = styled.h3`
    color: #27b5e5;
    text-align: left;
    font: normal normal bold 22px Segoe UI;
    letter-spacing: 1.1px;
`;

export const Actions = styled.div`
    display: flex;
`;

export const Table = styled.table`
    border-radius: 7px!important;
    background: white;
    width: 100%;
    border-spacing: 0px;
    border-collapse: separate;
    border: 1px solid #5E7BB9;
    border-radius: 8px 8px 8px 7px;
    opacity: 1;

    tr:nth-child(odd) > td,
    tr:nth-child(odd) > th {
        background-color: rgba(0,0,0,.05);
    }

    tr:nth-child(even) > td,
    tr:nth-child(even) > th {
        background-color: white;
    }

    th {
        text-align: left!important;
        font: normal normal medium 12px Segoe UI!important;
        letter-spacing: 0px!important;
        opacity: 1!important;
        background-color: #234285!important;
        color: #D2D9E6!important;
    }

    tr:last-child {
        td:first-child {
            border-bottom-left-radius: 7px;
        }

        td:last-child {
            border-bottom-right-radius: 7px;
        }
    }

    th:first-child {
        border-top-left-radius: 7px;
    }

    th:last-child {
        border-top-right-radius: 7px;
    }

    td:first-child {
        padding-top: 20px!important;
    }

    th, td {
        border-top: 0px!important;
        padding-left: 35px!important;
        padding-top: 15px!important;
        padding-bottom: 12px!important;
        padding-right: 12px!important;
        font-size: 14px;
        letter-spacing: 0.7px;
        opacity: 1;
        border: 0;
    }
`;


export const ContainerHeaderDetail = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 25px;
    margin-top: 42px;
`;

export const TableDetail = styled.table`
    font-size: 13px;

    thead{
        background-color: #102A63;
        height: 40px;

        tr{
            th{
                text-align: center;
                font-weight: bold;
                color: #FFFFFFFF;
                padding: 14px;
            }

            th:first-child{
                border-radius: 8px 0px 0px 0px;
            }

            th:last-child{
                border-radius: 0px 8px 0px 0px;
            }
        }
    }

    tbody{
        background-color: #FFFFFFFF;
        box-shadow: 0px 3px 20px #0000000d;

        tr{
            border: 1px solid #ECECF3;
            border-left: #FFFFFF;
            border-right: #FFFFFF;

            td{
                text-align: center;
                padding: 14px;

                &:last-child{
                    .btn{
                        background-color: transparent;
                        border: 0;
                        padding: 0;
                        margin: 0;

                        svg{
                            color: #B0B0BB;
                            
                            &:hover{
                                color: #102A63;
                            }
                        }

                        &:focus{
                            background-color: transparent;
                            border: 0;
                            padding: 0;
                            margin: 0;
                            box-shadow: 0 0 0 0 transparent;
                        }

                        &:active{
                            background-color: transparent;
                            border: 0;
                            padding: 0;
                            margin: 0;
                            box-shadow: 0 0 0 0 transparent;
                        }
                    }

                    .dropdown-menu{
                        box-shadow: 0px 3px 20px #00000026;
                        border: 1px solid #ECECF3;
                        border-radius: 4px;
                        font-size: 13px;

                        .dropdown-item{
                            padding-left: 19px;
                            padding-right: 19px;

                            &:active{
                                background-color: #102A63;
                            }
                        }
                    }
                }
            }

            .no-records{
                text-align: center;
            }

            &:last-child{
                td:first-child{
                    border-radius: 0px 0px 0px 8px;
                }

                td:last-child{
                    border-radius: 0px 0px 8px 0px;
                }

                border-bottom: #F6F8FA;
            }
        }

        
    }
`;