import React, { useState } from "react";
import { FaQuestionCircle } from 'react-icons/fa';
import { TooltipCard, TooltipText, TooltipBox } from './styles';


const TooltipComponent = ({ guidance, size, TooltipBoxClass = "", isColumnOverlap }) => {
  const [showGuidance, setShowGuidance] = useState(false);


  TooltipBoxClass = guidance?.length > 30 && guidance?.length < 499 ? "sticky-header-tooltipbox-big" : guidance?.length > 500 ? "sticky-header-tooltipbox-giant" : "sticky-header-tooltipbox";
  return (
    <>
      <TooltipCard className="sad">
        <TooltipText className={showGuidance ? 'active' : ''} onClick={(e) => {
          setShowGuidance(!showGuidance)
        }}>
          <FaQuestionCircle size={size} style={showGuidance ? { color: "#112A61" } : {}} />
        </TooltipText>
        <TooltipBox className={TooltipBoxClass}>

          <p className="mb-0">{guidance != null && guidance != "" && guidance != "n/a" ? guidance : "No guidance"}</p>
        </TooltipBox>
      </TooltipCard>
    </>
  );
}

export default TooltipComponent;
