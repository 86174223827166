import React from 'react';
import FormCrud from '../../components/FormCrud';

const UnitMeasureForm = () => {
    const typeOptions = [
        { value: "%, no decimal", text: "%, no decimal"},
        { value: "%, one decimal place", text: "%, one decimal place"},
        { value: "%, two decimal cases", text: "%, two decimal cases"},
        { value: "%, four decimal cases", text: "%, four decimal cases"},
        // { value: "6 decimals, positive or negative allowed", text: "6 decimals, positive or negative allowed"}, 
        { value: "All/Some/None", text: "All/Some/None"},
        { value: "Always/Sometimes/Never/NA", text: "Always/Sometimes/Never/NA"},
        { value: "Always: 100% of the times/Most of the time: over 90% of the times/Sometimes/Occasionally:less than 90% of the times/Never: 0% of the times", text: "Always: 100% of the times/Most of the time: over 90% of the times/Sometimes/Occasionally:less than 90% of the times/Never: 0% of the times"},
        { value: "Date", text: "Date"},
        { value: "Full cost/Subsidised/Free of Charge", text: "Full cost/Subsidised/Free of Charge"},
        { value: "Natural number", text: "Natural number"}, 
        { value: "No decimal", text: "No decimal"}, 
        { value: "One decimal place", text: "One decimal place"},
        { value: "Selection from list: All, More than half, Approximaterly half, Less than half, None", text: "Selection from list: All, More than half, Approximaterly half, Less than half, None"},
        { value: "Selection from countries list", text: "Selection from countries list"},
        { value: "Selection from housing", text: "Selection from housing"},
        { value: "Text", text: "Text"},
        { value: "Two decimal places", text: "Two decimal places"},
        { value: "Three decimal places", text: "Three decimal places"},  
        { value: "Four decimal places", text: "Four decimal places"},       
        { value: "Six decimal places", text: "Six decimal places"}        
    ];

    const fields = [
        {
            label: "Answer Type KPI Description",
            name: "description",
            type: "input"
        },
        {
            label: "Type",
            name: "type",
            type: "select",
            options: typeOptions,
            placeholder: { value: "", text: ""}
        }
    ]

    return (
        <FormCrud formName={"unitMeasure"} title={"Answer Type KPI"} fields={fields}/>
    );
}

export default UnitMeasureForm;
