import React from 'react';
import TableCrud from '../../components/TableCrud';

const CompanyIndex = () => {
    const columns = [
        {
            label: "Name",
            name: "name"
        },
        {
            label: "Currency Type",
            name: "currencyType.description"
        },
        {
            label: "Active",
            name: "isActive"
        }
    ]

    return (
        <TableCrud title={"Supplier"} table={"company"} columns={columns}/>
    );
}

export default CompanyIndex;
