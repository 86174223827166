import React from 'react';
import FormCrud from '../../components/FormCrud';

const CurrencyTypeForm = () => {
    const fields = [
        {
            label: "Currency Type Description",
            name: "description",
            type: "input"
        }
    ]

    return (
        <FormCrud formName={"currencyType"} title={"Currency Type"} fields={fields}/>
    );
}

export default CurrencyTypeForm;
