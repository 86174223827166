import React from 'react';
import { Card } from './styles';

const TableLoading = ({ small = false }) => {
    return (
        <Card>
            <header className="pb-3 avn-skeleton avn-skeleton-header">
                <span><i className="avn-skeleton-text"></i></span>
                <i className="avn-skeleton-loader"></i>
            </header>
            {!small && <>
                <div className="pt-3 avn-skeleton">
                    <p>
                        <i className="avn-skeleton-text"></i>
                        <i className="avn-skeleton-text"></i>
                        <i className="avn-skeleton-text"></i>
                        <i className="avn-skeleton-text"></i>
                    </p>
                </div>
            </>}
        </Card>
    );
}

export default TableLoading;