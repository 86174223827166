import React, { createContext, useState, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import Button from '../Button';
import { ContentTitle, CommentText, PasswordInput, Container, CancelButton} from './styles';
import { useHistory } from "react-router";
import api, { exceptionNotificationAPI } from '../../services/api';
import { success_message } from '../../components/Toast/index.jsx';
import PasswordStrengthBar from 'react-password-strength-bar';

const ModalComponent = (props) => {
    const history = useHistory();
    const role = localStorage.getItem('@QualityApp:userRoles');

    const [password, setPassword] = useState();
    const [passwordCfr, setPasswordCfr] = useState();
    const [inputValue , setInputValue] = useState();

    const changePassword = async (password, passwordCfr) => {
      try {
        const userPasswordRequest = {Password:password, ConfirmPassword:passwordCfr}
  
        const result = await api.post('/changePassword', userPasswordRequest);
        success_message("Update password successfuly");

        props.setModalShow(false)
        
      } catch (error) {
        exceptionNotificationAPI(error);
      }    
    }

    const handleCancel = () => {
      props.setModalShow(false)
    }

    


    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-password"
        >
        <ModalHeader closeButton>          
        </ModalHeader>
        <ModalBody>          
            <Container>         
              <ContentTitle>New Password</ContentTitle>
              <div className="d-block">
                <PasswordInput type="password" name="Password" id="newPassword" 
                                onChange={(e) => {setPassword(e.target.value);}}></PasswordInput>
                <PasswordStrengthBar 
                              password={password}
                              minLength={10}
                              />
              </div>
            <ContentTitle>Confirm New Password</ContentTitle>
            <div className="d-block">
              <PasswordInput type="password" name="ConfirmPassword" id="confirmNewPassword"
                              onChange={(e) => {
                                  setPasswordCfr(e.target.value);}}></PasswordInput>
                <PasswordStrengthBar 
                              password={passwordCfr}
                              minLength={10}
                              />
            </div>
            <CancelButton onClick={handleCancel}>Cancel</CancelButton>
            <Button style={{ marginTop: '2rem' }} type="submit" onClick={() => changePassword(password, passwordCfr)}>Save</Button>
            {/* */}
            </Container>
          
        </ModalBody>        
      </Modal>
    );
}

export default ModalComponent;

/*
import ModalComponent from '../ModalComponent';
const [modalShow, setModalShow] = React.useState(false);

<Button style={{ marginRight: '15px' }} onClick={() => setModalShow(true)}>Modal</Button>

<ModalComponent
          show={modalShow}
          onHide={() => setModalShow(false)}
          title={"Tobbaco Price / Kg"}
          comment={"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt"}
        />


*/