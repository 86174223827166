import React, { useEffect, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import { Container, Error } from './styles';
import FormCheck from 'react-bootstrap/FormCheckInput';
import moment from 'moment';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import PasswordStrengthBar from 'react-password-strength-bar';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import Paragraph from '../Paragraph';


const FormInput = ({ inputType, name, placeholder, options, hideAlert, min, max, isDash = null, customOptions = null, refNumber = null, checked = null, styleGlide = null, isSimulation = false, ...rest }) => {
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const { fieldName, defaultValue, error, registerField } = useField(name);
    const [startDate, setStartDate] = useState(new Date());
    const [inputValue, setInputValue] = useState('');
    const [customOptionsValues, setCustomOptionsValues] = useState([]);
    const [multipleValues, setMultipleValues] = useState([]);
    const MAX_LENGTH = 150;

    useEffect(() => {
        if (customOptions != null) {
            if (customOptions.indexOf(";") > -1)
                setCustomOptionsValues(customOptions.split(";"));
            else
                setCustomOptionsValues([customOptions]);
        }
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                if (ref.current) {
                    if (inputType == "date") {
                        return ref.current.props.selected;
                    }
                    else {
                        return ref.current.value
                    }
                }

                return null;
            },
            setValue: (ref, value) => {
                if (inputType == "date") {
                    setStartDate(new Date(value))
                    ref.current.value = value
                }
                else {
                    ref.current.value = value
                }
            },
            clearValue: ref => {
                ref.current.value = ''
            },
        });
    }, [fieldName, registerField]);

    
    const handleDateSelect = () => {
        return startDate;
    }

    const handleDate = (date) => {
        setStartDate(date)
    }

    const handleInputFocus = () => {
        setIsFocused(true);
    }

    const handleInputBlur = () => {
        setIsFocused(false);

        setIsFilled(!!inputRef.current?.value);
    }

    if (!placeholder && inputType !== "select" && inputType !== undefined && typeof inputType.startsWith === 'function' && inputType?.startsWith("select")) {
        placeholder = { value: "", text: "Select an option" };
    }

    return (
        <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused} style={{ padding: styleGlide }}>
            {inputType === "select" ?
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    {options != null && options.length > 0 ? options.map((option, i) => <option key={`${name}-${i}`} value={option.value}>{option.text.length > MAX_LENGTH ? `${option.text.substring(0, MAX_LENGTH)}...` : option.text}</option>) : <option key={`${name}`} value={0}>{"no data"}</option>}
                </select>
                : <></>
            }

            {inputType === "selecttobacco" || inputType === "selectfuel" || inputType === "selectcountry" || inputType === "selectCuringBarn" ?
                <select
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    {options != null && options != undefined ? options.map((option, i) => <option key={`${name}-${i}`} value={option.value} hidden={option.hidden} >{option.text.length > MAX_LENGTH ? `${option.text.substring(0, MAX_LENGTH)}...` : option.text}</option>) : <option key={`${name}`} value={0}>{"no data"}</option>}
                </select>
                : <></>
            }

            {inputType === "selectyn" &&
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    <option key={`${name}-2`} value="Yes">Yes</option>
                    <option key={`${name}-3`} value="No">No</option>
                </select>
            }

            {inputType === "selectynu" &&
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    <option key={`${name}-1`} value="Yes">Yes</option>
                    <option key={`${name}-2`} value="No">No</option>
                    <option key={`${name}-3`} value="Unsure">Unsure</option>
                </select>
            }

            {inputType === "selectasnn" &&
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    <option key={`${name}-1`} value="Always">Always</option>
                    <option key={`${name}-2`} value="Sometimes">Sometimes</option>
                    <option key={`${name}-3`} value="Never">Never</option>
                    <option key={`${name}-4`} value="NA">NA</option>
                </select>
            }

            {inputType === "selectasn" &&
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    <option key={`${name}-1`} value="All">All</option>
                    <option key={`${name}-2`} value="Some">Some</option>
                    <option key={`${name}-3`} value="None">None</option>
                </select>
            }

            {inputType === "selecthousing" &&
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    <option key={`${name}-1`} value="All">All</option>
                    <option key={`${name}-2`} value="More than half">More than half</option>
                    <option key={`${name}-3`} value="Approximaterly half">Approximaterly half</option>
                    <option key={`${name}-4`} value="Less than half">Less than half</option>
                    <option key={`${name}-5`} value="None">None</option>
                </select>
            }

            {inputType === "selectcharge" &&
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    <option key={`${name}-1`} value="Full Cost">Full Cost</option>
                    <option key={`${name}-2`} value="Subsidised">Subsidised</option>
                    <option key={`${name}-3`} value="Free of Charge">Free of Charge</option>
                </select>
            }

            {inputType === "selectli" &&
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    <option key={`${name}-1`} value="Local">Local</option>
                    <option key={`${name}-2`} value="Imported">Imported</option>
                    <option key={`${name}-3`} value="Free of Charge">Free of Charge</option>
                </select>
            }

            {inputType === "selectcustom" &&
                ((refNumber != "7309" && refNumber != "7316") ?
                    <select
                        title={name}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        ref={inputRef}
                        {...rest}>
                        {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                        {customOptionsValues.map((option, i) => <option key={`${name}-${i}`} value={option}>{option.length > MAX_LENGTH ? `${option.substring(0, MAX_LENGTH)}...` : option}</option>)}
                    </select>
                    :
                    <Select
                        title={name}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        ref={inputRef}
                        options={customOptionsValues.map((option) => {
                            var data = {
                                value: option,
                                label: option
                            }
                            return data;
                        })}
                        value={multipleValues}
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={(a) => setMultipleValues(a)}
                        className="col-md-12"
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />)
            }

            {inputType === "multiselectcustom" &&
                <Select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    options={customOptionsValues.map((option) => {
                        var data = {
                            value: option,
                            label: option
                        }
                        return data;
                    })}
                    //value={multipleValues}
                    isMulti
                    closeMenuOnSelect={false}
                    //onChange={(a) => setMultipleValues(a)}
                    className="col-md-12"
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    {...rest}
                />
            }



            {inputType === "selecttimezone" &&
                <select
                    title={name}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    ref={inputRef}
                    {...rest}>
                    {(placeholder != null) && <option value={placeholder.value}>{placeholder.text}</option>}
                    <option value="Morocco Standard Time">(GMT) Casablanca</option>
                    <option value="GMT Standard Time">(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
                    <option value="Greenwich Standard Time">(GMT) Monrovia, Reykjavik</option>
                    <option value="W. Europe Standard Time">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                    <option value="Central Europe Standard Time">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                    <option value="Romance Standard Time">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                    <option value="Central European Standard Time">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                    <option value="W. Central Africa Standard Time">(GMT+01:00) West Central Africa</option>
                    <option value="Jordan Standard Time">(GMT+02:00) Amman</option>
                    <option value="GTB Standard Time">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                    <option value="Middle East Standard Time">(GMT+02:00) Beirut</option>
                    <option value="Egypt Standard Time">(GMT+02:00) Cairo</option>
                    <option value="South Africa Standard Time">(GMT+02:00) Harare, Pretoria</option>
                    <option value="FLE Standard Time">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                    <option value="Israel Standard Time">(GMT+02:00) Jerusalem</option>
                    <option value="E. Europe Standard Time">(GMT+02:00) Minsk</option>
                    <option value="Namibia Standard Time">(GMT+02:00) Windhoek</option>
                    <option value="Arabic Standard Time">(GMT+03:00) Baghdad</option>
                    <option value="Arab Standard Time">(GMT+03:00) Kuwait, Riyadh</option>
                    <option value="Russian Standard Time">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                    <option value="E. Africa Standard Time">(GMT+03:00) Nairobi</option>
                    <option value="Georgian Standard Time">(GMT+03:00) Tbilisi</option>
                    <option value="Iran Standard Time">(GMT+03:30) Tehran</option>
                    <option value="Arabian Standard Time">(GMT+04:00) Abu Dhabi, Muscat</option>
                    <option value="Azerbaijan Standard Time">(GMT+04:00) Baku</option>
                    <option value="Mauritius Standard Time">(GMT+04:00) Port Louis</option>
                    <option value="Caucasus Standard Time">(GMT+04:00) Yerevan</option>
                    <option value="Afghanistan Standard Time">(GMT+04:30) Kabul</option>
                    <option value="Ekaterinburg Standard Time">(GMT+05:00) Ekaterinburg</option>
                    <option value="Pakistan Standard Time">(GMT+05:00) Islamabad, Karachi</option>
                    <option value="West Asia Standard Time">(GMT+05:00) Tashkent</option>
                    <option value="India Standard Time">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                    <option value="Sri Lanka Standard Time">(GMT+05:30) Sri Jayawardenepura</option>
                    <option value="Nepal Standard Time">(GMT+05:45) Kathmandu</option>
                    <option value="N. Central Asia Standard Time">(GMT+06:00) Almaty, Novosibirsk</option>
                    <option value="Central Asia Standard Time">(GMT+06:00) Astana, Dhaka</option>
                    <option value="Myanmar Standard Time">(GMT+06:30) Yangon (Rangoon)</option>
                    <option value="SE Asia Standard Time">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                    <option value="North Asia Standard Time">(GMT+07:00) Krasnoyarsk</option>
                    <option value="China Standard Time">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                    <option value="North Asia East Standard Time">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                    <option value="Singapore Standard Time">(GMT+08:00) Kuala Lumpur, Singapore</option>
                    <option value="W. Australia Standard Time">(GMT+08:00) Perth</option>
                    <option value="Taipei Standard Time">(GMT+08:00) Taipei</option>
                    <option value="Tokyo Standard Time">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                    <option value="Korea Standard Time">(GMT+09:00) Seoul</option>
                    <option value="Yakutsk Standard Time">(GMT+09:00) Yakutsk</option>
                    <option value="Cen. Australia Standard Time">(GMT+09:30) Adelaide</option>
                    <option value="AUS Central Standard Time">(GMT+09:30) Darwin</option>
                    <option value="E. Australia Standard Time">(GMT+10:00) Brisbane</option>
                    <option value="AUS Eastern Standard Time">(GMT+10:00) Canberra, Melbourne, Sydney</option>
                    <option value="West Pacific Standard Time">(GMT+10:00) Guam, Port Moresby</option>
                    <option value="Tasmania Standard Time">(GMT+10:00) Hobart</option>
                    <option value="Vladivostok Standard Time">(GMT+10:00) Vladivostok</option>
                    <option value="Central Pacific Standard Time">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                    <option value="New Zealand Standard Time">(GMT+12:00) Auckland, Wellington</option>
                    <option value="Fiji Standard Time">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                    <option value="Tonga Standard Time">(GMT+13:00) Nuku'alofa</option>
                    <option value="Azores Standard Time">(GMT-01:00) Azores</option>
                    <option value="Cape Verde Standard Time">(GMT-01:00) Cape Verde Is.</option>
                    <option value="Mid-Atlantic Standard Time">(GMT-02:00) Mid-Atlantic</option>
                    <option value="E. South America Standard Time">(GMT-03:00) Brasilia</option>
                    <option value="Argentina Standard Time">(GMT-03:00) Buenos Aires</option>
                    <option value="SA Eastern Standard Time">(GMT-03:00) Georgetown</option>
                    <option value="Greenland Standard Time">(GMT-03:00) Greenland</option>
                    <option value="Montevideo Standard Time">(GMT-03:00) Montevideo</option>
                    <option value="Newfoundland Standard Time">(GMT-03:30) Newfoundland</option>
                    <option value="Atlantic Standard Time">(GMT-04:00) Atlantic Time (Canada)</option>
                    <option value="SA Western Standard Time">(GMT-04:00) La Paz</option>
                    <option value="Central Brazilian Standard Time">(GMT-04:00) Manaus</option>
                    <option value="Pacific SA Standard Time">(GMT-04:00) Santiago</option>
                    <option value="Venezuela Standard Time">(GMT-04:30) Caracas</option>
                    <option value="SA Pacific Standard Time">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                    <option value="Eastern Standard Time">(GMT-05:00) Eastern Time (US & Canada)</option>
                    <option value="US Eastern Standard Time">(GMT-05:00) Indiana (East)</option>
                    <option value="Central America Standard Time">(GMT-06:00) Central America</option>
                    <option value="Central Standard Time">(GMT-06:00) Central Time (US & Canada)</option>
                    <option value="Central Standard Time (Mexico)">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                    <option value="Canada Central Standard Time">(GMT-06:00) Saskatchewan</option>
                    <option value="US Mountain Standard Time">(GMT-07:00) Arizona</option>
                    <option value="Mountain Standard Time (Mexico)">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                    <option value="Mountain Standard Time">(GMT-07:00) Mountain Time (US & Canada)</option>
                    <option value="Pacific Standard Time">(GMT-08:00) Pacific Time (US & Canada)</option>
                    <option value="Pacific Standard Time (Mexico)">(GMT-08:00) Tijuana, Baja California</option>
                    <option value="Alaskan Standard Time">(GMT-09:00) Alaska</option>
                    <option value="Hawaiian Standard Time">(GMT-10:00) Hawaii</option>
                    <option value="Samoa Standard Time">(GMT-11:00) Midway Island, Samoa</option>
                    <option value="Dateline Standard Time">(GMT-12:00) International Date Line West</option>
                </select>
            }

            {inputType === "check" &&
                <FormCheck onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    defaultChecked={checked}
                    defaultValue={checked}
                    checked={checked}
                    ref={inputRef}
                    {...rest} />
            }


            {inputType === "textarea" &&
                <textarea onFocus={handleInputFocus}
                    autoComplete={"off"}
                    onBlur={handleInputBlur}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    ref={inputRef}
                    {...rest} />
            }

            {(inputType === "number") &&
                <input onFocus={handleInputFocus}
                    autoComplete={"off"}
                    onBlur={handleInputBlur}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    ref={inputRef}
                    step="0"
                    presicion={0}
                    type={inputType}
                    min={0}
                    {...rest} />
            }

            {(inputType === "decimal") &&
                <input onFocus={handleInputFocus}
                    autoComplete={"off"}
                    onBlur={handleInputBlur}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    step="0.001"
                    presicion={3}   //very important
                    ref={inputRef}
                    type={"number"}
                    min={0}
                    {...rest} />
            }

            {(inputType === "date") &&
                <DatePicker ref={inputRef} onSelect={handleDateSelect} selected={startDate} onChange={(date) => { setStartDate(date) }} dateFormat="dd-MMM-yyyy" />
            }

            {((inputType === "input" || inputType === "" || inputType == null) && !isSimulation) &&
                <textarea onFocus={handleInputFocus}
                    autoComplete={"off"}
                    onBlur={handleInputBlur}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    ref={inputRef}
                    {...rest} />

            }

            {((inputType === "input" || inputType === "" || inputType == null) && isSimulation) &&
                <input onFocus={handleInputFocus}
                    autoComplete={"off"}
                    onBlur={handleInputBlur}
                    type={inputType === "input" ? "text" : inputType}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    ref={inputRef}
                    {...rest} />
            }
            {(inputType === "password") &&
                <>
                    <div className={"d-block"}>
                        <input onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            type={inputType}
                            defaultValue={defaultValue}
                            placeholder={placeholder}
                            ref={inputRef}
                            onChange={(e) => {
                                setInputValue(
                                    e.target.value
                                );
                            }}
                        />
                        <PasswordStrengthBar
                            password={inputValue}
                            minLength={10}
                        />
                    </div>
                </>
            }

            {/* {(inputType == "textareaRichText") && 
                <>
                    <Paragraph
                        title={name}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        ref={inputRef}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        {...rest}
                      />
                </>
            } */}

            {isDash != null &&
                <Error title={error} className={hideAlert ? "hide" : ""}>
                    <FiAlertCircle color="#e71700" size={20} />
                </Error>
            }
        </Container>
    );
}

export default FormInput;
